import { makeStyles, Typography } from '@material-ui/core';
import { EquipmentMode, OperationalState } from '@ats/graphql';
import { grey100, grey300 } from '../../../theme/color';
import StandardButton from './StandardButton';
import StatusCircle from '../StatusCircle';

const useStyles = makeStyles(
  {
    divider: {
      '& p': {
        color: grey100,
        textAlign: 'left',
        margin: '0px',
      },
      '& p:first-child': {
        margin: 'auto 0 0 0',
      },
      '& p:last-child': {
        margin: '0 0 auto 0',
        color: grey300,
      },
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-around',
    },
    leftPlacement: {
      marginLeft: 'auto',
      paddingRight: '30px',
    },
    vehicleButton: {
      '&:hover': {
        backgroundColor: '#242A33',
      },
      '&:active': {
        backgroundColor: '#2B323D',
      },
    },
  },
  { index: 1 },
);

interface IProps {
  active: boolean;
  hover: boolean;
  mode: EquipmentMode | null;
  onClick: () => void;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  operationalState: OperationalState | null;
  status: string;
  name: string;
  statusLabel: JSX.Element;
  alertPulse: boolean;
  icon: JSX.Element | null;
  className?: string;
}
const VehicleStatusButton = (props: IProps) => {
  const {
    active,
    hover,
    mode,
    onClick,
    onMouseEnter,
    onMouseLeave,
    operationalState,
    status,
    name,
    statusLabel,
    alertPulse,
    icon,
    className,
  } = props;

  const { divider, leftPlacement, vehicleButton } = useStyles();

  return (
    <StandardButton
      active={active}
      hover={hover}
      classes={className ? [vehicleButton, className] : vehicleButton}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={`select-vehicle-${name}`}
    >
      <StatusCircle animating={alertPulse} operationalState={operationalState} mode={mode} status={status} />
      <div className={divider}>
        <Typography className="sdds-detail-02">{name}</Typography>
        <Typography className="sdds-detail-02" data-testid={`vehicle-${name}-status-label`}>
          {statusLabel}
        </Typography>
      </div>
      {icon && <span className={leftPlacement}>{icon}</span>}
    </StandardButton>
  );
};

export default VehicleStatusButton;
