/* eslint-disable camelcase */
const uuidInt32Format = (uuid: string) => {
  const uuidHex = uuid.replaceAll('-', '');
  return {
    id_1: parseInt(uuidHex.substring(0, 8), 16),
    id_2: parseInt(uuidHex.substring(8, 16), 16),
    id_3: parseInt(uuidHex.substring(16, 24), 16),
    id_4: parseInt(uuidHex.substring(24), 16),
  };
};

export default uuidInt32Format;
