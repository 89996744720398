import { useEffect, useState, useRef } from 'react';
import {
  DegradationPlanAction,
  DegradationPlanReleaseCondition,
  useDegradations,
  useDegradationPlan,
  Degradations,
  OperationalState,
  EquipmentMode,
} from '@ats/graphql';

import getDegradationPlans from './getDegradationPlans';

interface IProps {
  areaId: string | null;
  externalEquipmentReference: string;
  mode: EquipmentMode | null;
  operationalState: OperationalState | null;
  alertHandler: () => void;
  alertResetHandler: () => void;
}

function useVehicleDegradationAlert(props: IProps): void {
  const { areaId, externalEquipmentReference, mode, operationalState, alertHandler, alertResetHandler } = props;

  // We won't the keep track of the most severe degradation given by its release condition
  const [activeDegradation, setActiveDegradation] = useState<DegradationPlanReleaseCondition | null | undefined>(
    undefined,
  );

  const firstLoad = useRef<number>(new Date().getTime());

  // Get the degradations for all vehicles in the area (filter by vehicle is done below)
  const [allDegradations] = useDegradations({ areaId });
  const [degradationPlan] = useDegradationPlan({ externalEquipmentReference });

  // Filter out only degradations for our vehicle which is never than the first load of this component
  const vehicleDegradations: Degradations[] = allDegradations
    ? allDegradations.filter(
        (veh) =>
          veh.externalEquipmentReference === externalEquipmentReference &&
          new Date(veh.timestamp).getTime() > firstLoad.current,
      )
    : [];

  // In case we have degradations for this vehicle, use the "getDegradationPlans" to filter out only the degradations that should trigger an alert
  const degradations =
    degradationPlan && degradationPlan.length > 0 && vehicleDegradations && vehicleDegradations.length === 1
      ? getDegradationPlans(degradationPlan[0], vehicleDegradations[0].degradations)
      : null;

  const standDownDegradations = degradations?.filter(
    (deg) => deg.action !== DegradationPlanAction.ReducedSpeed && deg.action !== DegradationPlanAction.None,
  );

  useEffect(() => {
    // First time we render, and we have no degradations, set the active degradation to null
    if (activeDegradation === undefined && (!standDownDegradations || standDownDegradations.length === 0)) {
      setActiveDegradation(null);
      return;
    }

    // If we don't have an active degradation, or the top severity (based on release condition) has increased, save this state
    if (
      standDownDegradations &&
      standDownDegradations.length > 0 &&
      (activeDegradation === undefined ||
        activeDegradation === null ||
        Object.values(DegradationPlanReleaseCondition).indexOf(standDownDegradations[0].releaseCondition) >
          Object.values(DegradationPlanReleaseCondition).indexOf(activeDegradation))
    ) {
      // Don't alert if transitioning fron undefined to a degradation as that will only happen when there are alerts the first time this component instance is rendered
      // Alert only if equipment is in autonomous mode
      if (activeDegradation !== undefined && mode === EquipmentMode.MODE_AUTONOMOUS) {
        alertHandler();
      }
      // Store the currently highest release conditon
      // The degradations are sorted by release condition, so the first one is one of the most severe at that time
      setActiveDegradation(standDownDegradations[0].releaseCondition);
      return;
    }

    // If activeDegradation is active, but we think (??) that the vehicle has been restarted... reset the alert
    if (
      activeDegradation !== null &&
      !standDownDegradations?.length &&
      (operationalState === OperationalState.OPERATIONAL_STATE_IDLE ||
        operationalState === OperationalState.OPERATIONAL_STATE_NORMAL ||
        operationalState === OperationalState.OPERATIONAL_STATE_EXECUTING)
    ) {
      alertResetHandler();

      setActiveDegradation(null);
    }
  }, [activeDegradation, standDownDegradations, operationalState, mode, alertHandler, alertResetHandler]);
}

export default useVehicleDegradationAlert;
