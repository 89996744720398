import { makeStyles, List, ListItem } from '@material-ui/core';
import { useObservable } from '@libreact/use-observable';
import { useSiteRelation } from '@ats/graphql';
import { grey100, grey700, grey900 } from '../../theme/color';
import {
  selectedAreaId as selectedAreaIdObservable,
  selectedZoneId as selectedZoneIdObservable,
} from '../../model/observables';
import { Close } from '../icons';
import { Selected } from '../../model/type/Selected';
import ZoneDisplayName from './ZoneDisplayName';
import QueueIcon from '../icons/QueueIcon';
import PaddockIcon from '../icons/PaddockIcon';
import DashedLine from '../icons/DashedLine';
import PaddockStatusContainer from './PaddockStatus.container';
import QueueStatus from './QueueStatus';

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: grey900,
      borderTop: `8px solid ${grey700}`,
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 64px)',
      position: 'relative',
    },
    zoneSummary: {
      width: '368px',
      height: '316px',
      flexShrink: 0,
      color: 'white',
    },
    icons: {
      position: 'absolute',
      top: '164px',
      display: 'flex',
      left: '93px',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    queueIcon: {
      width: '56px',
      height: '56px',
    },
    paddockIcon: {
      width: '56px',
      height: '56px',
    },
    dashedLine: {
      width: '56px',
      height: 'auto',
    },
    close: {
      position: 'absolute',
      color: grey100,
      top: '2px',
      left: '10px',
      width: '40px',
      height: '40px',
      cursor: 'pointer',
    },
  },
  { index: 1 },
);

interface IProps {
  selectedEquipmentState: [string | null, (id: string | null) => void];
  setSelected: (selected: Selected) => void;
}

const ZonePanel = (props: IProps) => {
  const { selectedEquipmentState, setSelected } = props;
  const { root, zoneSummary, icons, queueIcon, paddockIcon, dashedLine, close } = useStyles();

  const [areaId] = useObservable(selectedAreaIdObservable, null);
  const [selectedZoneId] = useObservable(selectedZoneIdObservable, null);
  const [siteRelation] = useSiteRelation({ areaId });

  const queueRelations = !siteRelation ? null : siteRelation.length ? siteRelation[0].queueRelations : [];

  const selectedZone = queueRelations ? queueRelations?.find((qr) => qr.id === selectedZoneId) : null;

  return (
    <div className={root} id="ZonePanel">
      {selectedZone ? (
        <>
          <div className={zoneSummary} id="zoneSummary">
            <Close click={() => setSelected(null)} className={close} />

            <ZoneDisplayName displayName={selectedZone.displayName} />
            <div className={icons}>
              <QueueIcon className={queueIcon} />
              {selectedZone.paddockId && (
                <>
                  <DashedLine className={dashedLine} />
                  <PaddockIcon className={paddockIcon} />
                </>
              )}
            </div>
          </div>
          {selectedZone.paddockId && (
            <PaddockStatusContainer
              paddockId={selectedZone.paddockId}
              areaId={areaId}
              selectedEquipmentState={selectedEquipmentState}
            />
          )}

          <QueueStatus queueId={selectedZone.queueId} areaId={areaId} selectedEquipmentState={selectedEquipmentState} />
        </>
      ) : (
        <div id="zoneList">
          <Close click={() => setSelected(null)} className={close} />
          <div className={zoneSummary} style={{ marginTop: 30 }}>
            {queueRelations && queueRelations.length ? (
              <List>
                {queueRelations.map((qr) => (
                  <ListItem key={qr.id} onClick={() => selectedZoneIdObservable.next(qr.id)}>
                    <QueueIcon className={queueIcon} />
                    {qr.paddockId && <PaddockIcon className={paddockIcon} />}
                    <span style={{ padding: 10 }}>{qr.displayName}</span>
                  </ListItem>
                ))}
              </List>
            ) : (
              queueRelations && !queueRelations.length && <span style={{ padding: 30 }}>No zones</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ZonePanel;
