import { Amplify } from 'aws-amplify';
import { api, auth } from '../config/amplify';

export function configureAmplify() {
  Amplify.configure({
    API: api,
    Auth: auth,
  });
}

export interface ICustomerAttributes {
  readonly externalCustomerReference: string;
  readonly externalStaffReference: string;
}

export interface IUserAttributes {
  readonly familyName: string;
  readonly givenName: string;
  readonly customer: ICustomerAttributes;
}
