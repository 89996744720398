import { BehaviorSubject as StateHolder } from 'rxjs';
import type { EquipmentStatus_position as IPosition, MissionInput, Area } from '@ats/graphql';
import { Coordinate } from 'ol/coordinate';
import { MissionView } from './ViewTypes';
import { IUserAttributes } from './user';

/*
    This is all observables that is basically 'one-liners'... they are used as the equivalent of a React state (they contain no processing)
*/

/*
    Mission input sent to preview the mission route on the map
    Having the input set is akin to "aspiring to preview a mission", the ID of the
    actual preview mission will be stored as a state somewhere else
*/
export const previewMissionInput = new StateHolder<MissionInput | null>(null);

/*
    Holds the map state of the Click and Drive and the selection of poi position on map
*/
export const mapInteractionModule = new StateHolder<string>('DEFAULT');

/*
    Is the Vehicle smoothing algorithm currently active?
*/
export const vehicleSmoothing = new StateHolder<boolean>(true);

/*
    Holds the state of the selected position when using Click and Drive
*/
export const clickAndDrivePosition = new StateHolder<Coordinate | null>(null);

/*
    Holds the state of the suggested poi position 
*/
export const activePoiPosition = new StateHolder<Coordinate | null>(null);

/*
    Holds the state of the selected rotation (heading) when using Click and Drive
*/
export const clickAndDriveRotation = new StateHolder<number | null>(null);

/*
     Holds the state of the suggested poi position 
*/
export const poiPosition = new StateHolder<Coordinate | null>(null);

/*
     Holds the state of the suggested Poi rotation (heading) 
*/
export const poiRotation = new StateHolder<number | null>(null);

/*
    Position of the selected vehicle
*/
export const selectedVehiclePosition = new StateHolder<IPosition | null>(null);

/*
    Offset of the rendered Map DOM element
*/
export const offsetPosition = new StateHolder<DOMRect | null>(null);

/* 
    -
    -
    -
    -
    -
    -
    - Slaves - Note that the react state is the source of truth on this one.
*/

/*
    ID of the area currently selected.
*/
export const selectedAreaId = new StateHolder<string | null>(null);

/*
    Currently selected area.
*/
export const selectedArea = new StateHolder<Area | null>(null);

/*
    ID of the vehicle currently selcted (shown in right panel)
*/
export const selectedVehicleId = new StateHolder<string | null>(null);

/*
    ID of the POI currently selcted (shown in right panel)
*/
export const selectedPoiId = new StateHolder<string | null>(null);

/*
    Zone currently selected
*/
export const selectedZoneId = new StateHolder<string | null>(null);

/*
    ID of the queue currently selected (shown in right panel)
*/
export const selectedQueueId = new StateHolder<string | null>(null);

/*
    ID of the paddock currently selected (shown in right panel)
*/
export const selectedPaddockId = new StateHolder<string | null>(null);

/*
    User data
*/
export const user = new StateHolder<IUserAttributes | null>(null);

/*
    Mouse pointer coordinates from the map
*/
export const mousePointerCoordinates = new StateHolder<Coordinate | null>(null);

/*
    Vehicle display label setting
*/
export const displayVehicleLabelSetting = new StateHolder<boolean>(false);

/*
    Current MissionView displayed in MissionWidget inside EquipmentPanel
*/
export const missionViewContext = new StateHolder<MissionView | null>(null);

/*
    POI display label setting
*/
export const displayPoiLabelSetting = new StateHolder<boolean>(false);
