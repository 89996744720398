import { PropsWithChildren, MouseEvent } from 'react';
import { makeStyles } from '@material-ui/core';
import { grey100, grey700, grey800, grey900, grey868, grey846 } from '../../../theme/color';

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: grey900,
      border: 'none',
      color: grey100,
      textCecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      width: '368px',
      height: '68px',
      flexShrink: 0,
      borderTop: `1px solid ${grey800}`,
      padding: '0',
      margin: '0',
      outline: 'none',
      '&:hover, &:active': {
        backgroundColor: grey868,
        cursor: 'pointer',
      },
      '&:focus': {
        backgroundColor: grey846,
      },
      '& > p': {
        marginLeft: '24px',
      },
    },
    mapControlButton: {
      width: '40px',
      height: '40px',
      borderRadius: '8px',
      borderTop: '0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:active': {
        backgroundColor: grey100,
      },
    },
    mapControlButtonActive: {
      background: 'grey',
      '&:hover': {
        backgroundColor: grey700,
      },
    },
  },
  { index: 1 },
);

interface IProps {
  active?: boolean;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  testid?: string;
}

const MapControlButton = (props: PropsWithChildren<IProps>) => {
  const { active, onClick, children, testid } = props;

  const { root, mapControlButton, mapControlButtonActive } = useStyles();
  return (
    <button
      onClick={onClick}
      type="button"
      className={[root, mapControlButton].concat(active ? mapControlButtonActive : '').join(' ')}
      data-testid={testid}
    >
      {children}
    </button>
  );
};

export default MapControlButton;
