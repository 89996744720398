import { omit } from 'ramda';
import { forwardRef, LegacyRef, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import { grey100, grey800, grey846, grey868, grey900 } from '../../../theme/color';

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: grey900,
      border: 'none',
      color: grey100,
      textCecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      width: '368px',
      height: '68px',
      flexShrink: 0,
      borderTop: `1px solid ${grey800}`,
      padding: '0',
      margin: '0',
      outline: 'none',
      '&:hover, &:active': {
        backgroundColor: grey868,
        cursor: 'pointer',
      },
      '&:focus': {
        backgroundColor: grey846,
      },
      '& > p': {
        marginLeft: '24px',
      },
    },
    wrapStyle: {
      borderBottom: `1px solid ${grey800}`,
    },
    squareStyle: {
      width: '68px',
    },
    areaStyle: {
      border: 0,
      color: grey100,
      height: '48px',
      '&:hover, &:active': {
        backgroundColor: grey868,
      },
      '&:focus': {
        backgroundColor: grey846,
      },
    },
    externalHover: {
      backgroundColor: grey846,
    },
    activeStyle: {
      backgroundColor: grey868,
      borderLeft: `4px solid ${grey100}`,
      '&:hover, &:active': {
        backgroundColor: grey868,
      },
      '& > div:first-child': {
        marginLeft: '-4px',
      },
    },
  },
  { index: 1 },
);

export interface IProps {
  children?: ReactNode;
  classes?: string | ConcatArray<string>;
  onClick: () => void;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  active?: string | boolean;
  hover?: string | boolean;
  area?: string | boolean;
  square?: string | boolean;
  wrap?: string | boolean;
}

// forwardRef is used for Material UI ToolTip integration
const StandardButton = forwardRef((props: IProps, ref: LegacyRef<HTMLButtonElement> | undefined) => {
  const { children, classes = [], active, hover, area, onMouseEnter, onMouseLeave, square, wrap } = props;

  const { root, externalHover, activeStyle, areaStyle, squareStyle, wrapStyle } = useStyles();

  const hoverClass = hover ? externalHover : '';
  const activeClass = active ? activeStyle : '';
  const areaClass = area ? areaStyle : '';
  const squareClass = square ? squareStyle : '';
  const wrapClass = wrap ? wrapStyle : '';

  // all of this is also used for Material UI Tooltip
  const wrappedProps: IProps = omit(['active', 'wrap', 'area', 'hover', 'square', 'onMouseEnter', 'onMouseLeave'], {
    ...props,
  });

  // This is not pure at all
  if (wrap) wrappedProps.wrap = wrap.toString();
  if (area) wrappedProps.area = area.toString();
  if (hover) wrappedProps.hover = hover.toString();
  if (active) wrappedProps.active = active.toString();
  if (square) wrappedProps.square = square.toString();

  return (
    <button
      {...wrappedProps}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      ref={ref}
      type="button"
      className={[root, hoverClass, activeClass, areaClass, squareClass, wrapClass].concat(classes).join(' ')}
    >
      {children}
    </button>
  );
});

export default StandardButton;
