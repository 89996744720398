import GeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { useEffect, useMemo } from 'react';
import geoJsonObservable from '../../model/map/observable/geoJson.observable';
import { beige900 } from '../../theme/color';

const useSiteMapLayer = () => {
  const source = useMemo<VectorSource<Geometry>>(() => new VectorSource(), []);
  const layer = useMemo<VectorLayer<VectorSource<Geometry>>>(
    () => new VectorLayer({ source, updateWhileAnimating: true, updateWhileInteracting: true }),
    [source],
  );

  useEffect(() => {
    const subscription = geoJsonObservable.subscribe((geoJson) => {
      source.clear();

      if (geoJson) {
        const features = new GeoJSON({
          featureProjection: 'EPSG:3857',
        }).readFeatures(geoJson);

        features.forEach((feature) => {
          const type = feature.get('type');

          if (type === 'open_area_boundary') {
            feature.setStyle(
              new Style({
                fill: new Fill({
                  color: 'rgba(206, 184, 136, 0.38)',
                }),
                stroke: new Stroke({
                  color: beige900,
                  width: 1.5,
                }),
              }),
            );
          } else {
            feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: 'rgba(255, 255, 255, 0.6)',
                  width: 3,
                }),
              }),
            );
          }
        });

        source.addFeatures(features);
      }
    });

    return () => subscription.unsubscribe();
  }, [source]);

  return layer;
};

export default useSiteMapLayer;
