import getIndexClosestFeature from './getIndexClosestFeature';
import type IBisectedRoute from './IBisectedRoute';
import getIndexClosestCoordinates from '../getIndexClosestCoordinates';
import type ICoordinates from '../ICoordinates';
import type RoutedFeature from '../RoutedFeature';

const bisectOnRoad = (
  route: ReadonlyArray<RoutedFeature>,
  coordinates: ICoordinates,
  heading: number | null,
  maxDistanceMeters?: number,
): IBisectedRoute | null => {
  const closest = getIndexClosestFeature(route, coordinates, heading, maxDistanceMeters);

  if (!closest) return null;

  const { featureIndex, coordinateIndex } = closest;
  const { laneGroupRef } = route[featureIndex].properties;
  const future: RoutedFeature[] = [];
  const past: RoutedFeature[] = [];

  route.forEach((feature, index) => {
    const { geometry, properties } = feature;
    const parallelLane = !!laneGroupRef && index !== featureIndex && properties.laneGroupRef === laneGroupRef;
    let featureCoordinateIndex = coordinateIndex;

    if (parallelLane) {
      featureCoordinateIndex = getIndexClosestCoordinates(geometry.coordinates, coordinates);
    }

    if (index < featureIndex && !parallelLane) {
      past.push(feature);
    } else if (index > featureIndex && !parallelLane) {
      future.push(feature);
    } else if (!featureCoordinateIndex) {
      future.push(feature);
    } else if (featureCoordinateIndex === geometry.coordinates.length - 1) {
      past.push(feature);
    } else {
      past.push({
        ...feature,
        geometry: {
          ...geometry,
          coordinates: geometry.coordinates.slice(0, featureCoordinateIndex + 1),
        },
      });
      future.push({
        ...feature,
        geometry: {
          ...geometry,
          coordinates: geometry.coordinates.slice(featureCoordinateIndex),
        },
      });
    }
  });

  return { future, past };
};

export default bisectOnRoad;
