import { OperationalState, EquipmentMode } from '@ats/graphql';
import { t } from 'ttag';

const getVehicleStateLabel = (
  state: OperationalState | null,
  mode: EquipmentMode | null,
  status: string,
  onlyBasicInfo?: boolean,
): string | null => {
  const isOffline = status.toLowerCase() === 'offline';
  if (isOffline) return t`Offline`;
  if (mode === EquipmentMode.MODE_MANUAL) return t`Manual`;
  if (mode === EquipmentMode.MODE_SHADOW) return getState(state, 'Shadow', onlyBasicInfo);
  if (mode === EquipmentMode.MODE_AUTONOMOUS || mode === EquipmentMode.MODE_UNMANNED)
    return getState(state, 'Autonomous', onlyBasicInfo);
  if (mode === null || mode === EquipmentMode.MODE_INVALID) return '-';
  return 'Mode not found';
};

function getState(State: OperationalState | null, Mode: string, OnlyBasicInfo: boolean | undefined): string {
  switch (State) {
    case OperationalState.OPERATIONAL_STATE_STAND_DOWN:
    case OperationalState.OPERATIONAL_STATE_ENTERING_STAND_DOWN:
    case OperationalState.OPERATIONAL_STATE_LEAVING_STAND_DOWN:
      return OnlyBasicInfo ? t`Stand Down` : t`Stand Down - ${Mode}`;
    case OperationalState.OPERATIONAL_STATE_EXECUTING:
      return OnlyBasicInfo ? t`Active` : t`Active - ${Mode}`;
    case OperationalState.OPERATIONAL_STATE_EXECUTING_RESTRICTED:
    case OperationalState.OPERATIONAL_STATE_IDLE_RESTRICTED:
      return OnlyBasicInfo ? t`Deviation` : t`Deviation - ${Mode}`;
    case OperationalState.OPERATIONAL_STATE_NORMAL:
      return OnlyBasicInfo ? t`Normal` : t`Normal - ${Mode}`;
    case OperationalState.OPERATIONAL_STATE_IDLE:
      return OnlyBasicInfo ? t`Idle` : t`Idle - ${Mode}`;
    case OperationalState.OPERATIONAL_STATE_INVALID:
      return OnlyBasicInfo ? t`Invalid` : t`Invalid - ${Mode}`;
    default:
      return ''; // Let the presentation decide what to do
  }
}

export default getVehicleStateLabel;
