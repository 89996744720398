import { EquipmentEventsLog } from '@ats/graphql';
import { EquipmentEventsLog_values as EquipmentEventsLogValues } from '@ats/graphql/dist/types/simple';

type EventsLogField = 'degradations' | 'faults';

export type EventsLogRowValue = { timestamp: string; type: EventsLogField; values: string[] };

const getTimestamp = (event: EquipmentEventsLog): string => event.timestamp;

const getValues = (eventValues: EquipmentEventsLogValues, type: EventsLogField): string[] =>
  (eventValues[type] as string[]) ?? [];

const hasValues = ({ values }: { values: string[] }) => !!values.length;

const getEventsLogRowValues = (events: readonly EquipmentEventsLog[] | null) => {
  if (!events || events.length === 0) {
    return [];
  }
  return events.reduce<EventsLogRowValue[]>((result: EventsLogRowValue[], nextEvent: EquipmentEventsLog) => {
    const eventLogFields = Object.keys(nextEvent.values) as EventsLogField[];

    return [
      ...result,
      ...eventLogFields
        .map((type) => ({ values: getValues(nextEvent.values, type), timestamp: getTimestamp(nextEvent), type }))
        .filter(hasValues),
    ];
  }, []);
};

export default getEventsLogRowValues;
