import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useMissionStates, useMissions, useActionLists, MissionState } from '@ats/graphql';
import NotificationType, { INotification } from './notificationType';
import { getDisplayName } from '../vehicle/useVehicles';
import getMissionDisplayName from '../mission/getMissionDisplayName';
import getVisibility from './getVisibility';

const isExecuting = ({ executing }: MissionState) => executing !== null;
const isTerminated = (missionIdComparee: string) => ({
  failed,
  updateFailed,
  done,
  cancelled,
  missionId,
}: MissionState) =>
  (failed !== null || updateFailed !== null || done !== null || cancelled !== null) && missionId === missionIdComparee;

/*
    Preserves currently executing missions and detects a transition into a mission state
    that warrants a notification, either failed or completed
*/
const useMissionStateNotifications = (
  selectedAreaId: string | null,
  selectedEquipmentId: string | null,
  notificationOpen: boolean,
) => {
  const [states] = useMissionStates({ areaId: selectedAreaId }, false); // "false" means we will not ask for initial data
  const [missions] = useMissions({ areaId: selectedAreaId }, false); // TODO: remove the "false" to also request initial data once the API has been updated to allow for getting missions without supplying externalEquipmentReference
  const [actionLists] = useActionLists({ areaId: selectedAreaId });
  const [executingMissionIds, setExecutingMissionIds] = useState<string[]>([]);
  const [missionStateNotifications, setMissionStateNotifications] = useState<INotification[]>([]);

  /*
    Detect new mission states that are executing and not in the list
  */
  useEffect(() => {
    if (selectedAreaId === null || states === null) return;

    const allExecutingStates = states.filter(isExecuting);

    const hasNotBeenCompleted = allExecutingStates.filter((state) => {
      const { missionId } = state;
      // If the mission in the executing list here is already failed or done, then it should be removed
      const terminated = states.find(isTerminated(missionId));

      return !terminated && !executingMissionIds.includes(missionId);
    });

    if (hasNotBeenCompleted.length > 0) {
      setExecutingMissionIds((ids) => ids.concat(hasNotBeenCompleted.map(({ missionId }) => missionId)));
    }
  }, [states, selectedAreaId, setExecutingMissionIds, executingMissionIds]);

  /*
    Go through all the executing missions and detect if they are now terminated
  */

  useEffect(() => {
    if (selectedAreaId === null || states === null) return;

    const terminatedMissionIds = executingMissionIds.filter((missionId) => {
      const terminated = states.find(isTerminated(missionId));

      if (terminated && (terminated.done !== null || terminated.failed !== null)) {
        const missionAreaId = missions?.find(({ id }) => id === terminated.missionId)?.areaId;
        if (!missionAreaId) return;
        // This is the criteria for a new notification
        setMissionStateNotifications((missionStateNotifications) => {
          const { done, externalEquipmentReference } = terminated;
          const notification = {
            id: uuid(),
            notificationType: done ? NotificationType.MissionStateCompleted : NotificationType.MissionStateFailed,
            displayName: getDisplayName(externalEquipmentReference),
            missionName: getMissionDisplayName(missionId, missions, actionLists),
            visible: getVisibility(externalEquipmentReference, selectedEquipmentId, notificationOpen),
            timestamp: new Date(),
            vehicleId: externalEquipmentReference,
            areaId: missionAreaId,
            read: false,
          };
          return missionStateNotifications.concat(notification);
        });
      }
      return terminated;
    });

    if (terminatedMissionIds.length > 0) {
      setExecutingMissionIds((ids) => ids.filter((id) => !terminatedMissionIds.includes(id)));
    }
  }, [
    states,
    executingMissionIds,
    setExecutingMissionIds,
    selectedAreaId,
    missions,
    actionLists,
    selectedEquipmentId,
    notificationOpen,
  ]);

  return [missionStateNotifications];
};

export default useMissionStateNotifications;
