import { Mission, ActionList } from '@ats/graphql';
import { extractRootLevelTags } from './extractTagsData';
/**
 * This function returns the name of a mission || "Click and Drive"
 */
const getMissionDisplayName = (
  missionId: string | null,
  missions: readonly Mission[] | null,
  actionLists: readonly ActionList[] | null,
) => {
  const mission: Mission | undefined = missions?.find(({ id }) => id === missionId);
  const missionTags: Mission['tags'] = extractRootLevelTags(mission?.tags);

  const missionLabel: string | undefined =
    missionTags && missionTags.displayText !== undefined
      ? missionTags.displayText
      : actionLists?.find(({ id }) => id === mission?.actionListId)?.displayName;

  // actionListId does not exist on ClickAndDrive missions
  const clickAndDriveLabel: string = !mission?.actionListId ? 'Click and Drive' : '';

  const displayName = missionLabel || clickAndDriveLabel || 'Mission';

  return displayName;
};

export default getMissionDisplayName;
