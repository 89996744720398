import { EquipmentStatus_position as EquipmentStatusPosition } from '@ats/graphql';
import { makeStyles } from '@material-ui/core';
import { useObservable } from '@libreact/use-observable';
import { useEffect, useState } from 'react';

import { transform, fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import { Coordinate } from 'ol/coordinate';
import MapBrowserEvent from 'ol/MapBrowserEvent';

import { rotationIcon, getRadiansBetweenPoints, createLineStringStyle, createMarkerStyle } from '../Map.helpers';

import {
  mapInteractionModule as mapInteractionModuleObservable,
  clickAndDrivePosition as clickAndDrivePositionObservable,
  selectedVehiclePosition as selectedVehiclePositionObservable,
  clickAndDriveRotation,
} from '../../../model/observables';

const moduleName = 'ROTATION';
const line = new Feature({ name: 'cndSelectionLine', id: 4 });
const target = new Feature({ name: 'cndDestination', id: 5 });

let source: VectorSource<Geometry> | null = null;
let state: string | null = null;
let selectedVehiclePosition: EquipmentStatusPosition | null = null;
let clickAndDrivePosition: Coordinate | null = null;
let headingInRadians: number;

export function rotationFeatures(event: MapBrowserEvent<PointerEvent>) {
  const { coordinate } = event;
  // If we don't have the correct data or of we are not in the correct state, do nothing
  if (!selectedVehiclePosition || state !== moduleName || !clickAndDrivePosition) return true;

  const { longitude, latitude } = selectedVehiclePosition;

  if (coordinate) {
    const mouseCoordinate = transform(coordinate, 'EPSG:3857', 'EPSG:4326');
    headingInRadians = getRadiansBetweenPoints(fromLonLat(clickAndDrivePosition), fromLonLat(mouseCoordinate));
  }

  if (!longitude || !latitude) return true;

  line.setGeometry(
    new LineString(
      [[longitude, latitude], clickAndDrivePosition].map((coord) => {
        return fromLonLat([coord[0], coord[1]]);
      }),
    ),
  );
  line.setStyle(createLineStringStyle());
  target.setGeometry(new Point(fromLonLat(clickAndDrivePosition)));
  target.setStyle(createMarkerStyle([0.5, 0.5769230769230769], rotationIcon, 1.2, headingInRadians));

  return false;
}

function init() {
  if (!source) return;
  source.addFeature(line);
  source.addFeature(target);
  rotationFeatures({} as MapBrowserEvent<PointerEvent>);
}

function cleanup() {
  if (!source || !line || !target) return;
  source.removeFeature(line);
  source.removeFeature(target);
}

selectedVehiclePositionObservable.subscribe((value) => {
  selectedVehiclePosition = value;
});

mapInteractionModuleObservable.subscribe((value) => {
  if (state !== value && state === moduleName) {
    cleanup();
  }
  state = value;
  if (state === moduleName) {
    init();
  }
});

clickAndDrivePositionObservable.subscribe((value) => {
  clickAndDrivePosition = value;
});

export function addSource(_source: VectorSource<Geometry> | null) {
  if (!_source) return;
  source = _source;
}

export function click() {
  if (state !== moduleName) return true;
  clickAndDriveRotation.next(headingInRadians);
  mapInteractionModuleObservable.next('CONFIRMATION');
  return false;
}

const useStyles = makeStyles(
  {
    clickAwayRoot: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  { index: 1 },
);

export const ClickAndDriveRotationClickAwayContainer = () => {
  const [open, setOpen] = useState(false);
  const [mapInteractionModule] = useObservable(mapInteractionModuleObservable);
  const { clickAwayRoot } = useStyles();

  useEffect(() => {
    if (mapInteractionModule === moduleName) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [mapInteractionModule]);

  return open ? <div className={clickAwayRoot} onClick={() => mapInteractionModuleObservable.next('DEFAULT')} /> : null;
};
