import { SvgIcon } from '@material-ui/core';
import { ICommonIconProps } from './CommonIconProps';

const SiteMenuIcon = ({ className }: ICommonIconProps) => (
  <SvgIcon
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.75 4.98538C8.75 5.67574 9.30964 6.23538 10 6.23538C10.6904 6.23538 11.25 5.67574 11.25 4.98538C11.25 4.29503 10.6904 3.73538 10 3.73538C9.30964 3.73538 8.75 4.29503 8.75 4.98538Z"
      fill="#F9FAFB"
    />
    <path
      d="M8.75 10C8.75 10.6904 9.30964 11.25 10 11.25C10.6904 11.25 11.25 10.6904 11.25 10C11.25 9.30964 10.6904 8.75 10 8.75C9.30964 8.75 8.75 9.30964 8.75 10Z"
      fill="#F9FAFB"
    />
    <path
      d="M8.75 15.0184C8.75 15.7087 9.30964 16.2684 10 16.2684C10.6904 16.2684 11.25 15.7087 11.25 15.0184C11.25 14.328 10.6904 13.7684 10 13.7684C9.30964 13.7684 8.75 14.328 8.75 15.0184Z"
      fill="#F9FAFB"
    />
  </SvgIcon>
);

export default SiteMenuIcon;
