import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Chevron from '../../icons/Chevron';
import { blue400, grey50, grey200, grey700, grey800, grey868, grey846 } from '../../../theme/color';

interface IProps {
  options: ITimeFilterOption[];
  anchorEl: null | HTMLElement;
  handleListItemClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleChange: (index: number, updatedFilter: ITimeFilter) => void;
  handleClose: () => void;
  selectedIndex: number;
}

export enum TimeFilterDuration {
  LAST_HOUR,
  LAST_8_HOURS,
  LAST_24_HOURS,
}

export interface ITimeFilterOption {
  key: TimeFilterDuration;
  label: string;
  duration: number;
}

export interface ITimeFilter {
  start: Date;
  end: Date;
}

interface IStyleProps {
  isOpen?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      backgroundColor: grey868,
      padding: theme.spacing(5, 4),
      height: theme.spacing(14),
      width: theme.spacing(50),
      borderBottom: '1px solid',
      borderColor: (props: IStyleProps) => (props.isOpen ? blue400 : grey200),
      color: grey200,
      borderRadius: theme.spacing(1, 1, 0, 0),
      '&:hover': {
        backgroundColor: grey868,
      },
      '&:focus, &active': {
        backgroundColor: grey846,
        borderColor: blue400,
        borderWidth: '2px',
      },
      '& .MuiListItemText-root': {
        color: grey50,
      },
    },
    menu: {
      '& .MuiMenu-list': {
        padding: '0px',
      },
    },
    menuItem: {
      height: theme.spacing(12),
      width: theme.spacing(50),
      '&active': {
        backgroundColor: grey700,
      },
      '&:hover': {
        backgroundColor: grey800,
      },
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: grey50,
      },
      border: '1px solid',
      borderColor: grey800,
    },
  }),
);

export const getTimeFilterForDuration = (duration: number): ITimeFilter => {
  const end = new Date();
  const start = new Date();

  start.setHours(start.getHours() - duration);
  return {
    start,
    end,
  };
};

const TimeFilter = (prop: IProps) => {
  const { options, anchorEl, handleListItemClick, handleChange, handleClose, selectedIndex } = prop;
  const isOpen = Boolean(anchorEl);

  const { listItem, menu, menuItem } = useStyles({ isOpen });

  const handleMenuItemClick = (option: ITimeFilterOption, index: number) => {
    handleChange(index, getTimeFilterForDuration(option.duration));
  };

  return (
    <div data-testid="time-filter">
      <ListItem
        disableRipple
        button
        onClick={(event) => handleListItemClick(event)}
        className={clsx('sdds-detail-02', listItem)}
      >
        <ListItemText disableTypography primary={options[selectedIndex].label} />
        <Chevron heading={isOpen ? 180 : 0} />
      </ListItem>
      <Menu
        className={menu}
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            disableRipple
            className={clsx(menuItem, 'sdds-detail-02')}
            key={option.label}
            selected={selectedIndex === index}
            disabled={selectedIndex === index}
            onClick={() => handleMenuItemClick(option, index)}
          >
            <ListItemText disableTypography primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default TimeFilter;
