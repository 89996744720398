import Keycloak, { KeycloakConfig, KeycloakInstance } from 'keycloak-js';
import environment from './environment';

const keycloakConfig: KeycloakConfig = {
  url: environment.keycloak.url,
  realm: 'fg-ext',
  clientId: environment.keycloak.clientId,
};
const keycloakClient: KeycloakInstance = Keycloak(keycloakConfig);

export default keycloakClient;
