import { sendDriveTrajectory, useMapFiles, previewDriveTrajectory, MissionInput } from '@ats/graphql';

import { Menu, Box, Button, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { t } from 'ttag';
import clsx from 'clsx';
import { useObservable } from '@libreact/use-observable';

import Feature from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import { Coordinate } from 'ol/coordinate';

import { radiansToDegrees, confirmationIcon, createMarkerStyle } from '../Map.helpers';
import Events from '../../../model/events';

import { useCommonStyles } from '../../../theme/theme';
import { grey900 } from '../../../theme/color';

import {
  mapInteractionModule as mapInteractionModuleObservable,
  clickAndDrivePosition as clickAndDrivePositionObservable,
  clickAndDriveRotation as clickAndDriveRotationObservable,
  selectedVehicleId as selectedVehicleIdObservable,
  offsetPosition as offsetPositionObservable,
  selectedAreaId,
  previewMissionInput,
} from '../../../model/observables';
import routePreviewObservable from '../../../model/map/observable/routePreview.observable';

/*
  The Confirmation Module is a combination of a Open Layers Feature (appears as a crosshair on the map) and a React
  component with two buttons
*/

const useStyles = makeStyles(
  {
    paper: {
      backgroundColor: grey900,
      width: '222px',
      height: '88px',
      overflow: 'hidden',
      borderRadius: '4px',
    },
    confirmButton: {
      width: '90px',
      height: '56px',
    },
    cancelButton: {
      width: '84px',
      height: '56px',
    },
  },
  { index: 1 },
);

const moduleName = 'CONFIRMATION';
const FEATURE_ID = 9;
const target = new Feature({ name: 'cndDestination', id: FEATURE_ID });
let source: VectorSource<Geometry> | null = null;
let state: string | null = null;
let position: Coordinate | null = null;
let rotation: number | null = null;

clickAndDrivePositionObservable.subscribe((value) => {
  position = value;
});

clickAndDriveRotationObservable.subscribe((value) => {
  rotation = value;
});

const clickAndDrivePreview = (selectedVehicleId: string, mapVersion: string) => {
  if (!position || !rotation) throw new Error('Click and drive failed');
  const [longitude, latitude] = position;
  const heading = radiansToDegrees(rotation);
  const externalEquipmentReference = selectedVehicleId;
  const missionInput: MissionInput = {
    externalEquipmentReference,
    mapVersion,
    actions: [
      {
        actionId: 'drive_trajectory',
        arguments: { trajectory: [{ latitude, longitude, altitude: 0, heading }] },
      },
    ],
  };

  return previewDriveTrajectory({
    externalEquipmentReference,
    mapVersion,
    trajectory: [{ latitude, longitude, altitude: 0, heading }],
  })
    .then(() => {
      previewMissionInput.next(missionInput);
      return true;
    })
    .catch(() => {
      const detail = { title: 'Click and drive failed', message: 'Failed to route path to selected destination' };
      const event = new CustomEvent(Events.TOAST, { detail });
      window.dispatchEvent(event);
      return false;
    });
};

function init() {
  if (!source || !position || !rotation) return;
  target.setGeometry(new Point(fromLonLat(position)));
  target.setStyle(createMarkerStyle([0.5, 0.5], confirmationIcon, 1.0, rotation));
  source.addFeature(target);
}

function cleanup() {
  if (!source) return;
  source.removeFeature(target);
}

export function addSource(_source: VectorSource<Geometry> | null) {
  if (!_source) return;
  source = _source;
}

mapInteractionModuleObservable.subscribe((value) => {
  if (state !== value && state === moduleName) {
    cleanup();
  }
  state = value;
  if (state === moduleName) {
    init();
  }
});

const convertFromReadonlyNumbertoCoordinate = (num: readonly number[]): Coordinate => {
  return [num[0], num[1]];
};

export const ConfirmationModuleComponent = () => {
  const { paper, cancelButton, confirmButton } = useStyles();
  const { dispatchButton, secondaryButton } = useCommonStyles();

  const [open, setOpen] = useState(false);
  const [previewAvailable, setPreviewAvailable] = useState(false);
  const [mapInteractionModule] = useObservable(mapInteractionModuleObservable);
  const [selectedVehicleId] = useObservable(selectedVehicleIdObservable, null);
  const [offsetPosition] = useObservable(offsetPositionObservable);
  const [areaId] = useObservable(selectedAreaId, null);
  const UNUSED_UUID_WHEN_NO_AREA_IS_SELECTED = 'cca3f8fc-46bf-11ec-81d3-0242ac130003';
  const [mapFiles] = useMapFiles({ areaId: areaId || UNUSED_UUID_WHEN_NO_AREA_IS_SELECTED });
  const [mousePosition, setMousePosition] = useState<Coordinate | null>(null);
  const [routePreview] = useObservable(routePreviewObservable, null);
  const derivedRoutePreview = routePreview && routePreview.length ? routePreview.join('-') : '';

  const mapVersion = mapFiles?.[0]?.version;

  const reset = () => {
    clickAndDrivePositionObservable.next(null);
    clickAndDriveRotationObservable.next(null);
    previewMissionInput.next(null);
    mapInteractionModuleObservable.next('DEFAULT');
    setOpen(false);
  };
  useEffect(() => {
    if (mapInteractionModule === moduleName) {
      setOpen(true);
      setPreviewAvailable(false);
      if (!selectedVehicleId || !mapVersion) return;

      clickAndDrivePreview(selectedVehicleId, mapVersion)
        .then((_previewAvailable) => {
          if (_previewAvailable) {
            if (!routePreview?.length) return;
            const { coordinates } = routePreview[routePreview.length - 1].geometry;
            if (!coordinates || !coordinates.length || !rotation) return;
            setPreviewAvailable(true);
            source?.clear();
            const destinationCoordinates = convertFromReadonlyNumbertoCoordinate(coordinates[coordinates.length - 1]);
            target.setGeometry(new Point(fromLonLat(destinationCoordinates)));
            target.setStyle(createMarkerStyle([0.5, 0.5], confirmationIcon, 1.0, rotation));
            source?.addFeature(target);
          } else {
            reset();
          }
        })
        .catch(() => {
          const detail = { title: 'Failed to click and drive' };
          const event = new CustomEvent(Events.TOAST, { detail });
          window.dispatchEvent(event);
        });
    } else {
      setOpen(false);
    }
    // Only re-render if the routePreview array change, but use derivedRoutePreview instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInteractionModule, selectedVehicleId, mapVersion, derivedRoutePreview]);

  const confirm = () => {
    if (!position || !mapVersion || !rotation) return;
    const [longitude, latitude] = position;
    const heading = radiansToDegrees(rotation);
    const externalEquipmentReference = selectedVehicleId;
    if (!longitude || !latitude || !externalEquipmentReference || !mapVersion) return;

    sendDriveTrajectory({
      externalEquipmentReference,
      mapVersion,
      trajectory: [{ latitude, longitude, altitude: 0, heading }],
    }).catch(() => {
      window.dispatchEvent(
        new CustomEvent(Events.GENERIC_ERROR, {
          detail: { msg: 'Click and Drive mission failed - perhaps destination was out of bounds?' },
        }),
      );
    });

    reset();
  };

  const setPos = ({ detail: { position } }: CustomEvent) => {
    setMousePosition(position);
  };

  useEffect(() => {
    window.addEventListener(Events.MAP_CLICK, setPos);
    return () => window.removeEventListener(Events.MAP_CLICK, setPos);
  }, []);

  if (!offsetPosition || !mousePosition || !open) return null;

  const { x, y } = offsetPosition;
  const [xP, yP] = mousePosition;

  const left = x + xP;
  const top = y + yP;

  return (
    <Menu
      id="simple-menu"
      open
      classes={{ paper }}
      anchorPosition={{ left, top }}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      anchorReference="anchorPosition"
      onClose={() => {
        if (!previewAvailable) return;
        mapInteractionModuleObservable.next('DEFAULT');
        previewMissionInput.next(null);
      }}
      data-testid="confirmation-menu"
    >
      <Box display="flex" justifyContent="space-between">
        <Button
          disableRipple
          disabled={!previewAvailable}
          className={clsx(secondaryButton, cancelButton)}
          onClick={reset}
          data-testid="confirmation-cancel-button"
        >
          <p className="sdds-detail-02">{t`Cancel`}</p>
        </Button>
        <Button
          disableRipple
          disabled={!previewAvailable}
          className={clsx(confirmButton, dispatchButton)}
          id="save"
          onClick={confirm}
          data-testid="confirmation-confirm-button"
        >
          <p className="sdds-detail-02">{t`Dispatch`}</p>
        </Button>
      </Box>
    </Menu>
  );
};
