import { Button, Card, CardActions, CardHeader, makeStyles, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect } from 'react';
import { t } from 'ttag';
import { useCardStyles } from './Card.styles';
import { useCommonStyles } from '../../theme/theme';

const useStyles = makeStyles(
  {
    button: {
      padding: '20px',
      textTransform: 'none',
    },
  },
  { index: 1 },
);

interface IPropsDefaultView {
  canAssignMission: boolean;
  assignMission: () => void;
  renderReleaseComponent: () => JSX.Element | null;
  vehicleMode: string | null;
  setRenderButton: (render: boolean) => void;
  dispatcherRight: boolean;
}
const DefaultView = ({
  canAssignMission,
  assignMission,
  renderReleaseComponent,
  vehicleMode,
  setRenderButton,
  dispatcherRight,
}: IPropsDefaultView) => {
  const { button } = useStyles();
  const { primaryButton } = useCommonStyles();
  const { card, cardHeader, cardHeaderTypography, cardActions, noBackground } = useCardStyles();

  const isDispatchable = vehicleMode === 'Autonomous' || vehicleMode === 'Shadow';

  useEffect(() => {
    setRenderButton(true);
  }, [setRenderButton]);

  return (
    <Card className={clsx(card, noBackground)}>
      <CardHeader
        className={cardHeader}
        title={t`No Mission`}
        titleTypographyProps={{ className: clsx(cardHeaderTypography, 'sdds-headline-04') }}
        data-testid="no-mission"
      />
      <Divider />
      <CardActions className={cardActions}>
        {canAssignMission ? (
          <Button
            className={clsx(button, primaryButton, 'sdds-detail-02')}
            fullWidth
            disableRipple
            onClick={assignMission}
            disabled={!isDispatchable}
            data-testid="assign-mission-button"
          >
            {dispatcherRight ? t`Assign mission` : t`View missions`}
          </Button>
        ) : null}
        {renderReleaseComponent()}
      </CardActions>
    </Card>
  );
};

export default DefaultView;
