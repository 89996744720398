import clsx from 'clsx';
import { useState, useEffect, Dispatch, SetStateAction, MouseEvent, ChangeEvent } from 'react';
import { t } from 'ttag';
import { makeStyles, FormControlLabel, Menu, FormControl, RadioGroup, Checkbox, Divider } from '@material-ui/core';
import { grey100, grey800, grey900, grey868, grey846 } from '../../../theme/color';
import StyledRadio from '../../icons/StyledRadio';
import MapControlButton from './MapControlButton';

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: grey900,
      border: 'none',
      color: grey100,
      textCecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      width: '368px',
      height: '68px',
      flexShrink: 0,
      borderTop: `1px solid ${grey800}`,
      padding: '0',
      margin: '0',
      outline: 'none',
      '&:hover, &:active': {
        backgroundColor: grey868,
        cursor: 'pointer',
      },
      '&:focus': {
        backgroundColor: grey846,
      },
      '& > p': {
        marginLeft: '24px',
      },
    },
    label: {
      color: grey100,
    },
    layerFormControlLabel: {
      width: '135px',
      height: '47px',
      margin: '0px 0px 0px 7px',
    },
    formControlLabel: {
      width: '135px',
      height: '47px',
      margin: '0px 0px 0px 4px',
    },
    menuDivider: {
      width: '192px',
      margin: '0px 0px 0px 0px',
    },
    radioGroup: {
      marginTop: '0px',
    },
  },
  { index: 1 },
);

interface IProps {
  satelliteVisibilityState: [boolean, Dispatch<SetStateAction<boolean>>];
  showPoiNameState: [boolean, Dispatch<SetStateAction<boolean>>];
  showVehicleNameState: [boolean, Dispatch<SetStateAction<boolean>>];
  rightPanelOpen: boolean;
  testid: string;
}

const LayerButton = (props: IProps) => {
  const {
    satelliteVisibilityState: [satelliteLayerVisible, setSatelliteLayerVisible],
    showPoiNameState: [showPoiName, setShowPoiName],
    showVehicleNameState: [showVehicleName, setShowVehicleName],
    rightPanelOpen,
    testid,
  } = props;

  const { label, layerFormControlLabel, formControlLabel, menuDivider, radioGroup } = useStyles();
  const [tempSatelliteVisibilityState, setTempSatelliteVisibilityState] = useState<boolean>(satelliteLayerVisible);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (tempSatelliteVisibilityState) {
      setSatelliteLayerVisible(true);
    } else {
      setSatelliteLayerVisible(false);
    }
  }, [tempSatelliteVisibilityState, setSatelliteLayerVisible]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <MapControlButton onClick={handleClick}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-testid={testid}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.1093 13L16 4.86426L0.890747 13L6.46218 16L0.890747 19L16 27.1358L31.1093 19L25.5378 16L31.1093 13ZM23.4286 17.1358L16 21.1358L8.57144 17.1358L5.10927 19L16 24.8643L26.8907 19L23.4286 17.1358ZM16 18.8643L5.10927 13L16 7.13577L26.8907 13L16 18.8643Z"
            fill="white"
          />
        </svg>
      </MapControlButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          disablePadding: true,
          style: {
            padding: '0px 0px 0px 0px',
          },
        }}
        PaperProps={{
          style: {
            height: 192,
            width: 192,
            paddingTop: '0px',
            borderRadius: '4px',
            transform: rightPanelOpen ? 'translateX(-105%) translateY(15%)' : 'translateX(-30%) translateY(15%)',
          },
        }}
      >
        <FormControl className={radioGroup}>
          <RadioGroup
            aria-labelledby="satellite-label"
            value={tempSatelliteVisibilityState ? 'ON' : 'OFF'}
            onChange={(event) => {
              setTempSatelliteVisibilityState(event.target.value === 'ON');
              setSatelliteLayerVisible(tempSatelliteVisibilityState);
            }}
          >
            <FormControlLabel
              classes={{ label: clsx(label, 'sdds-detail-02') }}
              className={layerFormControlLabel}
              control={<StyledRadio />}
              label={t`Base layer`}
              value="OFF"
            />
            <Divider className={menuDivider} />
            <FormControlLabel
              classes={{ label: clsx(label, 'sdds-detail-02') }}
              className={layerFormControlLabel}
              control={<StyledRadio />}
              label={t`Satellite layer`}
              value="ON"
            />
          </RadioGroup>
        </FormControl>
        <Divider className={menuDivider} />
        <FormControl>
          <FormControlLabel
            checked={!!showPoiName}
            classes={{ label: clsx(label, 'sdds-detail-02') }}
            className={formControlLabel}
            control={<Checkbox color="primary" />}
            label={t`Poi names`}
            onChange={(event) => {
              setShowPoiName((event as ChangeEvent<HTMLInputElement>).target.checked);
            }}
          />
        </FormControl>
        <Divider className={menuDivider} />
        <FormControl>
          <FormControlLabel
            checked={!!showVehicleName}
            classes={{ label: clsx(label, 'sdds-detail-02') }}
            className={formControlLabel}
            control={<Checkbox color="primary" />}
            label={t`Vehicle names`}
            onChange={(event) => {
              setShowVehicleName((event as ChangeEvent<HTMLInputElement>).target.checked);
            }}
          />
        </FormControl>
      </Menu>
    </>
  );
};

export default LayerButton;
