import { Mission, Mission_actions_commands as MissionActionsCommands, Poi } from '@ats/graphql';
import { t } from 'ttag';
import prettyText from '../../prettyText';
import getCommandDestinationName from './getCommandDestinationName';

const getCommandLabel = (
  missionActionId: Mission['actionListId'],
  command: MissionActionsCommands,
  pois: ReadonlyArray<Poi> | null,
): string | null => {
  if (!command || !command.name) return null;

  if (!command.name.startsWith('drive')) {
    return prettyText(command.name);
  }

  const destination = getCommandDestinationName(missionActionId, command, pois);

  return destination ? t`Driving to ${destination}` : t`Driving`;
};
export default getCommandLabel;
