import { Area } from '@ats/graphql';
import { makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { grey100, grey50, grey900 } from '../../theme/color';
import IVehicle from '../../model/vehicle/IVehicle';
import getSortedVehicles from '../../model/vehicle/state/getSortedVehicles';
import StandardButton from './button/StandardButton';
import getVehicleStateLabel from '../../model/vehicle/state/getVehicleStateLabel';
import TinyVehicleButton from './button/TinyVehicleButton';
import ExpandButton from './button/ExpandButton';

const useStyles = makeStyles(
  {
    tooltip: {
      background: grey50,
      '& > p': {
        color: grey900,
        margin: 0,
      },
    },
    siteMinimized: {
      color: grey100,
      display: 'block',
      textAlign: 'center',
      justifyContent: 'center',
      borderBottom: 'none',
      marginTop: '-1px',
    },
  },
  { index: 1 },
);

interface IProps {
  areas: readonly Area[];
  equipmentStatuses: readonly IVehicle[] | null;
  selectedAreaState: [string | null, React.Dispatch<React.SetStateAction<string | null>>];
  snapState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  hoverEquipmentState: [string | null, React.Dispatch<React.SetStateAction<string | null>>];
  selectedEquipmentState: [string | null, (s: string | null) => void];
  setMinimized: React.Dispatch<React.SetStateAction<boolean>>;
}

const MinimizedPanel = (props: IProps) => {
  const {
    areas,
    selectedAreaState,
    equipmentStatuses,
    hoverEquipmentState,
    selectedEquipmentState,
    snapState,
    setMinimized,
  } = props;
  const { siteMinimized, tooltip } = useStyles();
  const [selectedAreaId] = selectedAreaState;
  const [, setSnap] = snapState;
  const [hoverEquipmentId, setHoverEquipmentId] = hoverEquipmentState;
  const [selectedEquipmentId, setSelectedEquipmentId] = selectedEquipmentState;
  const area = areas?.find(({ id }) => id === selectedAreaId) ?? undefined;
  const areaDisplayName = area?.displayName ?? '';
  const sortedEquipment = getSortedVehicles(equipmentStatuses);

  return (
    <>
      <Tooltip placement="right" title={<p>{areaDisplayName}</p>} classes={{ tooltip }}>
        <StandardButton
          square
          wrap
          classes={['sdds-headline-04', siteMinimized]}
          onClick={() => setMinimized(false)}
          data-testid="maximize-left-panel"
        >
          {areaDisplayName?.[0]}
        </StandardButton>
      </Tooltip>
      {sortedEquipment?.map(({ displayName, externalEquipmentReference, operationalState, mode, status, position }) => (
        <Tooltip
          placement="right"
          key={externalEquipmentReference}
          classes={{ tooltip }}
          title={
            <>
              <p>{displayName || 'Name missing'}</p>
              <p>{getVehicleStateLabel(operationalState, mode, status)}</p>
            </>
          }
        >
          <TinyVehicleButton
            active={externalEquipmentReference === selectedEquipmentId}
            hover={externalEquipmentReference === hoverEquipmentId}
            key={externalEquipmentReference}
            onMouseEnter={() => setHoverEquipmentId(externalEquipmentReference)}
            onMouseLeave={() => setHoverEquipmentId(null)}
            onClick={() => {
              if (selectedEquipmentId && externalEquipmentReference === selectedEquipmentId) {
                setSelectedEquipmentId(null);
              } else {
                setSnap(position !== null); // If we don't have a position for the vehicle we should not try to snap to it
                setSelectedEquipmentId(externalEquipmentReference);
              }
            }}
            operationalState={operationalState}
            mode={mode}
            status={status}
          />
        </Tooltip>
      ))}
      <ExpandButton onClick={() => setMinimized(false)} />
    </>
  );
};

export default MinimizedPanel;
