/* istanbul ignore file */ // Ignore in test coverage
export const poiIcon = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15" cy="15" r="15" fill="#0D0F13"/>
<path d="M15 23C19.4183 23 23 19.4183 23 15C23 10.5817 19.4183 7 15 7C10.5817 7 7 10.5817 7 15C7 19.4183 10.5817 23 15 23Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15ZM27 15C27 21.6274 21.6274 27 15 27C8.37258 27 3 21.6274 3 15C3 8.37258 8.37258 3 15 3C21.6274 3 27 8.37258 27 15Z" fill="white"/>
</svg>
`;

export const poiHeadingIcon = `<svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.52566 9.84189L1.30629 10.5H2H6H6.69371L6.47434 9.84189L4.47434 3.84189L4 2.41886L3.52566 3.84189L1.52566 9.84189Z" fill="white" stroke="#0D0F13" stroke-linecap="round"/>
</svg>`;

export const markerIcon = `<svg width="26" height="37" viewBox="0 0 26 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.5 13.3999C25.5 15.7426 24.7189 18.352 23.5144 20.9732C22.3126 23.5886 20.7055 26.1809 19.0908 28.4799C17.4772 30.7776 15.8624 32.7731 14.6507 34.1946C14.0451 34.905 13.5408 35.4713 13.1884 35.8595C13.1197 35.9351 13.0568 36.004 13 36.0659C12.9432 36.004 12.8803 35.9351 12.8116 35.8595C12.4592 35.4713 11.9549 34.905 11.3493 34.1946C10.1376 32.7731 8.52284 30.7776 6.90917 28.4799C5.29451 26.1809 3.68742 23.5886 2.48558 20.9732C1.28108 18.352 0.5 15.7426 0.5 13.3999C0.5 6.49634 6.09644 0.899902 13 0.899902C19.9036 0.899902 25.5 6.49634 25.5 13.3999Z" fill="#1D2229" stroke="white"/>
<circle cx="13" cy="13.3999" r="6" fill="#1D2229" stroke="white"/>
</svg>`;
export const poiDisabledIcon = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15" cy="15" r="15" fill="#0D0F13"/>
<path d="M15 23C19.4183 23 23 19.4183 23 15C23 10.5817 19.4183 7 15 7C10.5817 7 7 10.5817 7 15C7 19.4183 10.5817 23 15 23Z" fill="grey"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15ZM27 15C27 21.6274 21.6274 27 15 27C8.37258 27 3 21.6274 3 15C3 8.37258 8.37258 3 15 3C21.6274 3 27 8.37258 27 15Z" fill="grey"/>
</svg>
`;
