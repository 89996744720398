import IVehicle from '../model/vehicle/IVehicle';
import useVehicles from '../model/vehicle/useVehicles';

export interface IWithEquipmentProps {
  areaId: string | null;
  equipmentStatuses: readonly IVehicle[] | null;
}

function withEquipment<P extends IWithEquipmentProps>(Component: React.ComponentType<P>) {
  const EnhancedComponent = (props: Partial<P & IWithEquipmentProps>) => {
    const { areaId } = props;
    //   // No area id gives all vehicles in system. When no area is selected, make sure to not ask for any vehicles
    const UNUSED_UUID_FOR_NO_FETCHING_OF_VEHICLES = '3c42de71-53ef-4785-8d57-161e76694211';
    const [equipmentStatuses] = useVehicles({ areaId: areaId || UNUSED_UUID_FOR_NO_FETCHING_OF_VEHICLES });

    // When there is no area id, empty array would indicate that the vehicles have loaded but that there is no vehicles in the area
    return <Component {...(props as P)} equipmentStatuses={areaId ? equipmentStatuses : null} />;
  };

  return EnhancedComponent;
}

export default withEquipment;
