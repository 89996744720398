/* istanbul ignore file */ // Ignore in test coverage
const paddockIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <circle cx="16" cy="16" r="15.5" fill="white" stroke="#0D0F13" />
  <path
    d="M25.2832 12.7431C25.1989 12.265 24.8051 11.9181 24.327 11.89L19.1145 11.6087L18.7301 12.865V17.515H6.83324V18.1806V18.4525V20.515H9.44886C9.58949 20.9837 9.94574 21.3587 10.4051 21.5462C10.4426 21.565 10.4801 21.5744 10.5176 21.5837C10.6676 21.6306 10.8364 21.6587 11.0051 21.6587C11.727 21.6587 12.3364 21.1806 12.5426 20.5244C12.5895 20.365 12.6176 20.2056 12.6176 20.0275C12.6176 19.8681 12.5895 19.7087 12.552 19.5587L13.5082 20.515H16.977H17.4457H19.8739C20.0707 21.1712 20.6895 21.6587 21.4114 21.6587C22.302 21.6587 23.0239 20.9369 23.0239 20.0462C23.0239 19.1556 22.302 18.4337 21.4114 18.4337C20.6895 18.4337 20.0707 18.9119 19.8739 19.5775H17.4457V18.4431H19.0489L19.2739 19.0244H19.8457L19.9864 18.8556C20.3426 18.4337 20.8582 18.19 21.402 18.19C22.4239 18.19 23.2582 19.0244 23.2582 20.0462V20.7962H25.7801V15.6025L25.2832 12.7431ZM9.44886 19.5775H7.77074V18.4525H10.6489C10.0676 18.5837 9.60824 19.015 9.44886 19.5775ZM11.0239 20.7212C10.6489 20.7212 10.3489 20.4212 10.3489 20.0462C10.3489 19.6712 10.6489 19.3712 11.0239 19.3712C11.3989 19.3712 11.6989 19.6712 11.6989 20.0462C11.6989 20.4212 11.3895 20.7212 11.0239 20.7212ZM14.7457 19.5869H13.902L12.7676 18.4525H14.7457V19.5869ZM16.5082 19.5869H15.6832V18.4525H16.5082V19.5869ZM21.4114 19.3806C21.7864 19.3806 22.0864 19.6806 22.0864 20.0556C22.0864 20.4306 21.7864 20.7306 21.4114 20.7306C21.0364 20.7306 20.7364 20.4306 20.7364 20.0556C20.7364 19.6806 21.0457 19.3806 21.4114 19.3806ZM24.8145 15.5087L21.552 15.0306L21.2051 14.2244L21.252 13.8962L24.5426 13.9619L24.8145 15.5087ZM24.8426 17.89H24.3082V19.4744L24.8426 19.6056V19.8681H24.1957C24.102 18.415 22.8832 17.2619 21.4114 17.2619C20.8207 17.2619 20.2582 17.4494 19.7895 17.7775L19.6489 17.4212V13.0056L19.7801 12.5837L24.2707 12.8181C24.3176 12.8181 24.3457 12.8556 24.3551 12.8931L24.3739 13.0244L20.4364 12.94L20.2301 14.3462L20.8864 15.8744L24.8332 16.4556V17.89H24.8426Z"
    fill="#0D0F13"
  />
  <path
    d="M13.3582 8.99999L5.99998 16.3582L7.39209 17.7503L14.7503 10.3921L13.3582 8.99999Z"
    stroke="#0D0F13"
    stroke-miterlimit="10"
  />
  <path d="M17.155 17.8055L12.3738 13.0243" stroke="#0D0F13" stroke-miterlimit="10" />
</svg>
`;

export default paddockIcon;
