import { makeStyles } from '@material-ui/core';

import { useState, useEffect } from 'react';
import { TransitionGroup, Transition } from 'react-transition-group';
import NotificationCard from './NotificationCard';
import { highEmphasisLightTheme, grey50 } from '../../theme/color';

import NotificationDisplayType from '../../model/notification/NotificationDisplayType';
import getColor from '../../model/notification/getColor';
import { INotification } from '../../model/notification/notificationType';
import getLabel from '../../model/notification/getLabel';
import getDescription from '../../model/notification/getDescription';
import NotificationCardContainer from './NotificationCard.container';

interface IProps {
  notifications: INotification[];
  selectedEquipmentIdState: [string | null, (s: string | null) => void];
  setSnap: (arg0: boolean) => void;
  markAsNotVisible: (id?: string) => void;
  markAsRead: (id: string) => void;
  selectedAreaId: string | null;
  notificationOpen: boolean;
}

const useStyles = makeStyles({
  root: {
    zIndex: 1500,
    position: 'absolute',
    top: 80,
    right: 24,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  notificationPopup: {
    marginBottom: '8px',
    backgroundColor: grey50,
    color: highEmphasisLightTheme,
    borderRadius: '4px',
  },
});

const NotificationPopupContainer = (props: IProps) => {
  const {
    notifications,
    selectedEquipmentIdState: [selectedEquipmentId, setSelectedEquipmentId],
    setSnap,
    markAsNotVisible,
    selectedAreaId,
    markAsRead,
    notificationOpen,
  } = props;

  const { notificationPopup, root } = useStyles();
  const isVisible = (notification: INotification) => notification?.visible === true;
  const isOnArea = (notification: INotification) => notification?.areaId === selectedAreaId;
  const belongToSelectedVehicle = (notification: INotification) => notification.vehicleId === selectedEquipmentId;
  const relevantNotifications = notifications.filter(isVisible).filter(isOnArea);

  const [hovering, setHovering] = useState(false);
  const [pausedTime, setPausedTime] = useState<Date | null>(null);
  const [show, setShow] = useState(false);
  const duration = 400;
  const height = 104;

  const defaultStyle: React.CSSProperties = {
    transition: `all ${duration}ms ease-in-out`,
    opacity: 0,
    height: `${height}px`,
    display: 'flex',
    flexDirection: 'column-reverse',
    overflow: 'hidden',
  };
  const transitionStyles: { [id: string]: React.CSSProperties } = {
    entering: { transform: 'translate(0%, -100%)', opacity: 0.1, height: '0px' },
    entered: { opacity: 1, height: `${height}px` },
    exiting: { transform: 'translate(100%, 0%)', opacity: 1 },
    exited: { opacity: 0.1 },
  };

  const clickHandler = (vehicleId: string, id: string) => {
    if (id) {
      setSelectedEquipmentId(vehicleId);
      setSnap(true);
      popupClosing(id);
      markAsRead(id);
    }
  };

  const popupClosing = (id: string) => {
    setHovering(false);
    setPausedTime(null);
    markAsNotVisible(id);
  };

  useEffect(() => {
    if (notificationOpen) {
      setPausedTime(null);
    }
  }, [notificationOpen, setPausedTime]);

  useEffect(() => {
    relevantNotifications.filter(belongToSelectedVehicle).forEach((note) => popupClosing(note.id));
    // Only re-render if the selectedEquipmentId array change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEquipmentId]);

  if (!notifications) return null;

  return (
    <>
      {!notificationOpen && (
        <div className={root}>
          <TransitionGroup>
            {relevantNotifications.map((note) => (
              <Transition
                key={note.id}
                in={show}
                timeout={duration}
                unmountOnExit
                onEnter={() => setShow(true)}
                onExit={() => setShow(false)}
              >
                {(state) => (
                  <div
                    style={{
                      ...defaultStyle,
                      ...transitionStyles[state],
                    }}
                  >
                    <NotificationCardContainer
                      hoverState={[hovering, setHovering]}
                      pausedState={[pausedTime, setPausedTime]}
                      markAsNotVisible={() => popupClosing(note.id)}
                      timestamp={note.timestamp}
                      key={note.id}
                    >
                      <NotificationCard
                        notificationDisplayType={NotificationDisplayType.Popup}
                        onClick={() => clickHandler(note.vehicleId, note.id)}
                        label={getLabel(note.notificationType)}
                        description={getDescription(note.notificationType, note.displayName, note.missionName)}
                        color={getColor(note)}
                        className={notificationPopup}
                        timestamp={note.timestamp}
                        read={note.read}
                      />
                    </NotificationCardContainer>
                  </div>
                )}
              </Transition>
            ))}
          </TransitionGroup>
        </div>
      )}
    </>
  );
};

export default NotificationPopupContainer;
