import { useState, useEffect, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { ConnectivityStatus, useConnectivityStatus } from '@ats/graphql';
import NotificationType, { INotification } from './notificationType';
import { getDisplayName } from '../vehicle/useVehicles';
import getVisibility from './getVisibility';

type HistoricStates = Record<string, ConnectivityStatus>;

const isUnexpectedOffline = ({ status, reason }: ConnectivityStatus) =>
  status !== null && status.toLowerCase() === 'offline' && reason?.toLowerCase() !== 'expected';

const useConnectivityStatusNotifications = (
  selectedAreaId: string | null,
  selectedEquipmentId: string | null,
  notificationOpen: boolean,
) => {
  const [statusSnapshot, setStatusSnapshot] = useState<HistoricStates>({});
  const [connectivityStatuses] = useConnectivityStatus({
    externalEquipmentReference: undefined,
    areaId: selectedAreaId,
  });

  const [connectivitiyStatusNotifications, setConnectivitiyStatusNotifications] = useState<INotification[]>([]);

  const toConnectivityStatusNotification = useCallback(
    (status: ConnectivityStatus): INotification => ({
      id: uuid(),
      notificationType: NotificationType.UnexpectedOffline,
      displayName: getDisplayName(status.externalEquipmentReference),
      missionName: undefined,
      visible: getVisibility(status.externalEquipmentReference, selectedEquipmentId, notificationOpen),
      timestamp: new Date(),
      vehicleId: status.externalEquipmentReference,
      areaId: selectedAreaId,
      read: false,
    }),

    [notificationOpen, selectedAreaId, selectedEquipmentId],
  );

  const derivedConnectivityStatuses = connectivityStatuses?.map(({ status }) => status).join('-');

  useEffect(() => {
    if (!connectivityStatuses) return;

    const updatedHistoricStatus = connectivityStatuses.reduce(
      (result: HistoricStates, current): HistoricStates => ({
        ...result,
        [current.externalEquipmentReference]: current,
      }),
      {},
    );

    setStatusSnapshot(updatedHistoricStatus);
    // Detect unique signature in connectivity status to prevent rerendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedConnectivityStatuses]);

  useEffect(() => {
    if (!connectivityStatuses || !selectedAreaId) return;

    const wasOfflineBefore = (status: ConnectivityStatus) => {
      const pastStatus = statusSnapshot[status.externalEquipmentReference];
      const wasOfflineBefore = pastStatus ? isUnexpectedOffline(pastStatus) : false;
      return wasOfflineBefore;
    };

    const unexpectedOfflineNotifications = connectivityStatuses
      .filter((val) => isUnexpectedOffline(val) && !wasOfflineBefore(val))
      .map(toConnectivityStatusNotification);

    if (unexpectedOfflineNotifications.length > 0) {
      setConnectivitiyStatusNotifications(unexpectedOfflineNotifications);
    }
  }, [
    connectivityStatuses,
    notificationOpen,
    selectedAreaId,
    statusSnapshot,
    selectedEquipmentId,
    toConnectivityStatusNotification,
  ]);

  return [connectivitiyStatusNotifications];
};

export default useConnectivityStatusNotifications;
