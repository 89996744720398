import { makeStyles, List, ListItem } from '@material-ui/core';
import { usePaddockWithSpots } from '@ats/graphql';
import { useObservable } from '@libreact/use-observable';
import { grey100, grey700, grey900 } from '../../theme/color';
import {
  selectedAreaId as selectedAreaIdObservable,
  selectedPaddockId as selectedPaddockIdObservable,
} from '../../model/observables';
import { Close } from '../icons';
import { Selected } from '../../model/type/Selected';
import ZoneDisplayName from './ZoneDisplayName';
import PaddockIcon from '../icons/PaddockIcon';
import PaddockStatusContainer from './PaddockStatus.container';

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: grey900,
      borderTop: `8px solid ${grey700}`,
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 64px)',
      position: 'relative',
    },
    zoneSummary: {
      width: '368px',
      height: '316px',
      flexShrink: 0,
      color: 'white',
    },
    icons: {
      position: 'absolute',
      top: '164px',
      display: 'flex',
      left: '93px',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    queueIcon: {
      width: '56px',
      height: '56px',
    },
    paddockIcon: {
      width: '56px',
      height: '56px',
    },
    dashedLine: {
      width: '56px',
      height: 'auto',
    },
    close: {
      position: 'absolute',
      color: grey100,
      top: '2px',
      left: '10px',
      width: '40px',
      height: '40px',
      cursor: 'pointer',
    },
  },
  { index: 1 },
);

interface IProps {
  selectedEquipmentState: [string | null, (id: string | null) => void];
  setSelected: (selected: Selected) => void;
}

const PaddockPanel = (props: IProps) => {
  const { selectedEquipmentState, setSelected } = props;
  const { root, zoneSummary, icons, paddockIcon, close } = useStyles();

  const [areaId] = useObservable(selectedAreaIdObservable, null);
  const [selectedPaddockId] = useObservable(selectedPaddockIdObservable, null);
  const [paddocks] = usePaddockWithSpots({ areaId });

  const paddock =
    selectedPaddockId && paddocks
      ? paddocks.find((p: { paddockId: string }) => p.paddockId === selectedPaddockId)
      : null;

  return (
    <div className={root} id="PaddockPanel">
      {paddock && selectedPaddockId ? (
        <>
          <div className={zoneSummary} id="paddockSummary">
            <Close click={() => setSelected(null)} className={close} />
            <ZoneDisplayName displayName={paddock.paddockId} />
            <div className={icons}>
              <PaddockIcon className={paddockIcon} />
            </div>
          </div>

          <PaddockStatusContainer
            paddockId={selectedPaddockId}
            areaId={areaId}
            selectedEquipmentState={selectedEquipmentState}
          />
        </>
      ) : (
        <div id="paddockList">
          <Close click={() => setSelected(null)} className={close} />
          {paddocks && paddocks.length ? (
            <div className={zoneSummary} style={{ marginTop: 30 }}>
              <List>
                {paddocks.map((p) => (
                  <ListItem key={p.paddockId} onClick={() => selectedPaddockIdObservable.next(p.paddockId)}>
                    <PaddockIcon className={paddockIcon} />
                    <span style={{ padding: 10 }}>{p.paddockId}</span>
                  </ListItem>
                ))}
              </List>
            </div>
          ) : (
            paddocks && !paddocks.length && <span style={{ padding: 30 }}>No paddocks</span>
          )}
        </div>
      )}
    </div>
  );
};

export default PaddockPanel;
