import * as yup from 'yup';
import { PoiInput, Position as PositionInput, savePoi, usePoi, Poi as IPointOfInterest } from '@ats/graphql';
import { TextField, FormLabel, Button, Divider, InputAdornment } from '@material-ui/core';
import clsx from 'clsx';
import { Formik, FormikHelpers } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import { t } from 'ttag';
import { useObservable } from '@libreact/use-observable';
import {
  mapInteractionModule as mapInteractionModuleObservable,
  poiPosition as poiPositionObservable,
  poiRotation as poiRotationObservable,
} from '../../model/observables';
import { PoiMarkerIcon } from '../icons/PoiMarkerIcon';
import { PoiIcon } from '../icons/PoiIcon';
import { radiansToDegrees } from '../map/Map.helpers';
import { usePoiPositionFormStyles } from './Form.styles';
import { useCommonStyles } from '../../theme/theme';
import PoiInputErrorCard from './PoiInputErrorCard';
import decimalPrecision from '../../model/decimalPrecision';

interface IProperties {
  areaId: string | null;
  pointOfInterest: IPointOfInterest;
  onSubmit: (formValues: PositionInput) => void;
}

interface IPosition {
  altitude: number;
  heading: number;
  longitude: number;
  latitude: number;
}

export const useSavePoi = (poiId: string, areaId: string | null) => {
  const [pois] = usePoi({ areaId });
  const pointOfInterest: IPointOfInterest | undefined = pois?.find(({ id }) => id === poiId);

  return (partial: Partial<PoiInput>) => {
    if (pointOfInterest) {
      const { id, ...poi } = pointOfInterest;
      const updatedPoi = { ...poi, ...partial };
      if (updatedPoi.areaId) {
        savePoi(id, updatedPoi.areaId, {
          type: updatedPoi.type,
          position: updatedPoi.position,
          displayName: updatedPoi.displayName,
        });
      }
    }
  };
};

const PoiPositionForm = (properties: IProperties) => {
  const {
    cancelButton,
    divider,
    editButton,
    form,
    label,
    poiIcon,
    saveButton,
    selectOnMapButton,
    tableRow,
    table,
    textField,
  } = usePoiPositionFormStyles();
  const { ghostButton } = useCommonStyles();
  const { pointOfInterest, onSubmit, areaId } = properties;
  const savePoi = useSavePoi(pointOfInterest.id, areaId);
  const [editable, setEditable] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [newHeading, setNewHeading] = useState(Number);
  const [newLongtitude, setNewLongitude] = useState(Number);
  const [newLatitude, setNewLatitude] = useState(Number);
  const [heading, setHeading] = useState(Number);
  const [longitude, setLongitude] = useState(Number);
  const [latitude, setLatitude] = useState(Number);
  const [altitude, setAltitude] = useState(Number);

  const updatedHeading: number = radiansToDegrees(newHeading) || heading;
  const updatedLatitude: number = newLatitude || latitude;
  const updatedLongitude: number = newLongtitude || longitude;
  const [mapInteractionModule] = useObservable(mapInteractionModuleObservable);
  const handleEdit = (): void => {
    setEditable(true);
    setDisable(false);
  };
  const handleClick = (): void => {
    mapInteractionModuleObservable.next('POIPOSITION');
    setDisable(true);
  };

  const handleSubmit = (position: IPosition, formikHelpers: FormikHelpers<IPosition>) => {
    const positionInput: PositionInput = {
      altitude: position.altitude,
      heading: position.heading,
      latitude: position.latitude,
      longitude: position.longitude,
    };
    const partial = { position: positionInput };
    savePoi(partial);
    formikHelpers.setSubmitting(false);
    setEditable(false);
    onSubmit(position);
  };
  useEffect(() => {
    if (pointOfInterest && pointOfInterest.id) {
      setEditable(false);
    }
  }, [pointOfInterest, pointOfInterest.id]);

  useEffect(() => {
    if (
      pointOfInterest &&
      pointOfInterest?.position.heading !== null &&
      pointOfInterest?.position.longitude !== null &&
      pointOfInterest?.position.latitude !== null &&
      pointOfInterest?.position.altitude !== null
    ) {
      setHeading(pointOfInterest.position.heading);
      setLongitude(decimalPrecision(pointOfInterest.position.longitude, 7));
      setLatitude(decimalPrecision(pointOfInterest.position.latitude, 7));
      setAltitude(pointOfInterest.position.altitude);
    }
  }, [heading, longitude, latitude, altitude, pointOfInterest]);

  useEffect(() => {
    const subscription = poiRotationObservable.subscribe((value) => {
      if (!value && value !== undefined) return;
      setNewHeading(value);
    });
    return () => subscription.unsubscribe();
  }, [newHeading]);

  useEffect(() => {
    const subscription = poiPositionObservable.subscribe((value) => {
      if (!value && value !== undefined) return;
      const [longtitude, latitude] = Object.values(value);
      setNewLongitude(decimalPrecision(longtitude, 7));
      setNewLatitude(decimalPrecision(latitude, 7));
    });
    return () => subscription.unsubscribe();
  }, [newLongtitude, newLatitude]);

  useEffect(() => {
    const subscription = mapInteractionModuleObservable.subscribe((value) => {
      if (value === 'DEFAULT') setDisable(false);
    });
    return () => subscription.unsubscribe();
  }, []);

  const handleReset = (): void => {
    setEditable(false);
    setNewLongitude(0);
    setNewLatitude(0);
    setNewHeading(0);
    setHeading(0);
    setLongitude(0);
    setLatitude(0);
    setAltitude(0);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const exceptThisSymbols = ['e', 'E', '.'];
    return exceptThisSymbols.includes(e.key) && e.preventDefault();
  };

  const commonValidator = (decimals: number, min: number, max: number) =>
    yup
      .number()
      .test(
        'decimal-precision',
        `The number of decimals exceeds the maximum precision (${decimals} decimals)`,
        (val: number | undefined) => {
          if (val !== undefined) {
            return val === decimalPrecision(val, decimals);
          }
          return true;
        },
      )
      .min(min, `minimum acceptable value is ${min}`)
      .max(max, `maximum acceptable value is ${max}`)
      .required('Field is required');

  const validationSchema = yup.object({
    latitude: commonValidator(7, -90, 90),
    longitude: commonValidator(7, -180, 180),
    heading: commonValidator(7, 0, 360),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        altitude,
        heading: updatedHeading,
        latitude: updatedLatitude,
        longitude: updatedLongitude,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { values, isSubmitting, handleSubmit, handleChange, errors, isValid } = props;
        return (
          <form onSubmit={handleSubmit} className={form}>
            {editable && <PoiIcon heading={values.heading} className={poiIcon} />}
            {!editable && <PoiMarkerIcon className={poiIcon} />}
            <div className={table}>
              <div className={tableRow}>
                <FormLabel id="latitude" className={clsx(label, 'sdds-detail-03')}>
                  {t`Latitude`}
                </FormLabel>
                <TextField
                  aria-labelledby="latitude"
                  id="latitude"
                  className={clsx(textField, 'sdds-detail-03')}
                  InputProps={{
                    inputProps: {
                      'data-testid': 'poi-position-form-latitude',
                    },
                    disableUnderline: true,
                  }}
                  placeholder="-"
                  type="number"
                  value={values.latitude}
                  onChange={handleChange}
                  onPaste={() => false}
                  disabled={!editable}
                  onKeyDown={handleKeyDown}
                />
                {errors.latitude && <PoiInputErrorCard description={errors.latitude} />}
              </div>
              <div className={tableRow}>
                <FormLabel className={clsx(label, 'sdds-detail-03')}>{t`Longitude`}</FormLabel>
                <TextField
                  className={clsx(textField, 'sdds-detail-03')}
                  disabled={!editable}
                  id="longitude"
                  InputProps={{
                    inputProps: {
                      'data-testid': 'poi-position-form-longitude',
                    },
                    disableUnderline: true,
                  }}
                  placeholder="-"
                  type="number"
                  value={values.longitude}
                  onChange={handleChange}
                  onPaste={() => false}
                  onKeyDown={handleKeyDown}
                />
                {errors.longitude && <PoiInputErrorCard description={errors.longitude} />}
              </div>
              <div className={tableRow}>
                <FormLabel className={clsx(label, 'sdds-detail-03')}>{t`Heading`}</FormLabel>
                <TextField
                  className={clsx(textField, 'sdds-detail-03')}
                  InputProps={{
                    inputProps: {
                      'data-testid': 'poi-position-form-heading',
                    },
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end" style={{ marginLeft: 0 }}>
                        <h5>{'\u00b0'}</h5>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="-"
                  id="heading"
                  type="number"
                  value={values.heading}
                  onChange={handleChange}
                  onPaste={() => false}
                  onKeyDown={handleKeyDown}
                  disabled={!editable}
                />
                {errors.heading && <PoiInputErrorCard description={errors.heading} />}
              </div>
              <div className={tableRow}>
                {editable && (
                  <>
                    <Button
                      disableRipple
                      disabled={disable}
                      className={clsx(selectOnMapButton, ghostButton, 'sdds-detail-02')}
                      onClick={handleClick}
                      data-testid="poi-select-on-map-button"
                    >
                      {t`Select on map`}
                    </Button>
                  </>
                )}
              </div>
              {editable && <Divider className={divider} />}
              {!editable && <Divider className={divider} style={{ marginTop: '41px' }} />}
            </div>
            {!editable && (
              <>
                <Button
                  onClick={handleEdit}
                  className={clsx(editButton, 'sdds-detail-02')}
                  data-testid="poi-position-form-edit"
                >
                  {t`Edit Information`}
                </Button>
              </>
            )}
            {editable && (
              <>
                <Button
                  onClick={handleReset}
                  disabled={isSubmitting}
                  className={clsx(cancelButton, 'sdds-detail-02')}
                  data-testid="poi-position-form-cancel"
                >
                  {t`Cancel`}
                </Button>
                {mapInteractionModule === 'DEFAULT' ? (
                  <Button
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    className={clsx(saveButton, 'sdds-detail-02')}
                    data-testid="poi-position-form-save"
                  >
                    {t`Save`}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    disabled
                    className={clsx(saveButton, 'sdds-detail-02')}
                    data-testid="poi-position-form-save"
                  >
                    {t`Save`}
                  </Button>
                )}
              </>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default memo(PoiPositionForm);
