import { GraphQLResult } from '@aws-amplify/api';

export interface IError extends Error {
  error: GraphQLResult;
}

function convertToHumanReadable(str: string) {
  if (str.includes('MaxSubscriptionsReachedError')) return 'AWS connection leak (maximum 100 connecton reached)';

  return str;
}

const decipherErrorMessage = (e: IError) => {
  const messages = e?.error?.errors ? e.error.errors.map((x) => x.message) : [];

  return messages.map(convertToHumanReadable).join(', ');
};

export default decipherErrorMessage;
