import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { grey900 } from '../../../theme/color';

import prettyText from '../../../model/prettyText';

export const useStyles = makeStyles({
  card: {
    boxShadow: 'none',
    width: '100%',
    backgroundColor: grey900,
    cursor: 'pointer',
  },
});

interface IProps {
  name: string;
  value?: string;
  icon: JSX.Element;
}

const TelemetryCard = ({ name, value, icon }: IProps) => {
  const { card } = useStyles();

  return (
    <div className={clsx(card, 'sdds-card')}>
      <div className={clsx('sdds-card-header')}>
        <h6
          style={{ color: 'white' }}
          className={clsx('sdds-card-headline')}
          data-testid={`telemetry-metric-title-${name}`}
        >
          {prettyText(name)}
        </h6>
        <h6 className={clsx('sdds-card-sub-headline')} data-testid={`telemetry-metric-value-${name}`}>
          {value}
        </h6>
      </div>
      <div className={clsx('sdds-card-footer')} data-testid={`telemetry-metric-icon-${name}`}>
        {icon}
      </div>
    </div>
  );
};

export default TelemetryCard;
