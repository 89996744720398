import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { t } from 'ttag';

import { grey100, grey300, grey600 } from '../../theme/color';

interface IProps {
  testId: string;
  onClick: () => void;
  disabled: boolean;
}

export const useStyles = makeStyles(
  {
    typography: {
      color: grey300,
      marginBottom: '4px',
    },
    button: {
      border: `1px solid ${grey600}`,
      color: grey100,
      marginTop: '8px',
      padding: '16px',
      textTransform: 'none',
    },
  },
  { index: 1 },
);

const ClickAndDriveButton = ({ testId, onClick, disabled }: IProps) => {
  const { button } = useStyles();

  return (
    <>
      <Button
        className={clsx(button, 'sdds-detail-02')}
        fullWidth
        disableRipple
        onClick={onClick}
        data-testid={testId}
        disabled={disabled}
      >
        {t`Click and drive`}
      </Button>
    </>
  );
};

export default ClickAndDriveButton;
