import { useTelemetryMetrics } from '@ats/graphql';
import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { useEffect, useMemo } from 'react';
import TelemetryCard from './TelemetryCard';
import Events from '../../../model/events';
import getTelemetryMetricsDisplayValue, {
  TelemetryUnit,
} from '../../../model/telemetry/getTelemetryMetricsDisplayValue';
import getTelemetryIcon from '../../../model/telemetry/getTelemetryIcon';
import NoDataAvailablePictogram from '../../pictograms/NoDataAvailable';
import { grey500 } from '../../../theme/color';
import { getConnectivity } from '../../../model/vehicle/useVehicles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: grey500,
    top: '0px',
    left: '0px',
    width: '100%',
    flexDirection: 'column',
  },
});

const triggerNoDataError = () => {
  const detail = {
    title: 'Failed to get telemetry data',
    message: 'No data available',
    type: 'error',
    testId: 'no-data-available-toast',
  };
  const event = new CustomEvent(Events.TOAST, { detail });
  window.dispatchEvent(event);
};

interface IProps {
  areaId: string | null;
  externalEquipmentReference: string;
}

const Telemetry = (props: IProps) => {
  const { areaId, externalEquipmentReference } = props;
  const { container } = useStyles();

  const [metrics, errorInTelemetry, timeoutError] = useTelemetryMetrics({ externalEquipmentReference, areaId });

  const isLoading = metrics.length === 0;

  const vehicleIsOnline = externalEquipmentReference
    ? getConnectivity(externalEquipmentReference)?.status.toLowerCase() === 'online'
    : false;
  const showError = timeoutError || errorInTelemetry !== null;

  const latestMetrics = useMemo(() => {
    return timeoutError ? [] : metrics;
  }, [metrics, timeoutError]);

  useEffect(() => {
    if (errorInTelemetry || timeoutError) {
      triggerNoDataError();
    }
  }, [errorInTelemetry, timeoutError]);

  if (!vehicleIsOnline || showError) {
    return (
      <NoDataAvailablePictogram
        width="70px"
        containerHeight="100vh"
        text="No data available"
        testId="no-data-available"
      />
    );
  }

  if (isLoading) {
    return (
      <Box className={container} data-testid="loading-telemetry-data">
        <CircularProgress size={25} />
      </Box>
    );
  }

  return (
    <Grid container spacing={8} data-testid="telemetry-grid" key={externalEquipmentReference}>
      {latestMetrics
        .filter(({ gauge }) => gauge && gauge.dataPoints && gauge.dataPoints[0])
        .map(({ name, gauge, unit }) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={`${name}-${externalEquipmentReference}`}>
            <TelemetryCard
              name={name}
              value={getTelemetryMetricsDisplayValue(
                gauge?.dataPoints[0].asInt,
                gauge?.dataPoints[0].asDouble,
                unit as TelemetryUnit,
              )}
              icon={getTelemetryIcon()}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default Telemetry;
