import React from 'react';
import { TableCell, TableRow, Theme, createStyles, makeStyles } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import prettyTime from './prettyTime';
import { getDegradationDescription } from '../../../model/vehicle/useVehicles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionParagraph: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  }),
);

interface IProps {
  timestamp: string;
  values: string[];
  type: string;
}

const LogTableRow = React.forwardRef<HTMLTableRowElement, IProps>((props, ref) => {
  const { timestamp, values, type } = props;
  const { descriptionParagraph } = useStyles();

  const paragraph = (label: string) => (
    <p key={`${label}-${uuid()}`} className={descriptionParagraph} data-testid="log-table-row-paragraph">
      {getDegradationDescription(label)}
    </p>
  );

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <TableRow ref={ref} data-testid="log-table-row" key={`${timestamp}-${uuid()}`}>
      <TableCell className="sdds-detail-02" component="th" scope="row">
        {prettyTime(timestamp)}
      </TableCell>
      <TableCell className="sdds-detail-02">{capitalizeFirstLetter(type)}</TableCell>
      <TableCell className="sdds-detail-02">{values.map(paragraph)}</TableCell>
    </TableRow>
  );
});

export default LogTableRow;
