import { useMemo, useEffect } from 'react';
import { BehaviorSubject, Subscription } from 'rxjs';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Text, Stroke, Fill, Style, Circle as CircleStyle } from 'ol/style';

import scaleModifier from './scaleModifier';
import Events from '../../model/events';
import { grey50, grey800 } from '../../theme/color';

const useTrackerLabelsLayer = () => {
  const source = useMemo(() => new VectorSource(), []);
  const layer = useMemo(
    () => new VectorLayer({ source, updateWhileAnimating: true, updateWhileInteracting: true, declutter: true }),
    [source],
  );

  useEffect(() => {
    const callback = () => {
      layer
        ?.getSource()
        ?.getFeatures()
        ?.forEach((feature) => {
          // Find the helper function that detects if this tracking features' host feature is inside a cluster and therefore hidden
          const isInsideCluster = feature.getProperties().getHostFeature().getProperties().getPoiIsInsideCluster;
          if (isInsideCluster === undefined) return;

          // Let each tracking feature that has the override flag retreive the value and set display for the feature
          // Also invert it - if the override is true, the visiblity should be false
          feature.getProperties().setHostIsInsideCluster(isInsideCluster());
        });
    };
    layer.on('prerender', callback);
    return () => layer.un('prerender', callback);
  }, [layer]);

  return layer;
};

function cloneGeometry(host: Feature<Geometry>, feature: Feature<Geometry>) {
  const hostGeometry = host.getGeometry();
  if (!hostGeometry) return;
  const geometry = feature.getGeometry();
  if (!geometry) return;
  if (geometry instanceof SimpleGeometry && hostGeometry instanceof SimpleGeometry) {
    const value = hostGeometry.getCoordinates();
    if (value === null) return;
    geometry.setCoordinates(value);
  }
}

function setStyle(feature: Feature<Geometry>, displayName: string, zIndex: number) {
  const styles = [
    new Style({
      image: new CircleStyle({
        radius: 3,
      }),
      text: new Text({
        text: displayName,
        textAlign: 'left',
        offsetX: 34,
        offsetY: 2,
        font: 'bold 16px "Scania Sans Semi Condensed"',
        fill: new Fill({
          color: grey50,
        }),
        stroke: new Stroke({
          color: grey800,
          width: 3,
        }),
      }),
    }),
  ];

  feature.setStyle((_, resolution: number) => {
    styles[0].getText().setOffsetX(scaleModifier(resolution) * 34);
    return styles;
  });

  styles[0].setZIndex(zIndex);
}

export function createTrackingFeature(
  zIndex: number,
  trackerLayer: VectorLayer<VectorSource<Geometry>>,
  hostFeature: Feature<Geometry>,
  settingObservable: BehaviorSubject<boolean>,
) {
  const feature = new Feature({ name: 'tracker' });

  let persistDisplayName = '';
  let hostIsInsideCluster = false;
  let displayLabelSetting = false;
  let settingSubscription: Subscription | null = null;

  // We make sure all data is loaded into the host feature before acting upon it
  const initializationInterval = setInterval(() => {
    const displayName = hostFeature.getProperties().getDisplayName();
    const geometry = hostFeature.getGeometry();
    if (displayName === 'Loading' || geometry === undefined) return;

    persistDisplayName = displayName;
    clearInterval(initializationInterval);
    cloneGeometry(hostFeature, feature);
    setStyle(feature, displayName, zIndex);

    trackerLayer.on('prerender', () => {
      cloneGeometry(hostFeature, feature);
    });

    settingSubscription = settingObservable.subscribe((value: boolean) => {
      feature.getProperties().setDisplayLabelSetting(value);
    });
  }, 100);

  // Unclear what this should be initialised to at startup
  feature.setGeometry(new Point([-1, -1]));

  const setDisplay = () => {
    if (!hostIsInsideCluster && displayLabelSetting) {
      setStyle(feature, persistDisplayName, zIndex);
    } else {
      feature.setStyle(
        new Style({
          image: new CircleStyle({
            radius: 3,
          }),
        }),
      );
    }
  };

  // Expects to be called before removing the feature from the layer upon destruction
  const destroy = () => {
    settingSubscription?.unsubscribe();
  };

  feature.setProperties({
    // Determine if this tracker features' host feature is inside a cluster
    setHostIsInsideCluster: (value: boolean) => {
      hostIsInsideCluster = value;
      setDisplay();
    },
    // The user setting to show labels
    setDisplayLabelSetting: (value: boolean) => {
      displayLabelSetting = value;
      setDisplay();
    },
    getHostFeature: () => {
      return hostFeature;
    },
  });

  window.addEventListener(
    Events.POI_REMOVED,
    (event: CustomEvent) => {
      const { id } = event.detail;
      if (id === undefined) {
        throw new Error('Missing required ID for POI tracker feature removal');
      }
      if (hostFeature.getProperties().id === undefined) return;
      if (id !== hostFeature.getProperties().id) return;

      destroy();
      trackerLayer?.getSource()?.removeFeature(feature);
    },
    { once: true },
  );

  window.addEventListener(
    Events.VEHICLE_REMOVED,
    (event: CustomEvent) => {
      const { id } = event.detail;
      if (id === undefined) {
        throw new Error('Missing required ID for vehicle tracker feature removal');
      }
      if (hostFeature.getProperties().externalEquipmentReference === undefined) return;
      if (id !== hostFeature.getProperties().externalEquipmentReference) return;

      destroy();
      trackerLayer?.getSource()?.removeFeature(feature);
    },
    { once: true },
  );

  return feature;
}

export default useTrackerLabelsLayer;
