import { useMergedPaddockData } from '@ats/graphql';
import { makeStyles } from '@material-ui/core';
import { memo } from 'react';
import { grey868, red } from '../../theme/color';
import PaddockStatus from './PaddockStatus';
import getVehicleStateLabel from '../../model/vehicle/state/getVehicleStateLabel';
import withEquipment from '../withEquipment';
import type { IWithEquipmentProps } from '../withEquipment';
import { isEqualOmitEquipmentTimestamps } from '../../model/isEqual';
import VehicleStatusButton from '../left/button/VehicleStatusButton';

interface IProps extends IWithEquipmentProps {
  paddockId: string;
  selectedEquipmentState: [string | null, (s: string | null) => void];
}

export const useStyles = makeStyles(
  {
    container: {
      marginLeft: '24px',
      borderRadius: '4px',
      padding: '0px',
      display: 'flex',
      flexDirection: 'column',
    },
    cardTopRedBorder: {
      borderTop: `4px solid ${red}`,
      borderRadius: '4px',
    },
    cardBorderRadius: {
      borderRadius: '4px',
    },
    cardBorderTopRadius: {
      borderRadius: '4px 4px 0 0',
    },
    paddockVehicleButton: {
      width: '320px',
      backgroundColor: grey868,
      '&:last-child': {
        borderRadius: '0 0 4px 4px',
      },
    },
  },
  { index: 1 },
);

const PaddockStatusContainer = (props: IProps) => {
  const { paddockId, areaId, selectedEquipmentState, equipmentStatuses } = props;
  const { container, cardTopRedBorder, paddockVehicleButton, cardBorderRadius, cardBorderTopRadius } = useStyles();
  const [paddockArray] = useMergedPaddockData({ areaId, paddockId });
  const [selectedEquipmentId, setSelectedEquipmentId] = selectedEquipmentState;

  if (!paddockArray) return null;
  const paddock = paddockArray[0];
  const isFull = paddock && paddock.filledSpots + paddock.failedSpots === paddock.totalSpots ? 'FULL' : null;
  const status = isFull ? 'FULL' : `${paddock?.filledSpots + paddock?.failedSpots}/${paddock?.totalSpots}`;

  return (
    <>
      <div className={container}>
        {isFull && <PaddockStatus status={status} className={cardTopRedBorder} />}
        {!isFull && paddock.equipment.length === 0 && <PaddockStatus status={status} className={cardBorderRadius} />}
        {!isFull && paddock.equipment.length !== 0 && <PaddockStatus status={status} className={cardBorderTopRadius} />}
        {paddock.equipment.map(({ displayName, externalEquipmentReference }) => {
          const equipment = equipmentStatuses?.find(
            ({ externalEquipmentReference: id }) => externalEquipmentReference === id,
          );
          if (!equipment) {
            throw new Error('Unable to get information about vehicle');
          }
          const { operationalState, mode, status } = equipment;

          return (
            <VehicleStatusButton
              alertPulse={false}
              icon={null}
              active={false}
              hover={false}
              key={externalEquipmentReference}
              className={paddockVehicleButton}
              onClick={() => {
                if (selectedEquipmentId && externalEquipmentReference === selectedEquipmentId) {
                  setSelectedEquipmentId(null);
                } else {
                  setSelectedEquipmentId(externalEquipmentReference);
                }
              }}
              mode={mode}
              operationalState={operationalState}
              status={status}
              name={displayName}
              statusLabel={<>{getVehicleStateLabel(operationalState, mode, status, false)}</>}
            />
          );
        })}
      </div>
    </>
  );
};

export default withEquipment(memo(PaddockStatusContainer, isEqualOmitEquipmentTimestamps));
