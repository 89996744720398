import { useObservable } from '@libreact/use-observable';
import useVehicles from './useVehicles';
import { selectedAreaId as selectedAreaIdObservable, user as userObservable } from '../observables';
import isInStandDownPersonalByUser from './stand-down/isInStandDownPersonalByUser';

export const useStopAllVehicles = () => {
  const [areaId] = useObservable(selectedAreaIdObservable);
  const [vehicles] = useVehicles({ areaId });
  const [user] = useObservable(userObservable);

  if (user === null || vehicles === null || vehicles.length === 0) {
    return {
      canStopAllVehicles: true,
      allVehiclesOffline: true,
    };
  }

  const onlineVehicles = vehicles.filter(({ status }) => status.toLowerCase() === 'online');

  const onlineEquipmentNotLockedByCurrentUser = onlineVehicles.filter(
    (equipmentStatus) => !isInStandDownPersonalByUser(equipmentStatus, user),
  );

  const allVehiclesOffline = onlineVehicles.length === 0;

  return {
    canStopAllVehicles: allVehiclesOffline || onlineEquipmentNotLockedByCurrentUser.length > 0,
    allVehiclesOffline,
  };
};

export default useStopAllVehicles;
