import { useMemo, useState, useRef, useEffect } from 'react';
import useVehicleDegradationAlert from '../../model/degradation/useVehicleDegradationAlert';
import IVehicle from '../../model/vehicle/IVehicle';
import { keepAttention } from '../../model/duration';
import { Speaker } from '../icons';
import VehicleStatusButton from './button/VehicleStatusButton';
import getVehicleStateLabel from '../../model/vehicle/state/getVehicleStateLabel';

interface IProps {
  vehicle: IVehicle;
  selectedAreaId: string | null;
  selectedEquipmentId: string | null;
  hoverEquipmentId: string | null;
  setSnap: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedEquipmentId: (s: string | null) => void;
  setHoverEquipmentId: React.Dispatch<React.SetStateAction<string | null>>;
  alertsMuted: boolean;
}

const VehicleStatusButtonContainer = ({
  vehicle,
  selectedAreaId,
  selectedEquipmentId,
  hoverEquipmentId,
  setSnap,
  setSelectedEquipmentId,
  setHoverEquipmentId,
  alertsMuted,
}: IProps) => {
  const { externalEquipmentReference, displayName, operationalState, mode, status, position } = vehicle;

  const [alert, setAlert] = useState(false);
  const sound = useMemo(() => new Audio('/static/sound/notification-decorative-01.wav'), []);
  const timerRef = useRef<number>();

  useVehicleDegradationAlert({
    areaId: selectedAreaId,
    externalEquipmentReference,
    operationalState,
    mode,
    alertHandler: () => {
      setAlert(true);
      // If this vehicle is already selected, mute the alert automatically after 9 seconds (before sound play again)
      if (selectedEquipmentId === externalEquipmentReference) {
        setTimeout(() => {
          setAlert(false);
        }, 9000);
      }
    },
    alertResetHandler: () => {
      setAlert(false);
    },
  });

  useEffect(() => {
    if (alert && timerRef.current === null && !alertsMuted) {
      sound.currentTime = 0;
      try {
        sound.play();
      } catch {
        // Intentionally silently swallow this error
        // CAV-38291
        /* DOMException: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD */
      }

      // Repeat the sound every 10:th second an additional 5 times (total 6)
      let repeats = 0;
      // allow setting current value
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      timerRef.current = setInterval(() => {
        sound.currentTime = 0;
        try {
          sound.play();
        } catch {
          // See above
        }
        repeats += 1;
        if (repeats === 5) {
          const intervalId = timerRef.current;
          clearInterval(intervalId);
          // allow setting current value
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          timerRef.current = null;
        }
      }, keepAttention);
    } else {
      try {
        sound.pause();
      } catch {
        // See above
      }
      clearInterval(timerRef.current);
      timerRef.current = undefined;
    }

    return function cleanup() {
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
        timerRef.current = undefined;
        try {
          sound.pause(); // Stop sound if changing area (which we cause this component instance to be removed)
        } catch {
          // See above
        }
      }
    };
  }, [alert, sound, timerRef, alertsMuted]);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAlert(false);
  };

  const icon = alert ? (
    <Speaker
      muted={alertsMuted}
      onClick={handleClick}
      testid={`degradation-alert-speaker-${externalEquipmentReference}`}
    />
  ) : null;

  return (
    <VehicleStatusButton
      alertPulse={alert}
      icon={icon}
      active={externalEquipmentReference === selectedEquipmentId}
      hover={externalEquipmentReference === hoverEquipmentId}
      key={externalEquipmentReference}
      onClick={() => {
        if (selectedEquipmentId && externalEquipmentReference === selectedEquipmentId) {
          setSelectedEquipmentId(null);
        } else {
          setSnap(position !== null);
          setSelectedEquipmentId(externalEquipmentReference);
          setAlert(false);
        }
      }}
      onMouseEnter={() => setHoverEquipmentId(externalEquipmentReference)}
      onMouseLeave={() => setHoverEquipmentId(null)}
      operationalState={operationalState}
      mode={mode}
      status={status}
      name={displayName}
      statusLabel={<>{getVehicleStateLabel(operationalState, mode, status)}</>}
    />
  );
};

export default VehicleStatusButtonContainer;
