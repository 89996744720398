import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import clsx from 'clsx';
import { Degradations as IDegradations } from '@ats/graphql';
import { memo } from 'react';
import { t } from 'ttag';
import getReleaseConditionLabel from '../../model/degradation/getReleaseConditionLabel';
import { grey868, disabledText, grey800, grey846, dimWhite, semiDimWhite } from '../../theme/color';
import theme from '../../theme/theme';
import { ReleaseVehicle } from '../icons/ReleaseVehicle';
import { DisabledVehicle } from '../icons/DisabledVehicle';
import getDegradationStateColor from '../../model/degradation/getDegradationStateColor';
import getDegradationActionLabel from '../../model/degradation/getDegradationActionLabel';
import { IDegradationPlanElementDataWithName } from '../../model/degradation/getDegradationPlans';

interface IProps {
  allDegradations: ReadonlyArray<IDegradations> | null;
  degradation: IDegradationPlanElementDataWithName;
  nested?: boolean;
}

interface IStyleProps {
  nested?: boolean;
  activeFaults?: boolean;
}

export const useStyles = makeStyles(
  {
    card: {
      boxShadow: 'none',
      marginTop: theme.spacing(4),
      borderRadius: '4px',
      borderTop: `4px solid`,
      backgroundColor: (props: IStyleProps) => (props.nested ? grey846 : grey868),
      marginLeft: (props: IStyleProps) => theme.spacing(props.nested ? 2 : 0),
      marginRight: (props: IStyleProps) => theme.spacing(props.nested ? 2 : 0),
    },
    cardHeader: {
      padding: theme.spacing(3, 4, 2, 4),
    },
    cardTitle: {
      color: semiDimWhite,
    },
    content: {
      padding: theme.spacing(0),
      '&:last-child': {
        paddingBottom: theme.spacing(4),
      },
    },
    divider: {
      height: '36px',
      width: '1px',
      backgroundColor: grey800,
      margin: theme.spacing(0, 4, 0, 0),
    },
    typography: {
      color: dimWhite,
      padding: theme.spacing(0, 4, 0),
    },
    cardActions: {
      display: 'flex',
      padding: theme.spacing(4, 4.5),
    },
    release: {
      padding: theme.spacing(0),
      height: '16px',
      display: 'flex',
      alignItems: 'center',
    },
    cardAction: {
      width: '50%',
    },
    typographyRelease: {
      color: (props: IStyleProps) => (props.activeFaults ? disabledText : semiDimWhite),
    },
    typographyReleaseTitle: {
      color: dimWhite,
      marginBottom: theme.spacing(3),
    },
    air: {
      marginTop: '8px',
      marginBottom: '3px',
    },
    indent: {
      marginLeft: '8px',
    },
  },
  { index: 1 },
);

const Degradation = (props: IProps) => {
  const { degradation, nested, allDegradations } = props;
  const {
    name,
    releaseCondition,
    description,
    action,
    operatorActionSequenceWithActiveFault,
    operatorActionSequenceWithPassiveFault,
  } = degradation;
  const activeFaults = allDegradations?.[0].faults?.includes(name);
  const {
    card,
    cardHeader,
    cardTitle,
    cardActions,
    cardAction,
    content,
    divider,
    typography,
    release,
    typographyRelease,
    typographyReleaseTitle,
    air,
    indent,
  } = useStyles({ nested, activeFaults });
  const { foreground } = getDegradationStateColor(action);
  const degradationActionlabel = getDegradationActionLabel(action);
  const releaseConditionLabel = getReleaseConditionLabel(releaseCondition);
  const isInVehicle = releaseConditionLabel === 'In-vehicle';

  const actions = activeFaults ? operatorActionSequenceWithActiveFault : operatorActionSequenceWithPassiveFault;

  return (
    <Card style={{ borderTopColor: foreground }} className={card}>
      <CardHeader
        className={cardHeader}
        title={degradationActionlabel}
        titleTypographyProps={{ className: clsx(cardTitle, 'sdds-headline-06') }}
      />
      <CardContent className={content}>
        <Typography className={clsx(typography, air, 'sdds-detail-05')}>Description</Typography>
        <Typography className={clsx(typography, 'sdds-detail-03')}>{description}</Typography>
        {actions.length > 0 ? (
          <Box>
            <Typography className={clsx(typography, air, 'sdds-detail-05')}>Actions</Typography>
            {actions.map((action, idx) => (
              <Typography key={action} className={clsx(typography, indent, 'sdds-detail-03')}>
                {`${idx + 1}. ${action}`}
              </Typography>
            ))}
          </Box>
        ) : null}
      </CardContent>
      <CardActions className={cardActions}>
        <Box className={cardAction}>
          <Typography className={clsx(typographyReleaseTitle, 'sdds-detail-07')}>{t`Release blockers`}</Typography>
          <Typography className={clsx(typographyRelease, 'sdds-detail-02')}>{activeFaults ? t`Yes` : t`No`}</Typography>
        </Box>
        <Divider className={divider} />
        <Box className={cardAction}>
          <Typography className={clsx(typographyReleaseTitle, 'sdds-detail-07')}>
            {activeFaults ? t`Release (Blocked)` : t`Release`}
          </Typography>

          <Box className={release}>
            {isInVehicle && activeFaults && <DisabledVehicle />}
            {isInVehicle && !activeFaults && <ReleaseVehicle />}
            <Typography
              className={clsx(typographyRelease, 'sdds-detail-02')}
              data-testid={`degradation-${action}-release-condition`}
            >
              {releaseConditionLabel}
            </Typography>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};

export default memo(Degradation);
