import { useEffect, useState, memo } from 'react';
import { Equipment, getStaffFg, Staff, sendStandDownPersonal, sendReleasePersonal } from '@ats/graphql';
import { SvgIcon, Tooltip, makeStyles, Button } from '@material-ui/core';
import Events from '../../model/events';
import { ICommonIconProps, useIconStyles } from './CommonIconProps';
import useControlIconStyles from './useControlIconStyles';
import { grey500, red } from '../../theme/color';

interface IProps extends ICommonIconProps {
  standDownUserList: Array<string | null> | null;
  vehicle: Equipment;
  userExternalStaffReference: string | null;
  online: boolean;
}

const useStyles = makeStyles(
  {
    toolTip: {
      position: 'absolute',
      left: '115px',
      top: '310px',
    },
  },
  { index: 1 },
);

export const PersonalStanddownButton = ({
  className,
  standDownUserList,
  testid,
  vehicle,
  userExternalStaffReference,
  online,
}: IProps) => {
  const iconStyles = useIconStyles();
  const { toolTip } = useStyles();
  const { button } = useControlIconStyles();
  const [standdownByNames, setStanddownByNames] = useState<string[]>([]);
  const [isPersonalStanddownByMe, setIsPersonalStanddownByMe] = useState(false);

  // This is used to keep react from running the useEffect when the array is the same (but a different reference)
  const derivedStandDownUserList = standDownUserList ? standDownUserList.join('-') : '';

  useEffect(() => {
    setStanddownByNames([]);
    if (!standDownUserList) return;
    setIsPersonalStanddownByMe(false);
    standDownUserList.forEach((externalId: string | null) => {
      if (!externalId) return;
      if (userExternalStaffReference === externalId) {
        setIsPersonalStanddownByMe(true);
      }
      getStaffFg(externalId)
        .then((staff: Staff | null) => {
          setStanddownByNames((standdownByNames) => [
            ...standdownByNames,
            staff ? `${staff.givenName} ${staff.familyName}` : 'Unknown user',
          ]);
        })
        .catch(() => {
          setStanddownByNames((standdownByNames) => [...standdownByNames, 'Unknown user']);
          const detail = { title: `Could not resolve the staff name for ExternalId ${externalId}` };
          const event = new CustomEvent(Events.TOAST, { detail });
          window.dispatchEvent(event);
        });
    });
    // Only re-render if the standDownUserList array change, but use derivedStandDownUserList instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedStandDownUserList, userExternalStaffReference]);

  const sendPersonalStanddown = () =>
    sendStandDownPersonal(vehicle.externalEquipmentReference).catch(() => {
      const detail = { title: 'Failed to activate personal stand down' };
      const event = new CustomEvent(Events.TOAST, { detail });
      window.dispatchEvent(event);
    });

  const releasePersonalStanddown = () =>
    sendReleasePersonal(vehicle.externalEquipmentReference).catch(() => {
      const detail = { title: 'Failed to release personal stand down' };
      const event = new CustomEvent(Events.TOAST, { detail });
      window.dispatchEvent(event);
    });

  const sendPersonalStanddownEvent = () => {
    const detail = {
      title: 'Personal stand down',
      description: 'Are you sure you want to activate a personal stand down on the vehicle?',
      cancelLabel: 'Cancel',
      executeLabel: 'Yes, activate',
      onLoadingLabel: 'Activating',
      executeCallback: sendPersonalStanddown,
    };

    const event = new CustomEvent(Events.MODAL, { detail });
    window.dispatchEvent(event);
  };

  const sendReleasePersonalStanddownEvent = () => {
    const detail = {
      title: 'Personal stand down',
      description: 'Are you sure you want to remove your personal stand down from the vehicle?',
      cancelLabel: 'Cancel',
      executeLabel: 'Yes, remove',
      onLoadingLabel: 'Removing',
      executeCallback: releasePersonalStanddown,
    };

    const event = new CustomEvent(Events.MODAL, { detail });
    window.dispatchEvent(event);
  };

  return standDownUserList && standDownUserList.length > 0 && online ? (
    <span className={toolTip}>
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={
          <span className={iconStyles.tooltipTypography} data-testid={`${testid}-stand-down-by`}>
            Personal stand down by: <br />
            {standdownByNames.join('\n')}
          </span>
        }
      >
        <span>
          <Button
            className={button}
            onClick={isPersonalStanddownByMe ? sendReleasePersonalStanddownEvent : sendPersonalStanddownEvent}
          >
            <SvgIcon
              width="16"
              height="16"
              viewBox="0 0 16 16"
              transform="scale(0.8)"
              className={className}
              data-testid={testid}
            >
              <path
                d="M10.321 13.6004H5.68177L2.40137 10.32V5.68079L5.68177 2.40039H10.321L13.6014 5.68079L13.6014 10.32L10.321 13.6004Z"
                fill={red}
              />
              <path
                d="M5.7923 2.66712L2.6681 5.79132V10.2096L5.7923 13.3338H10.2106L13.3348 10.2096L13.3348 5.79132L10.2106 2.66712H5.7923ZM5.57138 2.13379H10.4315L13.8681 5.5704L13.8681 10.4305L10.4315 13.8671H5.57138L2.13477 10.4305V5.5704L5.57138 2.13379Z"
                fill={red}
              />
              <path
                d="M5.12812 1.06667L1.06667 5.12812V10.8719L5.12812 14.9333H10.8719L14.9333 10.8719V5.12812L10.8719 1.06667H5.12812ZM4.90721 0H11.0928C11.2342 0 11.3699 0.056192 11.4699 0.156213L15.8438 4.53008C15.9438 4.6301 16 4.76575 16 4.90721V11.0928C16 11.2342 15.9438 11.3699 15.8438 11.4699L11.4699 15.8438C11.3699 15.9438 11.2342 16 11.0928 16H4.90721C4.76575 16 4.6301 15.9438 4.53008 15.8438L0.156208 11.4699C0.056192 11.3699 0 11.2342 0 11.0928V4.90721C0 4.76575 0.056192 4.6301 0.156213 4.53008L4.53009 0.156208C4.6301 0.056192 4.76576 0 4.90721 0Z"
                fill={red}
              />
            </SvgIcon>
          </Button>
        </span>
      </Tooltip>
    </span>
  ) : (
    <span className={toolTip}>
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={<span className={iconStyles.tooltipTypography}>Personal stand down</span>}
      >
        <span>
          <Button className={button} disabled={!online} onClick={sendPersonalStanddownEvent}>
            <SvgIcon
              width="16"
              height="16"
              viewBox="0 0 16 16"
              transform="scale(0.8)"
              className={className}
              data-testid={testid}
            >
              <path
                d="M10.321 13.6004H5.68177L2.40137 10.32V5.68079L5.68177 2.40039H10.321L13.6014 5.68079L13.6014 10.32L10.321 13.6004Z"
                fill={grey500}
              />
              <path
                d="M5.7923 2.66712L2.6681 5.79132V10.2096L5.7923 13.3338H10.2106L13.3348 10.2096L13.3348 5.79132L10.2106 2.66712H5.7923ZM5.57138 2.13379H10.4315L13.8681 5.5704L13.8681 10.4305L10.4315 13.8671H5.57138L2.13477 10.4305V5.5704L5.57138 2.13379Z"
                fill={grey500}
              />
              <path
                d="M5.12812 1.06667L1.06667 5.12812V10.8719L5.12812 14.9333H10.8719L14.9333 10.8719V5.12812L10.8719 1.06667H5.12812ZM4.90721 0H11.0928C11.2342 0 11.3699 0.056192 11.4699 0.156213L15.8438 4.53008C15.9438 4.6301 16 4.76575 16 4.90721V11.0928C16 11.2342 15.9438 11.3699 15.8438 11.4699L11.4699 15.8438C11.3699 15.9438 11.2342 16 11.0928 16H4.90721C4.76575 16 4.6301 15.9438 4.53008 15.8438L0.156208 11.4699C0.056192 11.3699 0 11.2342 0 11.0928V4.90721C0 4.76575 0.056192 4.6301 0.156213 4.53008L4.53009 0.156208C4.6301 0.056192 4.76576 0 4.90721 0Z"
                fill={grey500}
              />
            </SvgIcon>
          </Button>
        </span>
      </Tooltip>
    </span>
  );
};
export default memo(PersonalStanddownButton);
