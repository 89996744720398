import { makeStyles } from '@material-ui/core';
import createTheme from '@material-ui/core/styles/createTheme';
import {
  semiDimWhite,
  dimWhite,
  blue,
  blue400,
  blue500,
  blue600,
  blue700,
  green,
  green600,
  green800,
  grey50,
  grey100,
  grey868,
  grey500,
  grey800,
  grey900,
  grey958,
  white,
  ultraDimWhite,
  disabledText,
} from './color';

const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: grey100,
    },
    action: {
      active: white,
      hoverOpacity: 0.04,
      disabled: grey500,
    },
    background: {
      default: grey900,
      paper: grey900,
    },
    text: {
      primary: white,
      secondary: grey100,
      disabled: grey500,
    },
  },
  typography: {
    body1: {
      color: white,
      fontSize: '4rem',
    },
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiTypography: {
      h4: {
        marginTop: '0px',
        color: white,
      },
      h5: {
        marginTop: '0px',
        color: white,
        opacity: '0.87',
      },
      h6: {
        fontSize: '4rem',
      },
    },
    MuiTableContainer: {
      root: {
        height: '100vh',
        overflowX: 'clip',
        borderRadius: '4px',
        backgroundColor: grey958,
        paddingRight: '4px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          color: 'transparent',
          width: '4px',
        },
        '&:hover': {
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: grey900,
          },
        },
      },
    },
    MuiTable: {
      root: {
        fontSize: '4rem',
        color: grey50,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: grey800,
      },
    },
    MuiTableBody: {
      root: {
        backgroundColor: grey900,
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 'unset',
        verticalAlign: 'top',
        color: grey50,
        borderBottom: `1px solid rgb(186, 205, 232, 0.38)`, // blue100 0.38%
      },
      head: {
        color: grey50,
      },
      body: {
        color: grey50,
      },
      stickyHeader: {
        backgroundColor: grey800,
      },
    },
    MuiList: {
      padding: {
        padding: '16px',
        paddingTop: '16px',
      },
    },
    MuiTab: {
      root: {
        fontSize: '14px',
      },
      textColorInherit: {
        color: white,
        opacity: 0.4,
      },
    },
    MuiTabs: {
      fixed: {
        width: 'calc(100vh - 74px)',
      },
      flexContainer: {
        borderBottom: `1px solid ${grey800}`,
      },
      indicator: {
        backgroundColor: `${grey100}`,
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '5.5rem',
      },
    },
    MuiButton: {
      root: {
        minWidth: 0,
        borderRadius: '4px',
        '&:hover': {
          border: `1px solid`,
          borderColor: 'rgba(255, 255, 255, 0.6)',
          backgroundColor: grey900,
        },
        '&:focus': {
          border: `1px solid ${blue}`,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: grey50,
        color: grey900,
        fontFamily: '"Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif',
        fontSize: '12px',
        lineHeight: '16px',
        padding: '8px',
        borderRadius: '4px',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: 'none',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: grey868,
        margin: '16px 24px 23px 24px',
      },
    },
    MuiGrid: {
      'spacing-xs-4': {
        margin: 0,
        width: 'auto',
      },
    },
    MuiListItemText: {
      root: {
        paddingRight: '16px',
        height: '100%',
        overflow: 'hidden',
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
});

export const useCommonStyles = makeStyles(
  {
    pill: {
      borderRadius: '99999px !important',
      padding: '8px 16px !important',
      height: 'fit-content',
    },
    primaryButton: {
      backgroundColor: blue600,
      color: white,
      borderRadius: '4px',
      paddingBottom: '4px',
      paddingTop: '4px',
      textTransform: 'none',
      border: 'none',
      '&:hover': {
        backgroundColor: blue500,
        border: 'none',
      },
      '&:focus': {
        backgroundColor: blue400,
        border: 'none',
      },
      '&:active': {
        backgroundColor: blue700,
        border: 'none',
      },
      '&:disabled': {
        color: disabledText,
        backgroundColor: grey868,
      },
    },
    secondaryButton: {
      border: `1px solid ${ultraDimWhite}`,
      borderRadius: '4px',
      color: white,
      paddingBottom: '4px',
      paddingTop: '4px',
      textTransform: 'none',
      '&:hover': {
        border: `1px solid ${dimWhite}`,
      },
      '&:focus': {
        border: `1px solid ${blue}`,
      },
      '&:active': {
        border: `1px solid ${semiDimWhite}`,
      },
    },
    secondaryButtonSelected: {
      border: `1px solid ${blue}`,
    },
    dispatchButton: {
      backgroundColor: `${green} !important`,
      color: white,
      '&:hover': {
        backgroundColor: `${green600} !important`,
        border: `none !important`,
      },
      '&:focus': {
        backgroundColor: `${green800} !important`,
        border: `none !important`,
      },
      '&:active': {
        backgroundColor: `${green800} !important`,
        border: `none !important`,
      },
      '&:disabled': {
        color: disabledText,
        backgroundColor: `${grey868} !important`,
        border: `none !important`,
      },
      '&:Mui-disabled': {
        color: disabledText,
        backgroundColor: `${grey868} !important`,
        border: `none !important`,
      },
    },
    ghostButton: {
      border: '1px solid rgba(0, 0, 0, 0) !important',
      borderRadius: '4px',
      color: white,
      paddingBottom: '4px',
      paddingTop: '4px',
      textTransform: 'none',
      '&:hover': {
        border: `1px solid ${dimWhite} !important`,
      },
      '&:focus': {
        border: `1px solid ${blue} !important`,
      },
      '&:active': {
        border: `1px solid ${semiDimWhite} !important`,
      },
    },
    cardifyButton: {
      padding: '20px',
    },
  },
  { index: 1 },
);

export default theme;
