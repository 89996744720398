/**
 * Enums are based on Onboard definition, see
 * https://gitlab.collaborationlayer-traton.com/ats-project/ats-project-group/scania-ats/ats-appl-self-tele-mdef/-/blob/master/inf/selfTelemetry.idl
 *
 * {
    Undefined,              // Default
    None,                   // Unitless
    Meters,                 // Distance
    MetersPerSecond,        // Velocity
    MetersPerSecondSquared, // Acceleration
    MetersPerSecondCubed,   // Jerk
    Degrees,                // Angle
    Radians,                // Angle
    RadiansPerSecond,       // Angular speed
    Percent,                // Ratio
    Kilograms,              // Mass
    Joules,                 // Energy
    Hertz,                  // Frequency
    Newton,                 // Force
    NewtonMeter,            // Torque
    Ampere,                 // Current
    Volt,                   // Voltage
    Celsius,                // Temperature
    Kilopascal,             // Pressure
    Seconds,                // Time
    // Continue with more as needed
    NumberOfUnitsDefined    // Extra enum to make it easier to work with
};

 */
export enum TelemetryUnit {
  TIME_CONNECTIVITY = 'Seconds', // connectivity
  DEFAULT = '0',
  UNITLESS = '1',
  DISTANCE = '2',
  VELOCITY = '3',
  ACCELERATION = '4',
  JERK = '5',
  ANGLE_DEGREES = '6',
  ANGLE_RADIANS = '7',
  ANGULAR_SPEED = '8',
  RATIO = '9',
  MASS = '10',
  ENERGY = '11',
  FREQUENCY = '12',
  FORCE = '13',
  TORQUE = '14',
  CURRENT = '15',
  VOLTAGE = '16',
  TEMPERATURE = '17',
  PRESSURE = '18',
  TIME = '19',
}

const getDisplayValue = (value: number | null | undefined, unit: TelemetryUnit): string => {
  if (value === null || value === undefined) {
    return '-';
  }
  // convert kg to tonnes, meters to kilometers
  if ([TelemetryUnit.DISTANCE, TelemetryUnit.MASS].includes(unit)) {
    return parseFloat((value / 1000).toFixed(1)).toString();
  }

  // convert m/s to km/h
  if ([TelemetryUnit.VELOCITY].includes(unit)) {
    return parseFloat((value * 3.6).toFixed(1)).toString();
  }

  return parseFloat(value.toFixed(1)).toString();
};

const getTelemetryMetricsDisplayValue = (
  asInt: number | undefined,
  asDouble: number | null | undefined,
  unit: TelemetryUnit,
): string => {
  const value = (asInt !== undefined && Math.abs(asInt) > 1) || !asDouble ? asInt : asDouble;

  const displayUnit =
    {
      [TelemetryUnit.TIME_CONNECTIVITY]: 's',
      [TelemetryUnit.DEFAULT]: '',
      [TelemetryUnit.UNITLESS]: '',
      [TelemetryUnit.DISTANCE]: 'km', // conversion from m
      [TelemetryUnit.VELOCITY]: 'km/h', // conversion from m/s
      [TelemetryUnit.ACCELERATION]: 'm/s2',
      [TelemetryUnit.JERK]: 'm/s3',
      [TelemetryUnit.ANGLE_DEGREES]: '°',
      [TelemetryUnit.ANGLE_RADIANS]: 'rad',
      [TelemetryUnit.ANGULAR_SPEED]: 'ω',
      [TelemetryUnit.RATIO]: '%',
      [TelemetryUnit.MASS]: getDisplayValue(value, TelemetryUnit.MASS) === '1' ? 'ton' : 'tonnes', // conversion from kg
      [TelemetryUnit.ENERGY]: 'J',
      [TelemetryUnit.FREQUENCY]: 'Hz',
      [TelemetryUnit.FORCE]: 'N',
      [TelemetryUnit.TORQUE]: 'Nm',
      [TelemetryUnit.CURRENT]: 'A',
      [TelemetryUnit.VOLTAGE]: 'V',
      [TelemetryUnit.TEMPERATURE]: '°C',
      [TelemetryUnit.PRESSURE]: 'kPa',
      [TelemetryUnit.TIME]: 's',
    }[unit] ?? '';

  return `${getDisplayValue(value, unit)} ${displayUnit}`.trim();
};

export default getTelemetryMetricsDisplayValue;
