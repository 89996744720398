import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { memo } from 'react';
import { Circle, Line } from '../icons';
import formatTimeLabel from '../../model/format/formatTimeLabel';
import IMissionOverview from '../../model/mission/overview/IMissionOverview';
import type IVehicleStateColor from '../../model/vehicle/state/IVehicleStateColor';
import { grey100, grey500, white } from '../../theme/color';

export const useStyles = makeStyles(
  {
    actionGrid: {
      padding: '0px 24px 24px 24px',
      display: 'grid',
      gridTemplateColumns: '40px 16px auto',
      gridAutoRows: '48px',
      columnGap: '16px',
      color: grey100,
    },
    iconColumn: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    circle: {
      fill: grey100,
      width: '16px',
      height: '16px',
    },
    line: {
      height: '16px',
      marginBottom: '8px',
      marginTop: '8px',
    },
    lineMore: {
      height: '32px',
    },
    previousIcon: {
      fill: grey500,
    },
    previousColor: {
      color: grey500,
    },
    nextIcon: {
      fill: grey100,
    },
    nextColor: {
      color: grey100,
    },
  },
  { index: 1 },
);

function Previous(props: Pick<IMissionOverview, 'previous' | 'current'>): JSX.Element | null {
  const { previous, current } = props;
  const { previousColor, iconColumn, previousIcon, circle, line } = useStyles();

  if (!previous) return null;

  const { startTime, label } = previous;

  return (
    <>
      <div className={previousColor}>{formatTimeLabel(startTime)}</div>
      <div className={clsx(previousColor, iconColumn)}>
        <Circle className={clsx(circle, previousIcon)} />
        {current && <Line color={grey500} className={line} />}
      </div>
      <div className={clsx(previousColor, 'sdds-headline-07')} data-testid="active-mission-list-previous">
        {label}
      </div>
    </>
  );
}

function Current(
  props: Pick<IMissionOverview, 'current' | 'next'> & { vehicleColor: IVehicleStateColor },
): JSX.Element | null {
  const {
    current,
    next,
    vehicleColor: { foreground },
  } = props;
  const { circle, iconColumn, line } = useStyles();

  if (!current) return null;

  const { startTime, label } = current;

  return (
    <>
      <div className="sdds-headline-07" style={{ color: foreground }}>
        {formatTimeLabel(startTime)}
      </div>
      <div className={iconColumn}>
        <Circle className={circle} style={{ fill: foreground }} />
        {next && <Line color={white} className={line} />}
      </div>
      <div className="sdds-headline-07" style={{ color: foreground }} data-testid="active-mission-list-current">
        {label}
      </div>
    </>
  );
}

function Next(props: Pick<IMissionOverview, 'next' | 'more'>): JSX.Element | null {
  const { next, more } = props;
  const { iconColumn, nextIcon, nextColor, circle, line, lineMore } = useStyles();

  if (!next) return null;

  return (
    <>
      <div className={nextColor} />
      <div className={iconColumn}>
        <Circle className={clsx(circle, nextIcon)} />
        {more && <Line dashed color={white} className={clsx(line, lineMore)} />}
      </div>
      <div className={nextColor}>
        <div className="sdds-detail-05">Next action</div>
        <div className="sdds-detail-02" data-testid="active-mission-list-next">
          {next.label}
        </div>
      </div>
    </>
  );
}

interface IProps {
  missionOverview: IMissionOverview;
  vehicleColor: IVehicleStateColor;
}

const ActiveMissionActionList = ({ missionOverview, vehicleColor }: IProps) => {
  const { previous, current, next, more } = missionOverview;
  const { actionGrid } = useStyles();

  return (
    <div className={actionGrid} data-testid="active-mission-list">
      <Previous previous={previous} current={current} />
      <Current current={current} next={next} vehicleColor={vehicleColor} />
      <Next next={next} more={more} />
    </div>
  );
};

export default memo(ActiveMissionActionList);
