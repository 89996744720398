import { Button, Menu, MenuItem, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { t } from 'ttag';
import { sendStopAll, sendReleaseAll } from '@ats/graphql';
import { useObservable } from '@libreact/use-observable';
import Events from '../model/events';
import theme from '../theme/theme';
import { selectedAreaId as selectedAreaIdObservable } from '../model/observables';
import useStopAllVehicles from '../model/vehicle/useStopAllVehicles';
import SiteMenuIcon from './icons/SiteMenuIcon';
import { grey50, grey800, grey958, grey846 } from '../theme/color';

const useStyles = makeStyles(
  {
    root: {
      alignItems: 'center',
      display: 'inline-block',
    },

    menuItem: {
      background: grey958,
      '&:hover': {
        backgroundColor: grey800,
      },
      color: grey50,
      height: '36px',
      padding: theme.spacing(0, 6, 0, 4),
    },
    toolTip: {
      left: '53px',
      top: '310px',
    },
    list: {
      borderRadius: '4px',
      borderTop: `6px solid ${grey958}`,
      borderBottom: `6px solid ${grey958}`,
      width: '160px',
      padding: 0,
    },
    SiteMenu: {
      width: '28px',
      height: '28px',
      '&:hover': {
        backgroundColor: grey846,
        border: 'none',
      },
      '&:focus': {
        border: 'none',
      },
    },
    SiteMenuActiveStyle: {
      backgroundColor: grey846,
      border: 'none',
    },
  },
  { index: 1 },
);

const SiteMenu = () => {
  const { root, menuItem, toolTip, list, SiteMenu, SiteMenuActiveStyle } = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [areaId] = useObservable(selectedAreaIdObservable);
  const { canStopAllVehicles, allVehiclesOffline } = useStopAllVehicles();
  const activeClass = open ? SiteMenuActiveStyle : '';

  const siteMenuRef = useRef(null);

  const stopAll = () =>
    areaId &&
    sendStopAll(areaId).catch(() => {
      const detail = { title: 'Failed to stop all vehicles', testId: 'stop-all-toast' };
      const event = new CustomEvent(Events.TOAST, { detail });
      window.dispatchEvent(event);
    });

  const sendStopAllEvent = () => {
    const detail = {
      title: 'Stop all vehicles?',
      description: 'Are you sure you want to stop all vehicles in the site?',
      subDescription: 'This will place a personal stand down on all site vehicles, that only you can release.',
      cancelLabel: 'Cancel',
      executeLabel: 'Yes, stop all  vehicles',
      onLoadingLabel: 'Stopping all vehicles',
      executeCallback: stopAll,
      modalTestId: 'stop-all-modal',
      cancelTestId: 'cancel-stop-all',
      controlTestId: 'send-stop-all',
    };

    const event = new CustomEvent(Events.MODAL, { detail });
    window.dispatchEvent(event);
    setOpen(false);
  };

  const releaseAll = () =>
    areaId &&
    sendReleaseAll(areaId).catch(() => {
      const detail = { title: 'Failed to release all vehicles', testId: 'release-all-toast' };
      const event = new CustomEvent(Events.TOAST, { detail });
      window.dispatchEvent(event);
    });

  const sendReleaseAllEvent = () => {
    const detail = {
      title: 'Release all vehicles?',
      description: 'Are you sure you want to release all vehicles in the site?',
      subDescription: 'This will release the personal stand down placed by you on all site vehicles.',
      cancelLabel: 'Cancel',
      executeLabel: 'Yes, release all  vehicles',
      onLoadingLabel: 'Releasing all vehicles',
      executeCallback: releaseAll,
      modalTestId: 'release-all-modal',
      cancelTestId: 'cancel-release-all',
      controlTestId: 'send-release-all',
    };

    const event = new CustomEvent(Events.MODAL, { detail });
    window.dispatchEvent(event);
    setOpen(false);
  };

  return (
    <div className={root}>
      <span className={toolTip}>
        <Button
          aria-controls="site-menu"
          aria-haspopup="true"
          aria-label={t`SiteMenu`}
          className={[SiteMenu, activeClass].join(' ')}
          onClick={() => setOpen(!open)}
          ref={siteMenuRef}
          data-testid="site-menu"
        >
          <SiteMenuIcon />
        </Button>
      </span>

      <Menu
        anchorEl={siteMenuRef.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        classes={{ list }}
        getContentAnchorEl={null}
        marginThreshold={0}
        id="site-menu"
        onClose={() => setOpen(false)}
        open={open}
      >
        <MenuItem
          className={clsx(menuItem, 'sdds-detail-02')}
          disableRipple
          disabled={allVehiclesOffline}
          onClick={canStopAllVehicles ? sendStopAllEvent : sendReleaseAllEvent}
          data-testid={canStopAllVehicles ? 'stop-all-menu-item' : 'release-all-menu-item'}
        >
          {canStopAllVehicles ? t`Stop all vehicles` : t`Release all vehicles`}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SiteMenu;
