import { MissionState, MissionState_updateFailed as MissionStateUpdateFailed } from '@ats/graphql';
import { t } from 'ttag';

export interface IMissionWarning {
  stateDisplayName: string;
  id: string;
  state: MissionStateUpdateFailed;
}

const getmissionWarning = (states: ReadonlyArray<MissionState> | null): IMissionWarning | null => {
  if (states === null || states[0] === undefined) return null;
  const { missionId, updateFailed } = states[0];

  if (updateFailed) return { stateDisplayName: t`Mission update failed`, id: missionId, state: updateFailed };

  return null;
};

export default getmissionWarning;
