import { makeStyles } from '@material-ui/core';
import { grey100 } from '../../../theme/color';
import StandardButton from './StandardButton';

const useStyles = makeStyles(
  {
    collapseContainer: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      '& svg': {
        marginLeft: '24px',
      },
      '& span': {
        marginLeft: '13px',
      },
    },
  },
  { index: 1 },
);

interface IProps {
  onClick: () => void;
}
export const CollapseButton = (props: IProps) => {
  const { onClick } = props;
  const { collapseContainer } = useStyles();

  return (
    <StandardButton classes="" onClick={onClick} data-testid="collapse-left-panel">
      <div className={collapseContainer}>
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 0.5H0.25V15.5H1.5V0.5Z" fill={grey100} />
          <path
            d="M11.1313 13.3687L10.25 14.25L4 8L10.25 1.75L11.1313 2.63125L6.39375 7.375L17.75 7.375V8.625H6.39375L11.1313 13.3687Z"
            fill={grey100}
          />
        </svg>
        <span className="sdds-headline-07">Collapse</span>
      </div>
    </StandardButton>
  );
};

export default CollapseButton;
