import { memo, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { ReleaseConditionType, EquipmentStatus_releaseConditions as ReleaseCondition } from '@ats/graphql';
import clsx from 'clsx';
import { t } from 'ttag';
import { IUserAttributes } from '../model/user';
import withEquipment from './withEquipment';
import type { IWithEquipmentProps } from './withEquipment';
import { isEqualOmitEquipmentTimestamps } from '../model/isEqual';
import getVehicleStateColor from '../model/vehicle/state/getVehicleStateColor';
import IVehicle from '../model/vehicle/IVehicle';
import VehicleDataAgeLabel from './vehicle/VehicleDataAgeLabel';
import BasicVehicleInfo from './vehicle/BasicVehicleInfo';
import { LockButton } from './icons/Lock';
import { InvehicleStanddownButton } from './icons/InvehicleStanddown';
import { PersonalStanddownButton } from './icons/PersonalStanddown';
import { DispatcherButton } from './icons/Dispatcher';
import ControlContainer from './vehicle/ControlContainer';
import { MissionView } from '../model/ViewTypes';

import { grey100, grey400, grey600 } from '../theme/color';
import { useCommonStyles } from '../theme/theme';
import Degradations from './degradation/Degradations';
import MissionWidget from './mission/MissionWidget';
import { Close } from './icons';
import useProgress from '../send/useProgress';
import MapVersionCard from './map/MapVersionCard';
import MapViewIcon from './icons/MapViewIcon';
import CameraViewIcon from './icons/CameraViewIcon';
import VehicleStateCard, { VehicleStateType } from './vehicle/VehicleStateCard';
import { hasRightToDispatch, getDispatcher } from '../model/vehicle/dispatcherRight';
import appearsOnline from '../model/vehicle/appearsOnline';

const useStyles = makeStyles(
  {
    root: {
      position: 'relative',
      height: 'calc(100vh - 64px)',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1300,
    },
    scrollContainer: {
      '&::-webkit-scrollbar': {
        color: grey600,
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: grey400,
      },
      display: 'flex',
      flexDirection: 'column',
      overflow: 'overlay',
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 1,
    },
    invehicleStanddownIcon: { position: 'absolute', left: '3px', top: '3px' },
    dispatcherIcon: {
      position: 'absolute',
      left: '4px',
      top: '3px',
    },
    lockIcon: {
      position: 'absolute',
      left: '4px',
      top: '3px',
    },
    personalStanddownIcon: {
      position: 'absolute',
      left: '3px',
      top: '3px',
    },
    controlContainer: {
      '&::-webkit-scrollbar': {
        color: grey600,
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: grey400,
      },
    },
    buttonPlaceholder: {
      flexBasis: '104px',
      flexGrow: 0,
      flexShrink: 0,
    },
    darkBackground: {
      backgroundColor: '#0D0F13',
    },
    cardContainer: {
      paddingLeft: '24px',
    },
  },
  { index: 1 },
);

interface IProps extends IWithEquipmentProps {
  darkMode: boolean;
  selectedEquipmentState: [string | null, (id: string | null) => void];
  missionViewContextState: [MissionView, React.Dispatch<React.SetStateAction<MissionView>>];
  setMissionLabel: React.Dispatch<React.SetStateAction<string>>;
  user: IUserAttributes;
  userExternalStaffReference: string | null;
  missionStateCardOverview: [string[], (id: string) => void];
  cameraViewState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

const EquipmentPanel = (props: IProps) => {
  const {
    darkMode,
    equipmentStatuses,
    selectedEquipmentState,
    user,
    userExternalStaffReference,
    missionViewContextState,
    missionStateCardOverview,
    setMissionLabel,
    cameraViewState,
  } = props;
  const [missionViewContext] = missionViewContextState;
  const [cameraView, setCameraView] = cameraViewState;
  const [selectedEquipmentId, setSelectedEquipmentId] = selectedEquipmentState;

  // Remember the progress of clearing, releasing, and standing down after closing the side bar or switching vehicles back and forth.
  const [clearingProgress, startClearing] = useProgress(selectedEquipmentId);
  const [releasingProgress, startReleasing] = useProgress(selectedEquipmentId);
  const [standingDownProgress, startStandingDown] = useProgress(selectedEquipmentId);
  const [renderButton, setRenderButton] = useState(false);
  const {
    root,
    scrollContainer,
    dispatcherIcon,
    lockIcon,
    invehicleStanddownIcon,
    personalStanddownIcon,
    controlContainer,
    buttonPlaceholder,
    darkBackground,
    cardContainer,
  } = useStyles();
  const { secondaryButton, cardifyButton } = useCommonStyles();

  if (!selectedEquipmentId || !equipmentStatuses || equipmentStatuses.length === 0) return null;

  const selectedEquipment: IVehicle | undefined = equipmentStatuses.find(
    ({ externalEquipmentReference }: IVehicle) => externalEquipmentReference === selectedEquipmentId,
  );

  if (!selectedEquipment) return null;

  const vehicleColor = getVehicleStateColor(
    selectedEquipment.operationalState,
    selectedEquipment.mode,
    selectedEquipment.status,
  );

  const isMe = userExternalStaffReference === selectedEquipment.owner;
  const systemName = selectedEquipment.dispatcher?.systemName;

  const isReleaseCondition = (condition: ReleaseCondition | null): condition is ReleaseCondition => !!condition;
  const typePersonal = (condition: ReleaseCondition) => condition.type === ReleaseConditionType.TYPE_PERSONAL;
  const typeInEquipment = (condition: ReleaseCondition) => condition.type === ReleaseConditionType.TYPE_IN_EQUIPMENT;
  const owner = (condition: ReleaseCondition) => condition.owner;

  const noNullReleaseConditions = selectedEquipment.releaseConditions?.filter(isReleaseCondition);
  const standDownConditionList = noNullReleaseConditions?.filter((x) => typePersonal(x));
  const standDownUserList: (string | null)[] | null = standDownConditionList?.map(owner) || null;

  const isOnline = selectedEquipment.status.toLowerCase() === 'online';
  const invehicleStanddownActive = noNullReleaseConditions
    ? noNullReleaseConditions.filter(typeInEquipment)?.length > 0
    : false;

  const hasOnlineEquipmentStatusMismatch = isOnline && !appearsOnline(selectedEquipment.timestamp);

  const marginBottomCameraViewButton = renderButton ? 0 : 24;

  const displayCameraViewButton =
    !darkMode &&
    (missionViewContext === MissionView.DefaultView ||
      missionViewContext === MissionView.MissionState ||
      missionViewContext === MissionView.ActiveMission);

  const background = darkMode ? darkBackground : null;
  return (
    <div className={clsx(root, background)} id="equipmentPanel">
      {!darkMode && (
        <Close
          click={() => setSelectedEquipmentId(null)}
          style={{
            position: 'absolute',
            color: grey100,
            top: '10px',
            left: '10px',
            width: '40px',
            height: '40px',
            cursor: 'pointer',
          }}
          testid="right-panel-close-icon"
        />
      )}

      {/* Instead of passing all vehicle data (as specified in IVehicle) we only want to pass what's actually needed */}
      <BasicVehicleInfo
        vehicleData={{
          mode: selectedEquipment.mode,
          operationalState: selectedEquipment.operationalState,
          releaseConditions: selectedEquipment.releaseConditions,
          speed: selectedEquipment.speed ? Math.round(selectedEquipment.speed * 3.6) : null,
          position: {
            heading:
              selectedEquipment.position && selectedEquipment.position.heading !== null
                ? Math.round(selectedEquipment.position.heading)
                : null,
          },
          status: selectedEquipment.status,
          reason: selectedEquipment.reason,
          owner: selectedEquipment.owner,
        }}
        vehicleDataAgeComponent={
          <VehicleDataAgeLabel
            key={selectedEquipmentId}
            areaId={selectedEquipment.areaId}
            externalEquipmentReference={selectedEquipment.externalEquipmentReference}
            darkMode
          />
        }
        lastCommunicationHoverLabel={
          <VehicleDataAgeLabel
            key={selectedEquipmentId}
            areaId={selectedEquipment.areaId}
            externalEquipmentReference={selectedEquipment.externalEquipmentReference}
          />
        }
        vehicle={selectedEquipment}
      />
      <div className={clsx(cardContainer)}>
        {selectedEquipment && selectedEquipment.position === null && (
          <VehicleStateCard type={VehicleStateType.MissingEquipmentStatus} />
        )}
        {hasOnlineEquipmentStatusMismatch && <VehicleStateCard type={VehicleStateType.OnlineEquipmentStatusMismatch} />}
        {selectedEquipment.areaId && selectedEquipment.mapVersion !== null && (
          <MapVersionCard vehicle={selectedEquipment} areaId={selectedEquipment.areaId} />
        )}
      </div>
      <div className={scrollContainer}>
        <DispatcherButton
          className={dispatcherIcon}
          testid="vehicle-dispatcher-icon"
          vehicle={selectedEquipment}
          online={isOnline}
          system={systemName}
        />
        <LockButton
          className={lockIcon}
          lockedBy={selectedEquipment.owner}
          testid="vehicle-lock-icon"
          vehicle={selectedEquipment}
          isMe={isMe}
          online={isOnline}
        />
        <InvehicleStanddownButton
          className={invehicleStanddownIcon}
          active={invehicleStanddownActive}
          testid="vehicle-invehiclestanddown-icon"
          vehicle={selectedEquipment}
          online={isOnline}
        />
        <PersonalStanddownButton
          className={personalStanddownIcon}
          standDownUserList={standDownUserList}
          testid="vehicle-personalstanddown-icon"
          vehicle={selectedEquipment}
          userExternalStaffReference={userExternalStaffReference}
          online={isOnline}
        />
        <div className={controlContainer}>
          {isOnline && (
            <ControlContainer
              dispatcher={getDispatcher(systemName)}
              lockedBy={selectedEquipment.owner}
              standDownUserList={standDownUserList}
              active={invehicleStanddownActive}
            />
          )}
        </div>
        <Degradations
          online={isOnline}
          selectedAreaId={selectedEquipment.areaId}
          selectedEquipmentId={selectedEquipmentId}
        />
        {selectedEquipment.areaId && isOnline && (
          <MissionWidget
            areaId={selectedEquipment.areaId}
            clearingProgress={clearingProgress}
            externalEquipmentReference={selectedEquipment.externalEquipmentReference}
            missionViewContextState={missionViewContextState}
            setMissionLabel={setMissionLabel}
            vehicleMode={selectedEquipment.mode}
            releasingProgress={releasingProgress}
            standingDownProgress={standingDownProgress}
            missionStateCardOverview={missionStateCardOverview}
            startClearing={startClearing}
            startReleasing={startReleasing}
            startStandingDown={startStandingDown}
            user={user}
            vehicle={selectedEquipment}
            vehicleColor={vehicleColor}
            setRenderButton={setRenderButton}
            darkMode={darkMode}
            dispatcherRight={hasRightToDispatch(systemName)}
          />
        )}
      </div>
      {displayCameraViewButton && (
        <Button
          className={clsx(secondaryButton, cardifyButton, 'sdds-detail-02')}
          style={{ margin: `24px 24px ${marginBottomCameraViewButton}px` }}
          disabled={!isOnline}
          disableRipple
          onClick={() => setCameraView((state) => !state)}
        >
          {cameraView ? (
            <>
              <MapViewIcon /> &nbsp; {t`Map view`}
            </>
          ) : (
            <>
              <CameraViewIcon /> &nbsp; {t`Camera view`}
            </>
          )}
        </Button>
      )}
      {/* establishes a layout surface that child components will render a button on top */}
      {renderButton && <div className={buttonPlaceholder} />}
    </div>
  );
};

export default withEquipment(memo(EquipmentPanel, isEqualOmitEquipmentTimestamps));
