/* eslint-disable camelcase */
import { Button, Box, CircularProgress, makeStyles, Divider } from '@material-ui/core';
import { useState } from 'react';
import { sendTriggerLog } from '@ats/graphql';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { grey900, grey800, white, greyTegel400 } from '../../../theme/color';
import Copy from '../../icons/Copy';
import Events from '../../../model/events';
import uuidInt32Format from './uuid2IntegerFormat';
import theme, { useCommonStyles } from '../../../theme/theme';

export const useStyles = makeStyles({
  card: {
    boxShadow: 'none',
    width: '368px',
    height: '210px',
    backgroundColor: grey900,
    cursor: 'pointer',
    color: white,
    position: 'relative',
    borderRadius: '4px',
  },
  cardFooter: {
    margin: theme.spacing(0, 0, 4, 0),
  },
  cardHeader: {
    padding: theme.spacing(4, 0, 0, 4),
    height: '15px',
  },
  cardDescription: {
    color: greyTegel400,
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '-0.01em',
    padding: theme.spacing(0, 4, 0, 4),
    marginTop: theme.spacing(4),
    lineHeight: '20px',
    height: '55px',
  },
  startEventButton: {
    maxWidth: '150px',
    minWidth: '120px',
    height: '40px',
    fontSize: '14px',
  },
  spacedItem: {
    marginLeft: '11px',
  },
  divider: {
    backgroundColor: grey800,
    margin: theme.spacing(0, 4, 0, 4),
  },
  footerControl: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    paddingLeft: theme.spacing(4),
    width: '340px',
  },
  logId: {
    width: '180px',
    height: '32px',
    letterSpacing: '-0.01rem',
    lineHeight: '16px',
    marginRight: '10px',
  },
  copyIcon: {
    bottom: '15px',
    right: '7px',
    position: 'absolute',
  },
});

interface IProps {
  externalEquipmentReference: string | null;
}

const TriggerLog = ({ externalEquipmentReference }: IProps) => {
  const {
    card,
    footerControl,
    startEventButton,
    spacedItem,
    cardFooter,
    cardHeader,
    copyIcon,
    divider,
    logId,
    cardDescription,
  } = useStyles();

  const { primaryButton } = useCommonStyles();

  const [loading, setLoading] = useState(false);
  const [triggerSuccess, setTriggerSuccess] = useState(false);
  const [logUuid, setLogUuid] = useState('');
  const TriggerLog = () => {
    setLoading(true);
    const guid = uuid();
    const uuidInt32 = uuidInt32Format(guid);
    setLogUuid(guid);
    if (!externalEquipmentReference) return;
    sendTriggerLog(externalEquipmentReference, uuidInt32)
      .then(() => {
        const event = new CustomEvent(Events.TOAST, {
          detail: {
            title: 'Onboard logs triggered',
            message: 'The command was accepted and a reference UUID has been created',
            type: 'success',
            testId: 'trigger-event-success',
          },
        });
        window.dispatchEvent(event);
        setLoading(false);
        setTriggerSuccess(true);
      })
      .catch(() => {
        const event = new CustomEvent(Events.TOAST, {
          detail: {
            title: 'Failed to trigger logs',
            message: 'Failed to sent command to trigger logs, please try again',
            testId: 'trigger-event-failure',
          },
        });
        window.dispatchEvent(event);
        setLoading(false);
        setTriggerSuccess(false);
      });
  };

  return (
    <div className={card}>
      <div>
        <h6 className={cardHeader}>Trigger onboard logs</h6>
        <Divider className={divider} />
        <h6 className={cardDescription} data-testid="trigger-event-description">
          Trigger onboard logs with a 60 seconds offset before and after. A UUID will be presented for you to copy.
        </h6>
      </div>

      <div className={cardFooter}>
        <Box className={footerControl}>
          <Button
            className={clsx(startEventButton, primaryButton)}
            disableRipple
            id="triggerOnboardLog"
            onClick={TriggerLog}
            disabled={loading}
            data-testid="start-trigger-event"
          >
            {loading ? (
              <>
                <CircularProgress size={15} />
                <span className={spacedItem}>Starting event</span>
              </>
            ) : (
              <>Start event</>
            )}
          </Button>
          {logUuid && !loading && triggerSuccess && (
            <div className={logId}>
              <span data-testid="trigger-event-uuid">UUID: {logUuid}</span>
            </div>
          )}
        </Box>
      </div>
      {logUuid && !loading && triggerSuccess && (
        <div className={copyIcon}>
          <Copy
            className={copyIcon}
            click={() => {
              navigator.clipboard.writeText(logUuid);
            }}
            data-testid="trigger-copy-icon"
          />
        </div>
      )}
    </div>
  );
};

export default TriggerLog;
