import { Button, Box, CircularProgress, makeStyles } from '@material-ui/core';
import { useRef, useEffect, useState, useCallback } from 'react';
import { useRiskmap, RISKMAP_CELL_ROWS, RISKMAP_CELL_COLUMNS, RiskmapCellRow, RiskClass } from '@ats/graphql';
import { SubView } from '../model/ViewTypes';
import { grey700, grey800, grey868, grey900, grey846 } from '../theme/color';
import Chevron from './icons/Chevron';
import FullScreenIcon from './icons/FullScreenIcon';
import NoDataAvailablePictogram from './pictograms/NoDataAvailable';
import theme from '../theme/theme';
import Events from '../model/events';
import { getConnectivity } from '../model/vehicle/useVehicles';

const NO_RISKMAP_TIMEOUT = 15000;

const colors: Record<RiskClass, number[]> = {
  [RiskClass.INVALID]: [231, 233, 238],
  [RiskClass.UNOCCUPIED_INSIDE_GEOFENCE]: [134, 143, 162], // #868FA2
  [RiskClass.UNOCCUPIED_OUTSIDE_GEOFENCE]: [29, 34, 41], // #1D2229
  [RiskClass.OCCUPIED_INSIDE_GEOFENCE]: [255, 35, 64], // #FF2340
  [RiskClass.OCCUPIED_OUTSIDE_GEOFENCE]: [144, 35, 48], // #902330
  [RiskClass.UNSEEN_INSIDE_GEOFENCE]: [36, 42, 51], // #242A33
  [RiskClass.UNSEEN_OUTSIDE_GEOFENCE]: [13, 15, 19], // #0D0F13
};

function updateImageData(imageData: ImageData, cellRows: RiskmapCellRow[]): ImageData {
  const { data } = imageData;
  for (let i = 0; i < data.length; i += 4) {
    const targetRow = Math.floor(i / 4 / RISKMAP_CELL_ROWS);
    const targetColumn = (i / 4) % RISKMAP_CELL_COLUMNS;

    const target = cellRows[targetRow].cellRisks[targetColumn];
    if (target) {
      const color = colors[target];
      if (color === undefined) {
        throw new Error(`Missing color specification for ${target}`);
      }
      const [r, g, b] = color;
      data[i + 0] = r;
      data[i + 1] = g;
      data[i + 2] = b;
      data[i + 3] = 255; // alpha
    }
  }
  return imageData;
}

const headerHeight = theme.spacing(16);
const backButtonHeight = theme.spacing(12);
const useStyles = makeStyles(
  {
    container: {
      position: 'relative',
    },
    expandedRiskMap: {
      display: 'flex',
      flexDirection: 'column',
      height: `calc(100vh - ${headerHeight}px)`,
      borderLeft: `1px solid ${grey868}`,
    },
    button: {
      width: '100%',
      fontSize: '14px',
      borderRadius: 0,
      height: backButtonHeight,
      paddingLeft: theme.spacing(12),
      backgroundColor: grey900,
      transition: 'none',
      justifyContent: 'left',
      '&:hover, &:focus': {
        border: 'unset',
        backgroundColor: grey800,
      },
      '&:active': {
        backgroundColor: grey700,
      },
      '&:visited': {
        outline: 'unset',
      },
    },
    loaderOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0.6,
      background: grey846,
    },
    loader: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    iconPadding: {
      marginRight: theme.spacing(3),
    },
    previewRiskMapContainer: {
      position: 'relative',
      '& canvas': {
        display: 'block',
      },
    },
    previewRiskMapCanvas: {
      width: '100%',
      height: '100%',
      imageRendering: 'pixelated',
      display: 'block',
    },
    fullScreenIcon: {
      position: 'absolute',
      right: theme.spacing(5),
      top: theme.spacing(5),
      cursor: 'pointer',
    },
    expandedRiskMapCanvas: {
      height: `calc(100vh - ${headerHeight}px - ${backButtonHeight}px)`,
      imageRendering: 'pixelated',
    },
  },
  { index: 1 },
);

const clearCanvas = (canvas: HTMLCanvasElement | null) => {
  const ctx = canvas?.getContext('2d');
  if (!ctx) return;
  ctx.clearRect(0, 0, RISKMAP_CELL_ROWS, RISKMAP_CELL_COLUMNS);
};

interface IProps {
  areaId: string | null;
  externalEquipmentReference: string | null;
  setSubView?: (arg0: SubView) => void;
  isPreviewRiskMap?: boolean;
  onExpand?: () => void;
}

function RiskMap(props: IProps) {
  const { areaId, externalEquipmentReference, setSubView, isPreviewRiskMap, onExpand } = props;
  const [riskmap, errorInRiskMap] = useRiskmap({
    areaId,
    externalEquipmentReference: externalEquipmentReference ?? '',
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [noRiskmapAvailableError, setNoRiskmapAvailableError] = useState<boolean>(false);
  const imageDataRef = useRef<ImageData | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const timeoutRef = useRef<number | null>(null);

  const vehicleIsOnline = externalEquipmentReference
    ? getConnectivity(externalEquipmentReference)?.status.toLowerCase() === 'online'
    : false;
  const showError = noRiskmapAvailableError || errorInRiskMap !== null;
  const showDimmingOverlay = loading || showError;

  const resetTimeout = () => {
    if (timeoutRef.current === null) return;
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  };

  const restartTimeout = useCallback(() => {
    resetTimeout();
    timeoutRef.current = window.setTimeout(triggerError, NO_RISKMAP_TIMEOUT);
  }, []);

  const triggerError = () => {
    setLoading(false);
    setNoRiskmapAvailableError(true);
    clearCanvas(canvasRef.current);

    const detail = {
      title: 'Error',
      message: 'Failed to get risk map',
      type: 'error',
      testId: 'riskmap-not-available-toast',
    };
    const event = new CustomEvent(Events.TOAST, { detail });
    window.dispatchEvent(event);
  };

  const {
    button,
    iconPadding,
    expandedRiskMap,
    previewRiskMapContainer,
    previewRiskMapCanvas,
    fullScreenIcon,
    expandedRiskMapCanvas,
    loader,
    loaderOverlay,
    container,
  } = useStyles();

  useEffect(() => {
    return resetTimeout;
  }, []);

  useEffect(() => {
    if (vehicleIsOnline) return;
    setLoading(false);
    setNoRiskmapAvailableError(true);
    clearCanvas(canvasRef.current);
    resetTimeout();
  }, [vehicleIsOnline]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx) return;
    canvas.width = RISKMAP_CELL_COLUMNS;
    canvas.height = RISKMAP_CELL_ROWS;
    imageDataRef.current = ctx.createImageData(RISKMAP_CELL_ROWS, RISKMAP_CELL_COLUMNS);
  }, []);

  useEffect(() => {
    if (!vehicleIsOnline) return;

    setLoading(true);
    setNoRiskmapAvailableError(false);
    restartTimeout();
    if (!riskmap || !canvasRef.current || !imageDataRef.current) return;
    if (!riskmap[0] || !riskmap[0].cellRows) {
      triggerError();
      return;
    }

    if (sessionStorage.getItem('measurePerformance')) window.performance.mark('RISKMAP_UPDATE:START');

    imageDataRef.current = updateImageData(imageDataRef.current, [...riskmap[0].cellRows]);
    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;
    ctx.putImageData(imageDataRef.current, 0, 0);

    setLoading(false);

    if (sessionStorage.getItem('measurePerformance')) window.performance.mark('RISKMAP_UPDATE:END');
    if (sessionStorage.getItem('measurePerformance'))
      window.performance.measure('RISKMAP_UPDATE', 'RISKMAP_UPDATE:START', 'RISKMAP_UPDATE:END');
  }, [externalEquipmentReference, vehicleIsOnline, riskmap, canvasRef, imageDataRef, restartTimeout]);

  useEffect(() => {
    if (errorInRiskMap) {
      triggerError();
    }
  }, [errorInRiskMap]);

  const BackButton = (
    <div onClick={() => setSubView && setSubView(SubView.Dashboard)}>
      <Button disableRipple className={button}>
        <Chevron className={iconPadding} heading={90} />
        Back
      </Button>
    </div>
  );

  const PreviewRiskMap = (
    <div className={previewRiskMapContainer}>
      <FullScreenIcon className={fullScreenIcon} click={onExpand} />
      <canvas data-testid="risk-map-canvas-preview" ref={canvasRef} className={previewRiskMapCanvas} />
      {showError ? (
        <NoDataAvailablePictogram
          width="70px"
          containerHeight="97%" // Slight nudge to appear centered
          text="No risk map available"
          testId="no-riskmap-available"
          containerPositon="absolute"
        />
      ) : null}
    </div>
  );

  const ExpandedRiskMap = (
    <div className={expandedRiskMap}>
      {BackButton}
      <Box display="flex" justifyContent="center">
        <canvas data-testid="risk-map-canvas-expanded" ref={canvasRef} className={expandedRiskMapCanvas} />
      </Box>
    </div>
  );

  return (
    <div className={container} id="riskmap">
      {isPreviewRiskMap ? PreviewRiskMap : ExpandedRiskMap}
      {showDimmingOverlay && <div className={loaderOverlay} />}
      {loading && (
        <div data-testid="loader" className={loader}>
          <CircularProgress size={25} />
        </div>
      )}
    </div>
  );
}

export default RiskMap;
