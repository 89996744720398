import { makeStyles } from '@material-ui/core';
import { t } from 'ttag';
import { grey500 } from '../../theme/color';

interface IProps {
  width: string;
  containerHeight: string;
  text: string;
  testId: string;
  containerPositon?: 'static' | 'absolute' | 'fixed' | 'relative' | 'sticky' | 'initial' | 'inherit';
}

// Pictrogram original name: ChargeManagementSystem
const NoDataAvailable = ({ width, containerHeight, containerPositon, text, testId }: IProps) => {
  const useStyles = makeStyles({
    container: {
      height: containerHeight,
      position: containerPositon,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: grey500,
      top: '0px',
      left: '0px',
      width: '100%',
      flexDirection: 'column',
    },
  });
  const { container } = useStyles();

  return (
    <div className={container} data-testid={testId}>
      <svg
        id="Icons_Outlined_Blue_2_px"
        data-name="Icons Outlined Blue 2 px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        fill={grey500}
        width={width}
      >
        <path
          className="cls-1"
          d="M34.28,13.07a11,11,0,0,0-20.54,0,11,11,0,0,0-2,21.46.79.79,0,0,0,.3,0,1,1,0,0,0,.3-1.95A9.14,9.14,0,0,1,6,24a9,9,0,0,1,8.41-9h.23L15,15a8.92,8.92,0,0,1,7.2,3.6,1,1,0,1,0,1.6-1.2A10.91,10.91,0,0,0,15.93,13a9,9,0,0,1,16.66,1.3,1,1,0,0,0,.9.7l.21,0,.2,0a9,9,0,0,1,1.8,17.55,1,1,0,0,0,.3,1.95,1,1,0,0,0,.3,0,11,11,0,0,0-2-21.43Z"
        />
        <path
          className="cls-1"
          d="M16.63,31.2a1,1,0,0,0,.58,1.28.91.91,0,0,0,.35.07,1,1,0,0,0,.94-.65A6,6,0,0,1,24,28l-.32.21a1,1,0,0,0-.25,1.39,1,1,0,0,0,.82.43,1,1,0,0,0,.57-.17l2.33-1.6s0-.05.07-.08l.14-.14a1.63,1.63,0,0,0,.11-.17s0-.06.05-.09,0-.05,0-.08a.69.69,0,0,0,0-.2.6.6,0,0,0,0-.19.58.58,0,0,0,0-.18,1.08,1.08,0,0,0-.08-.19l0-.07-1.61-2.34a1,1,0,1,0-1.64,1.14l.22.33A8,8,0,0,0,16.63,31.2Z"
        />
        <path
          className="cls-1"
          d="M16.55,34.41l-.06.08a.88.88,0,0,0-.12.17l-.06.06L15.1,37.28a1,1,0,0,0,.47,1.33,1,1,0,0,0,.43.1,1,1,0,0,0,.9-.57l.18-.37a8,8,0,0,0,7,4.23,8.4,8.4,0,0,0,1.32-.11,1,1,0,0,0-.33-2A6,6,0,0,1,19,37.11l.34.16a1,1,0,0,0,.43.1,1,1,0,0,0,.43-1.9l-2.55-1.22h0l-.09,0-.21,0h-.19a.41.41,0,0,0-.11,0s-.05,0-.08,0h-.06l-.13.06-.16.1Z"
        />
        <path
          className="cls-1"
          d="M29.35,36.5a1,1,0,1,0-2,.16l.22,2.82h0a.29.29,0,0,0,0,.1,1.16,1.16,0,0,0,0,.18,1.14,1.14,0,0,0,.1.19.73.73,0,0,1,.05.09l.05,0a1,1,0,0,0,.23.18s.06,0,.1.06l0,0a.86.86,0,0,0,.36.07h.08l2.82-.23a1,1,0,0,0-.15-2l-.41,0a8,8,0,0,0-.62-9.3,1,1,0,0,0-1.4-.14,1,1,0,0,0-.14,1.41,6,6,0,0,1,.63,6.7Z"
        />
      </svg>
      {t` ${text}`}
    </div>
  );
};
export default NoDataAvailable;
