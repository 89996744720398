import type { Feature, Geometry } from 'geojson';
import type { DeepReadonly } from 'ts-essentials';
import getIndexOpenArea from './getIndexOpenArea';
import type IBisectedRoute from './IBisectedRoute';
import type ICoordinates from '../ICoordinates';
import type RoutedFeature from '../RoutedFeature';
import type IFeatureProperties from '../../IFeatureProperties';

const bisectInOpenArea = (
  route: ReadonlyArray<RoutedFeature>,
  { latitude, longitude }: ICoordinates,
  openArea: DeepReadonly<Feature<Geometry, IFeatureProperties>>,
): IBisectedRoute | null => {
  const openAreaIndex = getIndexOpenArea(route, openArea);

  if (openAreaIndex === -1) return null;

  const coordinates = [longitude, latitude];
  const feature = route[openAreaIndex];
  const past = [
    ...route.slice(0, openAreaIndex),
    {
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: [feature.geometry.coordinates[0], coordinates],
      },
    },
  ];
  const future = [
    {
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: [coordinates, feature.geometry.coordinates[1]],
      },
    },
    ...route.slice(openAreaIndex + 1),
  ];

  return { future, past };
};

export default bisectInOpenArea;
