import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { Area } from '@ats/graphql';
import { grey100, grey300, grey800 } from '../../theme/color';
import getSortedVehicles from '../../model/vehicle/state/getSortedVehicles';
import AccordionButton from './button/AccordionButton';
import ellipsis from '../../model/format/ellipsis';
import StandardButton from './button/StandardButton';
import SiteMenu from '../SiteMenu';
import CollapseButton from './button/CollapseButton';
import LoadingSkeleton from '../LoadingSkeleton';
import IVehicle from '../../model/vehicle/IVehicle';
import VehicleStatusButtonContainer from './VehicleStatusButton.container';

const useStyles = makeStyles(
  {
    spaced: {
      margin: '20px',
    },
    accordion: { marginTop: '-1px' },
    areaList: {
      borderBottom: `1px solid ${grey800}`,
    },
    h3: {
      marginBottom: '24px',
      marginLeft: '24px',
      marginTop: '48px',
      color: grey100,
      width: '80%',
      display: 'inline-block',
    },
    noVehicles: {
      marginLeft: '24px',
      color: grey300,
    },
  },
  { index: 1 },
);

interface IProps {
  areas: readonly Area[];
  equipmentStatuses: readonly IVehicle[] | null;
  selectedAreaState: [string | null, React.Dispatch<React.SetStateAction<string | null>>];
  snapState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  hoverEquipmentState: [string | null, React.Dispatch<React.SetStateAction<string | null>>];
  selectedEquipmentState: [string | null, (s: string | null) => void];
  setMinimized: React.Dispatch<React.SetStateAction<boolean>>;
  alertsMuted: boolean;
}

const MaximizedPanel = ({
  areas,
  equipmentStatuses,
  selectedAreaState,
  snapState,
  hoverEquipmentState,
  selectedEquipmentState,
  setMinimized,
  alertsMuted,
}: IProps) => {
  const { spaced, areaList, h3, noVehicles, accordion } = useStyles();
  const [, setSnap] = snapState;
  const [hoverEquipmentId, setHoverEquipmentId] = hoverEquipmentState;
  const [selectedEquipmentId, setSelectedEquipmentId] = selectedEquipmentState;
  const [selectedAreaId, setSelectedAreaId] = selectedAreaState;
  const [accordionOpen, setAccordionOpen] = useState(false);

  const sortedEquipment = getSortedVehicles(equipmentStatuses);
  const areaLabel = areas?.find(({ id }) => id === selectedAreaId)?.displayName ?? '';

  return (
    <>
      {areas ? (
        <AccordionButton
          classes={[accordion]}
          open={accordionOpen}
          label={ellipsis(areaLabel, 20)}
          onClick={() => setAccordionOpen(!accordionOpen)}
          testid="area-selection-button"
        />
      ) : (
        <Skeleton className={spaced} variant="rect" width={210} height={30} />
      )}
      {accordionOpen && (
        <div className={areaList} id="areaSelectionList">
          {areas.map(({ id, displayName }) => (
            <StandardButton
              area
              key={id}
              onClick={() => {
                setAccordionOpen(false);
                setSelectedAreaId(id);
                setSelectedEquipmentId(null);
              }}
              data-testid={`area-selection-button-${displayName}`}
            >
              <p className="sdds-detail-02">{displayName}</p>
            </StandardButton>
          ))}
        </div>
      )}
      <div>
        <h3 className={clsx(h3, 'sdds-headline-07')}>Site Vehicles</h3>
        <SiteMenu />
      </div>
      {sortedEquipment?.map((vehicle) => (
        <VehicleStatusButtonContainer
          selectedAreaId={selectedAreaId}
          key={vehicle.externalEquipmentReference}
          vehicle={vehicle}
          selectedEquipmentId={selectedEquipmentId}
          hoverEquipmentId={hoverEquipmentId}
          setSnap={setSnap}
          setSelectedEquipmentId={setSelectedEquipmentId}
          setHoverEquipmentId={setHoverEquipmentId}
          alertsMuted={alertsMuted}
        />
      ))}
      {equipmentStatuses?.length === 0 && <p className={noVehicles}>No vehicles in area</p>}
      {equipmentStatuses === null && (
        <div className={spaced}>
          <LoadingSkeleton />
        </div>
      )}
      <CollapseButton onClick={() => setMinimized(true)} />
    </>
  );
};

export default MaximizedPanel;
