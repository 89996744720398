import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectedVehiclePosition } from '../../observables';
import geoJsonObservable from './geoJson.observable';
import routeObservable from './route.observable';
import routePreviewObservable from './routePreview.observable';
import bisectRoute from '../route/bisect/bisectRoute';

const bisectedRouteObservable = combineLatest([
  selectedVehiclePosition,
  routeObservable,
  routePreviewObservable,
  geoJsonObservable,
]).pipe(
  map(([position, route, routePreview, geoJson]) => {
    if (!position) return { future: [], past: [] };

    const { heading, longitude, latitude } = position;

    return route.length && latitude && longitude && heading
      ? bisectRoute(route, { longitude, latitude }, { heading }, geoJson)
      : { future: route.length ? route : routePreview, past: [] };
  }),
);

export default bisectedRouteObservable;
