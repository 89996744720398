import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';

import Events from '../../model/events';

interface IContent {
  message: string;
}

const PreviewStateInfoBox = () => {
  const [content, setContent] = useState<IContent | null>(null);

  const { message } = content || {};

  const callback = (event: CustomEvent) => {
    setContent({ message: event.detail.message });
  };

  useEffect(() => {
    window.addEventListener(Events.PREVIEW_LOAD, callback);
    return () => window.removeEventListener(Events.PREVIEW_LOAD, callback);
  }, []);

  return (
    <Typography data-testid="preview-state-info-box" className="sdds-detail-03">
      {message}
    </Typography>
  );
};

export default PreviewStateInfoBox;
