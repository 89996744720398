import { useMemo } from 'react';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

const useClickAndDriveLayer = () => {
  const source = useMemo(() => new VectorSource(), []);
  const layer = useMemo(() => new VectorLayer({ source }), [source]);
  return { source, layer };
};

export default useClickAndDriveLayer;
