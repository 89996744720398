import { forwardRef, Ref } from 'react';
import { makeStyles } from '@material-ui/core';
import { OperationalState, EquipmentMode } from '@ats/graphql';
import { grey868, grey846 } from '../../../theme/color';

import StandardButton, { IProps as IStandardButtonProps } from './StandardButton';
import StatusCircle from '../StatusCircle';

const useStyles = makeStyles(
  {
    vehicleButton: {
      '&:hover': {
        backgroundColor: grey868,
      },
      '&:active': {
        backgroundColor: grey846,
      },
    },
  },
  { index: 1 },
);

interface IProps extends IStandardButtonProps {
  operationalState: OperationalState | null;
  mode: EquipmentMode | null;
  status: string;
}

const TinyVehicleButton = forwardRef((props: IProps, ref: Ref<HTMLButtonElement>) => {
  const { operationalState, mode, status, ...rest } = props;
  const { vehicleButton } = useStyles();

  return (
    <StandardButton {...rest} square classes={[vehicleButton]} ref={ref}>
      <StatusCircle operationalState={operationalState} status={status} mode={mode} />
    </StandardButton>
  );
});

export default TinyVehicleButton;
