import { t } from 'ttag';
import NotificationType from './notificationType';

/**
 * This function returns for every notification type the corresponding notification label
 */
const getLabel = (notificationType: NotificationType) => {
  switch (notificationType as NotificationType) {
    case NotificationType.MissionStateCompleted:
      return t`Mission completed`;
    case NotificationType.MissionStateFailed:
      return t`Mission failed`;
    case NotificationType.UnexpectedOffline:
      return t`Vehicle offline`;
    default:
      return t`Invalid notification type ${notificationType}`;
  }
};
export default getLabel;
