import { Map as OlMap } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Cluster from 'ol/source/Cluster';
import Geometry from 'ol/geom/Geometry';
import Feature from 'ol/Feature';
import { boundingExtent, Extent } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';
import MapBrowserEvent from 'ol/MapBrowserEvent';

import { mapInteractionModule } from '../../model/observables';
import { coordinatesFromExtent, notUndefined } from './zoomAndCenterToContent';

const moduleName = 'DEFAULT';

interface IConfig {
  map: OlMap;
  vehicleLayer: VectorLayer<VectorSource<Geometry>>;
  poiLayer: VectorLayer<Cluster>;
  queueAndPaddockLayer: VectorLayer<VectorSource<Geometry>>;
  setHoverEquipmentId: (id: string | null) => void;
  setSelectedEquipmentId: (id: string | null) => void;
  setSelectedPoiId: (id: string | null) => void;
  setSelectedZone: (arg0: string, arg1: string) => void;
}

export function defaultMapInteractions(config: IConfig) {
  const { map, vehicleLayer, poiLayer, queueAndPaddockLayer, setHoverEquipmentId } = config;

  // Makes sure that the pointer move events from default map interactions (like hover on vehicles and POIs) gets swallowed
  let swallowEvents = false;

  mapInteractionModule.subscribe((value) => {
    swallowEvents = value !== moduleName;
  });

  return (event: MapBrowserEvent<PointerEvent>) => {
    if (swallowEvents) return;

    // Reset everything we can
    map.getTargetElement().style.cursor = '';

    // POIs
    poiLayer
      ?.getSource()
      ?.getFeatures()
      .forEach((poiFeature) => poiFeature.getProperties().setHover(false));

    // Clusters
    poiLayer
      ?.getSource()
      ?.getSource()
      ?.getFeatures()
      .forEach((poiFeature) => poiFeature.getProperties().setHover(false));

    queueAndPaddockLayer
      ?.getSource()
      ?.getFeatures()
      .forEach((queueAndPaddockFeature) => queueAndPaddockFeature.getProperties().setHover(false));

    const pixel = map.getEventPixel(event.originalEvent);

    /*
      Start comparing target pixel with vehicle features. Only when it's determined that no vehicles are hovered
      we compare with the POIs. When they are not hovered as well we continue to queue and paddock icons.
      This whole block should be re-written to async await to reduce the nesting
    */
    vehicleLayer.getFeatures(pixel).then((vehicleFeatures) => {
      const { externalEquipmentReference } = (vehicleFeatures && vehicleFeatures[0]?.getProperties()) || {};

      if (externalEquipmentReference) {
        // customUpdateHover on the feature will be called somewhere else, triggered by hoverEquipmentId
        map.getTargetElement().style.cursor = 'pointer';
        setHoverEquipmentId(externalEquipmentReference);
        return;
      }
      // We are not hovering on a vehicle (this should not be reset at the top since it will trigger two react re-renders every tick
      setHoverEquipmentId(null);

      // Here, vehicles have been tested for and we can check hover on queue and paddock icons
      queueAndPaddockLayer.getFeatures(pixel).then((queueAndPaddockFeatures) => {
        const { id } = (queueAndPaddockFeatures && queueAndPaddockFeatures[0]?.getProperties()) || {};
        if (id) {
          queueAndPaddockFeatures[0].getProperties().setHover(true);
          map.getTargetElement().style.cursor = 'pointer';
          return;
        }

        // Here, vehicles and queue/paddock icons have been tested for, so test for POIs
        poiLayer.getFeatures(pixel).then((poiClusters) => {
          if (poiClusters.length === 0) return;
          map.getTargetElement().style.cursor = 'pointer';
          const poiFeatures = poiClusters[0].get('features');
          const isCluster = poiFeatures.length === 1;
          // Is it a cluster with just one feature in it - manipulate the feature directly
          // Is it a cluster with many features - manipulate the cluster feature
          const target = isCluster ? poiFeatures[0] : poiClusters[0];
          target.getProperties().setHover(true);
        });
      });
    });
  };
}

export function defaultMapClick(config: IConfig) {
  const {
    map,
    vehicleLayer,
    poiLayer,
    queueAndPaddockLayer,
    setSelectedPoiId,
    setSelectedEquipmentId,
    setSelectedZone,
  } = config;

  // Makes sure that the pointer move events from default map interactions (like hover on vehicles and POIs) gets swallowed
  let swallowEvents = false;

  mapInteractionModule.subscribe((value) => {
    swallowEvents = value !== moduleName;
  });

  return (event: MapBrowserEvent<MouseEvent>) => {
    if (swallowEvents || !map) return;
    const pixel = map.getEventPixel(event.originalEvent);

    /**
     * This should be re-written into async await code, first we need to (asyncronously) determine if
     * any vehicle was clicked
     * */
    vehicleLayer?.getFeatures(pixel).then((features) => {
      const { externalEquipmentReference, click } = (features && features[0]?.getProperties()) || {};
      if (externalEquipmentReference) {
        click();
        setSelectedEquipmentId(externalEquipmentReference);
        return;
      }

      // If no vehicle was clicked we can test for queue and paddock icons
      queueAndPaddockLayer.getFeatures(pixel).then((queueAndPaddockFeatures) => {
        const { id: zoneQueueOrPaddockId, name: zoneQueueOrPaddock } =
          (queueAndPaddockFeatures && queueAndPaddockFeatures[0]?.getProperties()) || {};
        if (zoneQueueOrPaddockId) {
          setSelectedZone(zoneQueueOrPaddockId, zoneQueueOrPaddock);
          return;
        }

        // Lastly, we can test for POIs and POI clusters
        poiLayer.getFeatures(pixel).then((poiClusters) => {
          if (poiClusters.length === 0) return;
          const poiFeatures: Feature<Geometry>[] = poiClusters[0].get('features');
          if (poiFeatures.length > 1) {
            const poiExtent = poiFeatures.map((f: Feature<Geometry>) => f?.getGeometry()?.getExtent() as Extent);
            const coordinates: Coordinate[] = poiExtent.filter(notUndefined).map(coordinatesFromExtent);
            const extent = boundingExtent(coordinates);
            map.getView().fit(extent, { duration: 1000, padding: [300, 300, 300, 300] });
            return;
          }
          const feature = poiFeatures[0];

          const { id } = feature.getProperties();
          if (!id) return;

          setSelectedPoiId(id);
        });
      });
    });
  };
}
