import { useKeycloak } from '@react-keycloak/web';
import AppBar from './AppBar';
import lightningjs from '../vendor/lightningjs';
import { INotification } from '../model/notification/notificationType';
import { View } from '../model/ViewTypes';

interface IProps {
  openSettingsPanel: () => void;
  openLogDumpPanel: () => void;
  notificationPanelState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  selectedEquipmentState: [string | null, (s: string | null) => void];
  markAsNotVisible: (id?: string) => void;
  notifications: INotification[];
  setSnap: (arg0: boolean) => void;
  selectedAreaId: string | null;
  viewState: [View, React.Dispatch<React.SetStateAction<View>>];
}

const AppBarContainer = (props: IProps) => {
  const {
    openSettingsPanel,
    openLogDumpPanel,
    notificationPanelState,
    markAsNotVisible,
    notifications,
    selectedEquipmentState,
    setSnap,
    selectedAreaId,
    viewState,
  } = props;
  const { keycloak } = useKeycloak();

  const giveFeedback = () => {
    const usabilla = lightningjs.require('usabilla_live', 'https://w.usabilla.com/91e08fc9edb3.js');

    usabilla('hide');
    usabilla('click');
  };

  const signOut = () => {
    keycloak.logout();
  };

  return (
    <AppBar
      giveFeedback={giveFeedback}
      openSettingsPanel={openSettingsPanel}
      openLogDumpPanel={openLogDumpPanel}
      signOut={signOut}
      notificationPanelState={notificationPanelState}
      markAsNotVisible={markAsNotVisible}
      notifications={notifications}
      selectedEquipmentState={selectedEquipmentState}
      setSnap={setSnap}
      selectedAreaId={selectedAreaId}
      viewState={viewState}
    />
  );
};

export default AppBarContainer;
