import { makeStyles } from '@material-ui/core';
import { useEffect, useRef } from 'react';

import 'ol/ol.css';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Zoom from 'ol/control/Zoom';
import TileLayer from 'ol/layer/Tile';
import OlSourceXYZ from 'ol/source/XYZ';
import { Map as OlMap } from 'ol';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Circle as CircleStyle, Style, Fill, Stroke } from 'ol/style';
import Interaction from 'ol/interaction/Interaction';

import { blue, white } from '../theme/color';
import { degreesToRadians } from './map/Map.helpers';
import getVehicleStateColor from '../model/vehicle/state/getVehicleStateColor';

import withEquipment from './withEquipment';
import type { IWithEquipmentProps } from './withEquipment';
import IVehicle from '../model/vehicle/IVehicle';

const useStyles = makeStyles(
  {
    root: {
      width: '100%',
      height: '100%',
      zIndex: 1100,
    },
  },
  { index: 1 },
);

function createOLMiniVehicleFeature(externalEquipmentReference: string) {
  const feature = new Feature({
    externalEquipmentReference,
    name: 'vehicle',
  });

  const fill = new Fill({
    color: 'rgba(255,255,255,0.4)',
  });
  const stroke = new Stroke({
    color: blue,
    width: 1.25,
  });

  const style = new Style({
    image: new CircleStyle({
      fill, // Not used when using custom renderer
      stroke, // Not used when using custom renderer
      radius: 23, // This is only used for hover and click events and needs to be dynamic
    }),
    fill, // Not used when using custom renderer
    stroke, // Not used when using custom renderer
    renderer: (coordinates, state) => {
      if (typeof coordinates[0] !== 'number' || typeof coordinates[1] !== 'number') return;
      const { context } = state;

      const properties = feature.getProperties();
      const { heading, operationalState, mode, status } = properties;

      const vehicleColor = getVehicleStateColor(operationalState, mode, status);

      // Circle showing vehicle state
      context.save();
      context.translate(coordinates[0], coordinates[1]);
      context.scale(1, 1);
      context.beginPath();
      context.arc(0, 0, 20, 0, 2 * Math.PI);
      context.fillStyle = vehicleColor.foreground;
      context.fill();
      context.restore();

      // Arrow within the vehicle circle
      context.save();
      context.translate(coordinates[0], coordinates[1]);
      context.rotate(degreesToRadians(heading));
      context.scale(1 * 1.2, 1 * 1.2);
      context.translate(-7.75, -9.5);
      context.beginPath();
      context.moveTo(8, 0);
      context.lineTo(0.5, 15);
      context.lineTo(8, 11.25);
      context.lineTo(15.5, 15);
      context.lineTo(8, 0);
      context.fillStyle = white;
      context.fill();
      context.restore();
    },
  });

  feature.setStyle(style);

  return feature;
}

function createMiniMap(containerRef: HTMLDivElement, externalEquipmentReference: string) {
  const satelliteLayerSource = new OlSourceXYZ({
    maxZoom: 17,
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  });
  const baseLayer = new TileLayer({
    opacity: 0.7,
    preload: 8,
    source: satelliteLayerSource,
    visible: true,
    zIndex: 0,
  });

  const source = new VectorSource();
  const vehicleLayer = new VectorLayer({ source });
  vehicleLayer.set('name', 'VehicleLayer');

  const vehicleFeature = createOLMiniVehicleFeature(externalEquipmentReference);
  source.addFeature(vehicleFeature);

  const view = new View({
    zoom: 15,
  });

  const map = new OlMap({
    target: containerRef,
    layers: [baseLayer, vehicleLayer],
    view,
  });

  map.addInteraction(
    new Interaction({
      handleEvent: () => {
        return false;
      },
    }),
  );

  map.getControls().forEach((control) => {
    if (control instanceof Zoom) {
      map.removeControl(control);
    }
  });

  return {
    map,
    feature: vehicleFeature,
  };
}

function selected(id: string) {
  return ({ externalEquipmentReference }: IVehicle) => id === externalEquipmentReference;
}

interface IProps extends IWithEquipmentProps {
  externalEquipmentReference: string | null;
}

function MiniMap(props: IProps) {
  const { root } = useStyles();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapPointer = useRef<OlMap | null>(null);
  const featurePointer = useRef<Feature | null>(null);

  const { externalEquipmentReference, equipmentStatuses } = props;

  useEffect(() => {
    if (!mapContainerRef.current) return;
    if (!externalEquipmentReference) return;
    if (mapPointer.current) return; // The map exists already, do not create it again
    const { map, feature } = createMiniMap(mapContainerRef.current, externalEquipmentReference);
    mapPointer.current = map;
    featurePointer.current = feature;

    // Ignore dependencies and treat this as a mount - running only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Set the vehicle position and camera view
    if (!mapPointer.current || !featurePointer.current || !equipmentStatuses || !externalEquipmentReference) return;
    const selectedVehicle = equipmentStatuses.find(selected(externalEquipmentReference));
    if (!selectedVehicle) return;
    const { longitude, latitude, heading } = selectedVehicle.position || {};
    const { operationalState, mode, status } = selectedVehicle || {};
    if (!longitude || !latitude) return;
    mapPointer.current.getView().animate({ center: fromLonLat([longitude, latitude]), duration: 10 });
    featurePointer.current.setGeometry(new Point(fromLonLat([longitude, latitude])));
    featurePointer.current.setProperties({ heading, operationalState, mode, status });
    mapPointer.current.updateSize();
  }, [equipmentStatuses, externalEquipmentReference, mapPointer]);

  return <div id="minimap" className={root} ref={mapContainerRef} />;
}

export default withEquipment(MiniMap);
