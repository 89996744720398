import { t } from 'ttag';
import { useState, MouseEvent, useEffect, useMemo, useCallback } from 'react';
import { Theme, Button, Link, makeStyles, createStyles } from '@material-ui/core';
import { loadMoreItems, useEquipmentEventsLog } from '@ats/graphql';
import clsx from 'clsx';
import LogTable from './LogTable';
import { useCommonStyles } from '../../../theme/theme';
import { blue500 } from '../../../theme/color';
import InfoIcon from '../../icons/InfoIcon';
import Events from '../../../model/events';
import TimeFilter, { getTimeFilterForDuration, ITimeFilter, ITimeFilterOption, TimeFilterDuration } from './TimeFilter';
import prettyTime from './prettyTime';
import getEventsLogRowValues from './getEventsLogRowValues';
import { getConnectivity } from '../../../model/vehicle/useVehicles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: theme.spacing(0.5),
    },
    detailsRow: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: theme.spacing(2.5),
      },
    },
    refresh: {
      marginLeft: theme.spacing(1),
      color: blue500,
    },
  }),
);

interface IProps {
  externalEquipmentReference: string;
}

enum TypeFilter {
  DEGRADATIONS = 'degradations',
  FAULTS = 'faults',
}

interface IFilters {
  time: ITimeFilter;
  types: TypeFilter[];
}

const timeFilterOptions: ITimeFilterOption[] = [
  { key: TimeFilterDuration.LAST_HOUR, label: 'Last hour', duration: 1 },
  { key: TimeFilterDuration.LAST_8_HOURS, label: 'Last 8 hours', duration: 8 },
  { key: TimeFilterDuration.LAST_24_HOURS, label: 'Last 24 hours', duration: 24 },
];

const EquipmentEventsLog = ({ externalEquipmentReference }: IProps) => {
  const { toolbar, detailsRow, refresh } = useStyles();
  const { primaryButton, pill } = useCommonStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [timeFilterIndex, setTimeFilterIndex] = useState(0);
  const [hasNoData, setHasNoData] = useState(true);
  const isVehicleOnline = externalEquipmentReference
    ? getConnectivity(externalEquipmentReference)?.status.toLowerCase() === 'online'
    : false;

  const initialTimeFilter = getTimeFilterForDuration(timeFilterOptions[timeFilterIndex].duration);

  const [filters, setFilters] = useState<IFilters>({
    types: [TypeFilter.DEGRADATIONS],
    time: initialTimeFilter,
  });

  const limit = 50;
  const [events, errorInEventsLog, isLoading, isEndOfList] = useEquipmentEventsLog({
    externalEquipmentReference,
    start: filters.time.start,
    end: filters.time.end,
    types: filters.types.join(','),
    offset: 0,
    limit,
  });

  const derivedEvents = events ? events.map((ev) => ev.timestamp).join('-') : '';

  const eventsLogValues = useMemo(() => {
    return getEventsLogRowValues(events);
    // only update values if derivedEvents change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedEvents]);

  useEffect(() => {
    if (errorInEventsLog) {
      triggerErrorToast();
      setHasNoData(true);
    } else {
      const hasNoEvents = events?.length === 0;
      const hasOnlyEventsWithoutValues = eventsLogValues.length === 0;
      const hasNoData = hasNoEvents || hasOnlyEventsWithoutValues;
      setHasNoData(hasNoData);
    }
    // only re-render when derived log values or error change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorInEventsLog, eventsLogValues]);

  const handleTimeFilterChange = useCallback((index: number, time: ITimeFilter) => {
    setTimeFilterIndex(index);
    setAnchorEl(null);
    setFilters(({ types }) => ({
      types,
      time,
    }));
  }, []);

  const handleRefresh = useCallback(() => {
    setFilters(({ types }) => ({
      types,
      time: getTimeFilterForDuration(timeFilterOptions[timeFilterIndex].duration),
    }));
  }, [timeFilterIndex]);

  const handleScrollNearBottom = useCallback(() => {
    if (!errorInEventsLog) {
      loadMoreItems(limit);
    }
    // only update function if time filter is changed to prevent references to stale instance of function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.time.start, filters.time.end]);

  const triggerErrorToast = () => {
    const detail = {
      title: t`Failed to get vehicle log`,
      message: t`No data available`,
      type: 'error',
      testId: 'events-log-not-available',
    };
    window.dispatchEvent(new CustomEvent(Events.TOAST, { detail }));
  };

  return (
    <>
      <div className={toolbar} data-testid="equipment-events-log">
        <Button className={clsx(primaryButton, pill, 'sdds-detail-02')} size="small" disableRipple>
          {t`Degradations`}
        </Button>
        <TimeFilter
          options={timeFilterOptions}
          anchorEl={anchorEl}
          handleListItemClick={(event: MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
          }}
          handleChange={handleTimeFilterChange}
          handleClose={() => {
            setAnchorEl(null);
          }}
          selectedIndex={timeFilterIndex}
        />
      </div>
      <div className={detailsRow}>
        <InfoIcon />
        <p className={clsx('sdds-detail-02', 'MuiTypography-body1')}>
          Log last updated at {prettyTime(filters.time.end)}
        </p>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          className={clsx(refresh, 'sdds-detail-02')}
          component="button"
          onClick={handleRefresh}
          data-testid="equipment-events-log-refresh"
        >
          {t`Refresh`}
        </Link>
      </div>
      <LogTable
        rows={eventsLogValues}
        onScrollNearBottom={handleScrollNearBottom}
        isLoading={isLoading && isVehicleOnline}
        isEndOfList={isEndOfList}
        hasNoData={hasNoData || !isVehicleOnline}
      />
    </>
  );
};

export default EquipmentEventsLog;
