import GeoJSON from 'ol/format/GeoJSON';
import { Map as OlMap } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { boundingExtent, buffer, Extent } from 'ol/extent';
import geoJsonObservable from '../../model/map/observable/geoJson.observable';

export const coordinatesFromExtent = (extent: Extent): Coordinate => {
  return [extent[0], extent[1]];
};

export const notUndefined = (extent: Extent | undefined) => {
  return extent !== undefined;
};

export default function zoomAndCenterToContent(map: OlMap) {
  geoJsonObservable.subscribe((geoJson) => {
    if (!geoJson) return;

    const extent = new GeoJSON({
      featureProjection: 'EPSG:3857',
    })
      .readFeatures(geoJson)
      .map((feature) => feature?.getGeometry()?.getExtent() as Extent);

    const coordinates: Coordinate[] = extent.filter(notUndefined).map(coordinatesFromExtent);
    map.getView().fit(buffer(boundingExtent(coordinates), 500), { size: map.getSize(), maxZoom: 16 });
  });
}
