import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { grey100, grey800 } from '../../../theme/color';
import StandardButton from './StandardButton';

const useStyles = makeStyles(
  {
    accordionContainer: {
      color: grey100,
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${grey800}`,
      alignItems: 'center',
      '& > p': {
        marginLeft: '24px',
      },
      '& > svg': {
        marginRight: '33px',
      },
    },
  },
  { index: 1 },
);

interface IProps {
  onClick: () => void;
  open: boolean;
  label: string;
  testid: string;
  classes?: string | ConcatArray<string>;
}
export const AccordionButton = (props: IProps) => {
  const { open, onClick, label, classes, testid } = props;

  const { accordionContainer } = useStyles();
  const rotation = `rotate(${open ? 180 : 0})`;

  return (
    <StandardButton classes={classes} onClick={onClick} data-testid={testid}>
      <div className={clsx(accordionContainer, 'sdds-headline-04')}>
        <p data-testid={`${testid}-label`}>{label}</p>
        <svg
          transform={rotation}
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.75 1.49805L1.66055 0.587502L7 5.92695L12.3394 0.587502L13.25 1.49805L7 7.74805L0.75 1.49805Z"
            fill={grey100}
          />
        </svg>
      </div>
    </StandardButton>
  );
};

export default AccordionButton;
