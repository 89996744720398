import { Equipment, EquipmentStatus, ConnectivityStatus } from '@ats/graphql';
import { indexBy, prop, omit } from 'ramda';
import IVehicle from './IVehicle';

export const nulledEquipmentStatus: EquipmentStatus = {
  externalEquipmentReference: '',
  areaId: '',
  owner: null,
  mode: null,
  operationalState: null,
  releaseConditions: [],
  position: null,
  positionAccuracy: null,
  speed: null,
  weight: null,
  mapVersion: null,
  versionHash: null,
  versionTag: null,
  timestamp: null,
  dispatcher: null,
} as EquipmentStatus;

const getVehicleConnectivity = (connectivity: ReadonlyArray<ConnectivityStatus> | null, equipment: Equipment) => {
  const { externalEquipmentReference } = equipment;
  if (!connectivity) return { status: 'online', reason: null };

  const vehicleConnectivity = connectivity.find(
    ({ externalEquipmentReference: comparee }) => comparee === externalEquipmentReference,
  );

  if (!vehicleConnectivity) return { status: 'online', reason: null };

  return {
    status: vehicleConnectivity.status,
    reason: vehicleConnectivity.reason,
  };
};

const mergeVehicles = (
  equipments: ReadonlyArray<Equipment> | null,
  statuses: ReadonlyArray<EquipmentStatus> | null,
  connectivity: ReadonlyArray<ConnectivityStatus> | null,
): ReadonlyArray<IVehicle> | null => {
  let vehicles = null;

  if (equipments && statuses) {
    const statusesById = statuses ? indexBy(prop('externalEquipmentReference'), statuses) : {};

    vehicles = equipments.map((equipment) => {
      const vehicleStatus =
        equipment.externalEquipmentReference in statusesById
          ? statusesById[equipment.externalEquipmentReference]
          : omit(['areaId', 'externalEquipmentReference'], nulledEquipmentStatus); // If we don't have EquipmentStatus for a given equipment we "null" those fields

      const { status, reason } = getVehicleConnectivity(connectivity, equipment);

      const vehicle: IVehicle = {
        ...equipment,
        ...vehicleStatus,
        status,
        reason,
      };

      return vehicle;
    });
  }

  return vehicles;
};

export default mergeVehicles;
