import { makeStyles } from '@material-ui/core';
import { grey100 } from '../../../theme/color';
import StandardButton from './StandardButton';

const useStyles = makeStyles(
  {
    expandButton: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  { index: 1 },
);

interface IProps {
  onClick: () => void;
}
export const ExpandButton = (props: IProps) => {
  const { onClick } = props;
  const { expandButton } = useStyles();

  return (
    <StandardButton classes="" square onClick={onClick} data-testid="expand-left-panel">
      <svg
        className={expandButton}
        transform="rotate(180)"
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.5 0.5H0.25V15.5H1.5V0.5Z" fill={grey100} />
        <path
          d="M11.1313 13.3687L10.25 14.25L4 8L10.25 1.75L11.1313 2.63125L6.39375 7.375L17.75 7.375V8.625H6.39375L11.1313 13.3687Z"
          fill={grey100}
        />
      </svg>
    </StandardButton>
  );
};

export default ExpandButton;
