function prettyTime(time: Date | string | null) {
  if (time === null) return '-';
  if (typeof time === 'string') time = new Date(time);
  const l = leftPad;

  const clock = `${l(time.getHours())}:${l(time.getMinutes())}:${l(time.getSeconds())}`;
  const calendar = `${l(time.getDate())}.${l(time.getMonth() + 1)}.${time.getFullYear()}`;
  return `${clock} - ${calendar}`;
}

export default prettyTime;

export function leftPad(num: number): string {
  if (num.toString().length === 1) return `0${num.toString()}`;
  return num.toString();
}
