import { useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Area } from '@ats/graphql';
import withEquipment from '../withEquipment';
import type { IWithEquipmentProps } from '../withEquipment';
import { grey900 } from '../../theme/color';
import { isEqualOmitEquipmentTimestamps } from '../../model/isEqual';
import Events from '../../model/events';
import MaximizedPanel from './MaximizedPanel';
import MinimizedPanel from './MinimizedPanel';

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: grey900,
      overflowX: 'hidden',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      '& button:last-child': {
        marginTop: 'auto',
      },
      height: 'calc(100vh - 64px)',
      zIndex: 1300,
    },
    minimizedRoot: {
      flexBasis: '68px !important',
    },
  },
  { index: 1 },
);

interface IProps extends IWithEquipmentProps {
  areas: readonly Area[];
  selectedAreaState: [string | null, React.Dispatch<React.SetStateAction<string | null>>];
  snapState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  hoverEquipmentState: [string | null, React.Dispatch<React.SetStateAction<string | null>>];
  selectedEquipmentState: [string | null, (id: string | null) => void];
  collapseState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  alertsMuted: boolean;
}

const LeftPanel = ({
  areas,
  equipmentStatuses,
  selectedAreaState,
  selectedEquipmentState,
  hoverEquipmentState,
  snapState,
  alertsMuted,
  collapseState: [minimized, setMinimized],
}: IProps) => {
  const { root, minimizedRoot } = useStyles();
  const maximized = !minimized;

  useEffect(() => {
    window.dispatchEvent(new Event(Events.MAP_AREA_CHANGED));
  }, [minimized]);

  return (
    <div className={[root].concat(minimized ? minimizedRoot : []).join(' ')}>
      {maximized ? (
        <MaximizedPanel
          setMinimized={setMinimized}
          selectedAreaState={selectedAreaState}
          areas={areas}
          equipmentStatuses={equipmentStatuses}
          snapState={snapState}
          hoverEquipmentState={hoverEquipmentState}
          selectedEquipmentState={selectedEquipmentState}
          alertsMuted={alertsMuted}
        />
      ) : (
        <MinimizedPanel
          areas={areas}
          equipmentStatuses={equipmentStatuses}
          selectedAreaState={selectedAreaState}
          snapState={snapState}
          hoverEquipmentState={hoverEquipmentState}
          selectedEquipmentState={selectedEquipmentState}
          setMinimized={setMinimized}
        />
      )}
    </div>
  );
};

export default withEquipment(memo(LeftPanel, isEqualOmitEquipmentTimestamps));
