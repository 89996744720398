import { SvgIcon } from '@material-ui/core';
import CSS from 'csstype';
import { ICommonIconProps } from './CommonIconProps';

interface IProps extends ICommonIconProps {
  click: React.MouseEventHandler<SVGSVGElement> | undefined;
  style?: Pick<CSS.Properties, 'position' | 'right' | 'top' | 'width' | 'height' | 'color' | 'cursor'>;
}

const FullScreenIcon = ({ click, className, style, testid }: IProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" onClick={click} className={className} style={style} data-testid={testid}>
    <path d="M7 0H0V7H2V2H7V0Z" fill="white" fillOpacity="0.6" />
    <path d="M13 20H20V13H18V18H13V20Z" fill="white" fillOpacity="0.6" />
    <path d="M20 0V7H18V2L13 2V0H20Z" fill="white" fillOpacity="0.6" />
    <path d="M0 13V20H7L7 18H2L2 13H0Z" fill="white" fillOpacity="0.6" />
  </SvgIcon>
);

export default FullScreenIcon;
