import { min } from 'ramda';
import getDistances from '../getDistances';
import { getDirectionPenalty } from '../getDirectionPenalty';
import type ICoordinates from '../ICoordinates';
import type RoutedFeature from '../RoutedFeature';

const getIndexClosestFeature = (
  route: ReadonlyArray<RoutedFeature>,
  coordinates: ICoordinates,
  heading: number | null,
  maxDistanceMeters?: number,
): { featureIndex: number; coordinateIndex: number } | null => {
  if (route.length === 0 || heading === null) return null;

  const allDistances = route.map(({ geometry }) =>
    getDistances(geometry.coordinates, coordinates).map(
      (distance, index) => distance + getDirectionPenalty(heading, geometry.coordinates, index),
    ),
  );
  const minDistances = allDistances.map((distances) => distances.reduce(min, Infinity));
  const minDistance = minDistances.reduce(min, Infinity);

  if (!maxDistanceMeters || minDistance <= maxDistanceMeters) {
    const featureIndex = minDistances.indexOf(minDistance);
    const coordinateIndex = allDistances[featureIndex].indexOf(minDistance);

    if (featureIndex >= 0 && coordinateIndex >= 0) {
      return { featureIndex, coordinateIndex };
    }
  }

  return null;
};

export default getIndexClosestFeature;
