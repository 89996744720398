import {
  useEquipment,
  useEquipmentStatuses,
  useConnectivityStatus,
  GetEquipmentVariables,
  Equipment,
  getEquipment,
  getConnectivityStatus,
  EquipmentStatus,
  ConnectivityStatus,
  getDegradationPlan,
  DegradationPlanElement,
} from '@ats/graphql';
import { Subscription } from 'rxjs';
import { omit } from 'ramda';
import { selectedAreaId, selectedVehicleId as selectedVehicleIdObservable } from '../observables';
import IVehicle from './IVehicle';
import mergeVehicles from './mergeVehicles';

const useVehicles = (filters: GetEquipmentVariables): [ReadonlyArray<IVehicle> | null, unknown] => {
  const [equipments, errorEquipments] = useEquipment(filters);
  const [statuses, errorStatuses] = useEquipmentStatuses(filters);
  const connectivityFilter =
    filters.externalEquipmentReference === null ? filters : omit(['externalEquipmentReference'], filters);
  const [connectivity, errorConnectivity] = useConnectivityStatus(connectivityFilter);

  const vehicles = filters.areaId ? mergeVehicles(equipments, statuses, connectivity) : null;

  return [vehicles, errorEquipments || errorStatuses || errorConnectivity];
};

export default useVehicles;

const byId = (id: string) => ({ externalEquipmentReference }: Equipment | EquipmentStatus | ConnectivityStatus) =>
  id === externalEquipmentReference;

/*
  Help function to get the display name from a vehicle id (externalEquipmentReference)
*/
export const getDisplayName = (() => {
  let equipment: Equipment[] | null = null;
  let subscription: Subscription | null = null;
  selectedAreaId.subscribe((areaId: string | null) => {
    subscription?.unsubscribe();
    if (!areaId) return;
    subscription = getEquipment({ areaId }).subscribe((equipmentPrime) => {
      equipment = [...equipmentPrime];
    });
  });
  return (id: string) => equipment?.find(byId(id))?.displayName ?? 'Unknown vehicle';
})();

/*
  Help function to get connectivity status from a vehicle id (externalEquipmentReference)
*/
export const getConnectivity = (() => {
  let connectivity: ConnectivityStatus[] | null = null;
  let subscription: Subscription | null = null;
  selectedAreaId.subscribe((areaId: string | null) => {
    subscription?.unsubscribe();
    if (!areaId) return;
    subscription = getConnectivityStatus({ areaId }).subscribe((connectivityPrime) => {
      connectivity = [...connectivityPrime];
    });
  });
  return (id: string) => {
    const equipmentConnectivity = connectivity?.find(byId(id));
    if (!equipmentConnectivity) return null;
    const { status, reason } = equipmentConnectivity;
    return { status, reason };
  };
})();

/*
  Help function to get the degradation description from the currently selected vehicles degradation plan
*/
export const getDegradationDescription = (() => {
  let degradations: DegradationPlanElement[] | null = null;

  selectedVehicleIdObservable.subscribe((value) => {
    degradations = null;

    if (value === null) return;

    getDegradationPlan({ externalEquipmentReference: value }).subscribe((response) => {
      degradations = response[0].degradationInformation;
    });
  });

  return (_degradation: string) => {
    if (degradations === null) return '-';
    const item = degradations.find(({ degradation }) => degradation === _degradation);
    if (!item) return `Unknown degradation: ${_degradation}`;
    return item.information.description;
  };
})();
