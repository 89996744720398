export const white = '#fff';
export const ultraDimWhite = 'rgba(255, 255, 255, 0.38)';
export const dimWhite = 'rgba(255, 255, 255, 0.6)';
export const semiDimWhite = 'rgba(255, 255, 255, 0.87)';
export const transparent = 'rgba(0, 0, 0, 0)';
export const grey = '#404040';
export const grey50 = '#F9FAFB';
export const grey100 = '#EDEFF3';
export const grey200 = '#E7E9EE';
export const grey300 = '#a4a6ab';
export const grey400 = '#82848a';
export const greyTegel400 = '#CDD1DB';
export const grey500 = '#696a70';
export const grey600 = '#515257';
export const grey700 = '#56657A';
export const grey800 = '#37414f';
export const grey846 = '#2B323D';
export const grey868 = '#242A33';
export const grey900 = '#1D2229';
export const grey958 = '#0D0F13';
export const black = '#000';
export const semiDimBlack = '#0d0f13de';

export const red = '#ff2340';
export const red400 = '#DA2F39';
export const red500 = '#DE2B36';
export const red600 = '#C20019';
export const red700 = '#950A16';

export const yellow = '#f1c21b';
export const orange500 = '#fa6e23';

export const green = '#1dab8b';
export const green300 = '#89B691';
export const green600 = '#26b494';
export const green800 = '#14a383';

export const blue = '#4a89f3';
export const blue100 = '#BACDE8';
export const blue400 = '#2B70D3';
export const blue500 = '#2058A8';
export const blue600 = '#16417F';
export const blue700 = '#0F3263';
export const blue800 = '#041E42';
export const blue900 = '#001533';

export const beige900 = '#ceb888';

export const highEmphasisLightTheme = '#0D0F13';
export const highEmphasisDarkTheme = '#FFFFFFDE';

export const disabledText = 'rgba(255, 255, 255, 0.38)';
