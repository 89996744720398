import { useState } from 'react';
import { SvgIcon } from '@material-ui/core';
import { black, grey300, white } from '../../theme/color';

export interface ISpeakerProps {
  muted: boolean;
  onClick: React.MouseEventHandler<SVGSVGElement>;
  testid: string;
}

export const Speaker = ({ muted, onClick, testid }: ISpeakerProps) => {
  const [hover, setHover] = useState(false);
  const iconColor = hover ? black : white;
  const circleFill = hover ? grey300 : 'none';

  return (
    <SvgIcon
      style={{ fontSize: '8rem' }}
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      onClick={onClick}
      data-testid={testid}
      onMouseEnter={(e) => {
        e.stopPropagation();
        setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
    >
      <circle cx="256" cy="256" r="255" fill={circleFill} />
      <g transform="scale(1, 1) translate(128, 128)">
        {muted ? (
          <>
            <path
              d="M109 71V185.262L72.615 151.448L69.812 149H65.868H28V107H66.119H70.076L72.882 104.532L109 70.807V71ZM114.214 54.019C113.007 54.019 111.817 54.235 110.828 55.252L66.119 97H18V159H65.868L110.875 200.793C111.863 201.804 113.01 202.135 114.214 202.135C116.695 202.135 119 200.089 119 197.174V58.858C119 55.941 116.695 54.019 114.214 54.019Z"
              fill={iconColor}
            />
            <path
              d="M233.49 85.6611L226.42 78.5881L183.994 121.005L141.578 78.5801L134.505 85.6501L176.922 128.075L134.496 170.492L141.566 177.564L183.992 135.148L226.409 177.574L233.481 170.503L191.064 128.077L233.49 85.6611Z"
              fill={iconColor}
            />
          </>
        ) : (
          <>
            <path
              d="M156.777 166.927L151.177 158.642C153.123 157.327 154.951 155.818 156.612 154.159C158.276 152.493 159.785 150.665 161.094 148.725C162.418 146.765 163.556 144.669 164.477 142.494C165.413 140.282 166.13 137.967 166.613 135.614C167.107 133.199 167.358 130.709 167.358 128.214C167.358 125.719 167.107 123.23 166.613 120.815C166.131 118.461 165.413 116.146 164.476 113.933C163.557 111.759 162.418 109.662 161.095 107.702C159.783 105.762 158.276 103.934 156.611 102.268C154.952 100.61 153.124 99.101 151.177 97.785L156.777 89.5C159.25 91.172 161.573 93.089 163.682 95.199C165.797 97.315 167.714 99.638 169.38 102.105C171.066 104.599 172.515 107.268 173.686 110.037C174.879 112.859 175.795 115.81 176.41 118.809C177.039 121.882 177.358 125.046 177.358 128.214C177.358 131.382 177.039 134.546 176.41 137.619C175.796 140.618 174.88 143.569 173.687 146.39C172.514 149.161 171.066 151.83 169.38 154.325C167.715 156.789 165.797 159.113 163.683 161.229C161.573 163.338 159.25 165.255 156.777 166.927Z"
              fill={iconColor}
            />
            <path
              d="M173.231 190.921L167.631 182.636C171.135 180.268 174.429 177.55 177.421 174.559C180.412 171.568 183.13 168.273 185.498 164.769C187.887 161.233 189.937 157.455 191.592 153.54C193.28 149.552 194.575 145.379 195.444 141.136C196.335 136.778 196.788 132.292 196.788 127.804C196.788 123.317 196.335 118.831 195.444 114.473C194.576 110.231 193.28 106.057 191.593 102.069C189.937 98.153 187.887 94.376 185.497 90.839C183.13 87.335 180.412 84.041 177.421 81.051C174.426 78.056 171.133 75.338 167.632 72.974L173.23 64.687C177.258 67.408 181.047 70.534 184.492 73.98C187.933 77.419 191.06 81.209 193.784 85.241C196.535 89.314 198.897 93.664 200.804 98.174C202.748 102.769 204.242 107.578 205.242 112.469C206.268 117.485 206.789 122.645 206.789 127.804C206.789 132.964 206.268 138.123 205.242 143.14C204.241 148.032 202.748 152.841 200.804 157.436C198.899 161.944 196.537 166.294 193.786 170.368C191.061 174.401 187.934 178.188 184.493 181.63C181.052 185.072 177.263 188.197 173.231 190.921Z"
              fill={iconColor}
            />
            <path
              d="M190.37 216.75L184.772 208.463C189.896 205.002 194.716 201.025 199.098 196.644C203.479 192.263 207.455 187.443 210.917 182.318C214.412 177.145 217.414 171.615 219.839 165.881C222.309 160.044 224.204 153.935 225.476 147.723C226.781 141.347 227.443 134.784 227.443 128.213C227.443 121.643 226.781 115.079 225.476 108.704C224.205 102.492 222.309 96.382 219.839 90.544C217.414 84.812 214.412 79.281 210.917 74.107C207.453 68.98 203.477 64.161 199.098 59.781C194.718 55.402 189.899 51.426 184.772 47.961L190.371 39.676C196.025 43.496 201.341 47.882 206.169 52.711C210.998 57.539 215.384 62.854 219.204 68.51C223.06 74.218 226.373 80.32 229.05 86.648C231.777 93.095 233.87 99.841 235.274 106.699C236.713 113.731 237.444 120.971 237.444 128.213C237.444 135.455 236.714 142.694 235.274 149.728C233.871 156.586 231.777 163.332 229.05 169.777C226.374 176.106 223.061 182.209 219.204 187.916C215.386 193.568 211 198.884 206.169 203.715C201.337 208.546 196.021 212.932 190.37 216.75Z"
              fill={iconColor}
            />
            <path
              d="M109 71V185.262L72.615 151.448L69.812 149H65.869H28V107H66.119H70.076L72.882 104.532L109 70.807V71ZM114.214 54.019C113.007 54.019 111.817 54.235 110.828 55.252L66.119 97H18V159H65.869L110.876 200.794C111.864 201.805 113.011 202.135 114.214 202.135C116.696 202.135 119 200.089 119 197.174V58.857C119 55.941 116.695 54.019 114.214 54.019Z"
              fill={iconColor}
            />
          </>
        )}
      </g>
    </SvgIcon>
  );
};
export default Speaker;
