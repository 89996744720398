import { t } from 'ttag';
import NotificationType from './notificationType';

/**
 * This function returns for every notification type the corresponding notification description
 */
const getDescription = (notificationType: NotificationType, displayName: string, missionName: string | undefined) => {
  switch (notificationType as NotificationType) {
    case NotificationType.MissionStateCompleted:
      return t`${displayName} has completed the mission ${missionName}`;
    case NotificationType.MissionStateFailed:
      return t`${displayName} failed to complete the mission ${missionName}`;
    case NotificationType.UnexpectedOffline:
      return t`${displayName} has unexpectedly gone offline`;
    default:
      return t`Invalid notification type ${notificationType}`;
  }
};
export default getDescription;
