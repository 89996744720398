enum NotificationType {
  MissionStateCompleted = 0,
  MissionStateFailed = 1,
  UnexpectedOffline = 2,
}
export default NotificationType;
export interface INotification {
  id: string;
  vehicleId: string;
  notificationType: NotificationType;
  displayName: string;
  missionName: string | undefined;
  visible: boolean;
  timestamp: Date;
  areaId: string | null;
  read: boolean;
}
