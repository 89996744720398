import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import { useEffect, useMemo } from 'react';
import { combineLatest } from 'rxjs';
import destinationMarkerObservable from '../../model/map/observable/destinationMarker.observable';
import selectedVehicleColorObservable from '../../model/map/observable/selectedVehicleColor.observable';
import { offline } from '../../model/vehicle/state/getVehicleStateColor';
import { confirmationIcon, createMarkerStyle, getRadiansBetweenPoints } from './Map.helpers';

const useDestinationMarkerLayer = (missionLabel: string) => {
  const source = useMemo(() => new VectorSource(), []);
  const layer = useMemo(() => new VectorLayer({ source }), [source]);

  useEffect(() => {
    const subscription = combineLatest([destinationMarkerObservable, selectedVehicleColorObservable]).subscribe(
      ([{ future }, selectedVehicleColor]) => {
        source.clear();
        if (
          missionLabel === 'Click and Drive' &&
          selectedVehicleColor &&
          selectedVehicleColor !== offline &&
          future.length
        ) {
          const { coordinates } = future[future.length - 1].geometry;
          const sourceLon = coordinates[0][0];
          const sourceLat = coordinates[0][1];
          const destinationLon = coordinates[coordinates.length - 1][0];
          const destinationLat = coordinates[coordinates.length - 1][1];
          const headingInRadians = getRadiansBetweenPoints(
            fromLonLat([sourceLon, sourceLat]),
            fromLonLat([destinationLon, destinationLat]),
          );

          const target = new Feature({ name: 'cndDestinationMarker', id: 10 });

          target.setGeometry(new Point(fromLonLat([destinationLon, destinationLat])));
          target.setStyle(createMarkerStyle([0.5, 0.58], confirmationIcon, 1.0, headingInRadians));

          source.addFeature(target);
        }
      },
    );

    return () => subscription.unsubscribe();
  }, [source, missionLabel]);

  return layer;
};

export default useDestinationMarkerLayer;
