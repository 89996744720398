import { red } from '../../theme/color';

export const Error = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.4997C5.85662 2.4997 2.49775 5.85857 2.49775 10.002C2.49775 14.1453 5.85662 17.5042 10 17.5042C14.1434 17.5042 17.5023 14.1453 17.5023 10.002C17.5023 5.85857 14.1434 2.4997 10 2.4997ZM1.25 10.002C1.25 5.16946 5.16751 1.25195 10 1.25195C14.8325 1.25195 18.75 5.16946 18.75 10.002C18.75 14.8344 14.8325 18.752 10 18.752C5.16751 18.752 1.25 14.8344 1.25 10.002Z"
      fill={red}
    />
    <path
      d="M9.25175 9.044V6.25H10.7367V9.044L10.5167 11.728H9.47175L9.25175 9.044ZM9.21875 12.564H10.7807V14.06H9.21875V12.564Z"
      fill={red}
    />
  </svg>
);

export default Error;
