import { useKeycloak } from '@react-keycloak/web';
import { PropsWithChildren, ReactNode, createElement, Fragment } from 'react';

const PrivateRoute = ({ children }: PropsWithChildren<ReactNode>) => {
  const { keycloak } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  if (isLoggedIn) {
    return createElement(Fragment, null, children);
  }

  return null;
};

export default PrivateRoute;
