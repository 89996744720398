import {
  canSendMission,
  EquipmentMode,
  useActionLists,
  useMapFiles,
  useMissions,
  useMissionStates,
  usePoi,
  canReleaseImpersonal,
  sendRelease,
  sendReleasePersonal,
  OperationalState,
  doLog,
  LogEntryType,
  LogLevel,
} from '@ats/graphql';
import type { ActionList, MissionInput, Mission, Control } from '@ats/graphql';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { useObservable } from '@libreact/use-observable';
import clsx from 'clsx';
import { memo, useState, useEffect } from 'react';
import { IUserAttributes } from '../../model/user';
import Events from '../../model/events';

import { MissionView } from '../../model/ViewTypes';
import type IVehicle from '../../model/vehicle/IVehicle';
import isInStandDown from '../../model/vehicle/stand-down/isInStandDown';
import type IVehicleStateColor from '../../model/vehicle/state/IVehicleStateColor';
import Progress from '../../send/Progress';
import useProgress from '../../send/useProgress';
import { mapInteractionModule as mapInteractionModuleObservable, previewMissionInput } from '../../model/observables';
import getMissionOverview from '../../model/mission/overview/getMissionOverview';
import getModeLabel from '../../model/vehicle/state/getModeLabel';
import getUnfinishedMissionState from '../../model/mission/overview/getUnfinishedMissionState';
import getMissionWarning from '../../model/mission/overview/getMissionWarning';

import DefaultView from './DefaultView';
import ClickAndDriveView from './ClickAndDriveView';
import SelectMission from './SelectMission';
import PreviewMissionContainer from './PreviewMission.container';
import ActiveMissionContainer from './ActiveMission.container';
import MissionStateOverview from './MissionStateOverview';

import isInStandDownImpersonal from '../../model/vehicle/stand-down/isInStandDownImpersonal';
import isInStandDownPersonalByUser from '../../model/vehicle/stand-down/isInStandDownPersonalByUser';
import { blue400, blue500, blue600, white, grey868 } from '../../theme/color';
import { getButtonTextRelease } from '../../send/getButtonText';
import getMissionDisplayName from '../../model/mission/getMissionDisplayName';

export const useStyles = makeStyles(
  {
    cardActions: {
      padding: '0 24px 24px',
    },
    button: {
      padding: '20px',
      textTransform: 'none',
    },
    buttonToIdle: {
      border: 'none',
      backgroundColor: blue600,
      color: white,
      '&:hover': {
        backgroundColor: blue500,
        border: 'none',
      },
      '&:focus': {
        backgroundColor: blue400,
        border: 'none',
      },
      '&:disabled': {
        background: grey868,
        color: white,
        opacity: 0.38,
      },
    },
    buttonToActive: {
      backgroundColor: blue600,
      color: white,
      '&:hover': {
        backgroundColor: blue500,
        border: 'none',
      },
      '&:focus': {
        background: blue400,
        border: 'none',
      },
      '&:disabled': {
        background: grey868,
        color: white,
        opacity: 0.38,
      },
    },
    circularProgress: {
      color: 'inherit',
      marginRight: '20px',
    },
  },
  { index: 1 },
);

interface IProps {
  areaId: string;
  clearingProgress: Progress | null;
  externalEquipmentReference: string;
  missionViewContextState: [MissionView, React.Dispatch<React.SetStateAction<MissionView>>];
  setMissionLabel: React.Dispatch<React.SetStateAction<string>>;
  releasingProgress: Progress | null;
  standingDownProgress: Progress | null;
  missionStateCardOverview: [string[], (id: string) => void];
  startClearing: (request: Promise<unknown> | null) => void;
  startReleasing: (request: Promise<unknown> | null) => void;
  startStandingDown: (request: Promise<unknown> | null) => void;
  user: IUserAttributes;
  vehicle: IVehicle;
  vehicleColor: IVehicleStateColor;
  vehicleMode: EquipmentMode | null;
  setRenderButton: (render: boolean) => void;
  darkMode: boolean;
  dispatcherRight: boolean;
}

const MissionWidget = (props: IProps) => {
  const {
    areaId,
    clearingProgress,
    externalEquipmentReference,
    missionViewContextState,
    setMissionLabel,
    releasingProgress,
    setRenderButton,
    standingDownProgress,
    startClearing,
    startReleasing,
    startStandingDown,
    user,
    vehicle,
    vehicleColor,
    vehicleMode,
    missionStateCardOverview,
    darkMode,
    dispatcherRight,
  } = props;

  const [actionLists] = useActionLists({ areaId });
  const [mapFiles] = useMapFiles({ areaId });
  const [missions] = useMissions({ areaId, externalEquipmentReference });
  const [states] = useMissionStates({ externalEquipmentReference });
  const [pois] = usePoi({ areaId });
  const [mapInteractionModule] = useObservable(mapInteractionModuleObservable);
  const [previewMissionId, setPreviewMissionId] = useState<string | null>(null);
  const [actionList, setActionList] = useState<ActionList | null>(null);
  const [actions, setActions] = useState<MissionInput['actions'] | null>(null);
  const [tags, setTags] = useState<MissionInput['tags'] | null>(null);
  const [dispatchingProgress, startDispatching] = useProgress(externalEquipmentReference);
  const [missionViewContext, setMissionViewContext] = missionViewContextState;
  const missionOverview = getMissionOverview(actionLists, missions, pois, states);
  const unfinishedMissionState = getUnfinishedMissionState(states);
  const missionWarning = getMissionWarning(states);
  const [dismissedIds, setDismissedId] = missionStateCardOverview;
  const unfinishedMission =
    unfinishedMissionState && dismissedIds.find((id) => unfinishedMissionState.id === id) === undefined
      ? unfinishedMissionState
      : null;
  const missionOverviewLoaded = !!(missions && pois && states);
  const hasMission = !!missionOverview;
  const latestMissionId = states && states[0] ? states[0].missionId : null;

  const inStandDown = isInStandDown(vehicle);
  const inStandDownImpersonal = isInStandDownImpersonal(vehicle);
  const inStandDownPersonalByUser = isInStandDownPersonalByUser(vehicle, user);
  const mapVersion = mapFiles?.[0]?.version || null;
  const canAssignMission = !!(!inStandDown && mapVersion && canSendMission(user));
  const canSelectMission = !!(actionLists?.length && mapVersion && canSendMission(user));
  const canRelease = inStandDownPersonalByUser || (inStandDownImpersonal && canReleaseImpersonal(user));
  const isReleasing = releasingProgress === Progress.IN_PROGRESS || releasingProgress === Progress.VERY_SLOW;
  const isDispatching = dispatchingProgress === Progress.IN_PROGRESS || dispatchingProgress === Progress.VERY_SLOW;
  const failDispatching = dispatchingProgress === Progress.FAILED;
  const isLeavingStandDown = vehicle.operationalState === OperationalState.OPERATIONAL_STATE_LEAVING_STAND_DOWN;
  const showDisabledReleaseButton = isReleasing || isLeavingStandDown || (inStandDown && !canRelease);

  const vehicleDisplayName = vehicle.displayName;
  const { button, buttonToIdle, buttonToActive, circularProgress } = useStyles();

  useEffect(() => {
    if (mapInteractionModule !== 'DEFAULT') {
      setMissionViewContext(MissionView.ClickAndDriveView);
    } else if (missionOverviewLoaded) {
      // Check for unfinished missions first as the vehicle might still have a/the mission assigned
      if (hasMission && missionOverview) {
        setMissionViewContext(MissionView.ActiveMission);
        setMissionLabel(missionOverview.label);
      } else if (isDispatching || failDispatching) {
        setMissionViewContext(MissionView.PreviewMission);
      } else if (unfinishedMission) {
        setMissionViewContext(MissionView.MissionState);
      } else {
        setMissionViewContext(MissionView.DefaultView);
        setMissionLabel('No Mission');
      }
    } else {
      setMissionViewContext(MissionView.Loading);
    }
  }, [
    hasMission,
    missionOverviewLoaded,
    externalEquipmentReference,
    mapInteractionModule,
    isDispatching,
    failDispatching,
    unfinishedMission,
    missionOverview,
    setMissionLabel,
    setMissionViewContext,
  ]);
  // Effectively an "unmount" reset of mission state
  useEffect(() => {
    return () => setMissionViewContext(MissionView.Loading);
  }, [setMissionViewContext]);

  useEffect(() => {
    if (!(missionViewContext === MissionView.PreviewMission || missionViewContext === MissionView.SelectMission)) {
      // It's a view that does not contain a preview, so reset it
      setPreviewMissionId(null);
    }
  }, [missionViewContext]);

  useEffect(() => {
    if (previewMissionId === null) previewMissionInput.next(null);
  }, [previewMissionId]);

  useEffect(() => {
    if (!actions || !actionList || !mapVersion || !externalEquipmentReference) return;
    if (previewMissionId) {
      const missionInput = {
        actions,
        externalEquipmentReference,
        mapVersion,
        actionListId: previewMissionId,
        tags,
      };
      previewMissionInput.next(missionInput);
    } else {
      previewMissionInput.next(null);
    }
  }, [externalEquipmentReference, actionList, actions, mapVersion, previewMissionId, missionViewContext, tags]);

  const dataMismatch = (equipment: Mission) => equipment.externalEquipmentReference !== externalEquipmentReference;
  if (missions?.find((x) => dataMismatch(x))) {
    return null;
  }

  const clear = () => {
    startDispatching(null);
    setMissionViewContext(MissionView.DefaultView);
  };

  const release = () => {
    const promises: Promise<Control | unknown>[] = [];

    if (inStandDownPersonalByUser) {
      promises.push(sendReleasePersonal(externalEquipmentReference));
    }
    if (inStandDownImpersonal && canReleaseImpersonal(user)) {
      promises.push(sendRelease(externalEquipmentReference));
    }

    const promise = Promise.all(promises);

    startReleasing(promise);

    promise
      .then(() =>
        doLog(LogLevel.Debug, {
          type: LogEntryType.UI,
          externalEquipmentReference,
          message: `Release ${vehicle.displayName}`,
        }),
      )
      .catch((e) => {
        const message = e === 'No current user' ? 'Unauthenticated, please log in again' : null;
        const detail = { title: `Failed release command`, message };
        const event = new CustomEvent(Events.TOAST, { detail });
        window.dispatchEvent(event);
        doLog(LogLevel.Warning, {
          type: LogEntryType.UI,
          externalEquipmentReference,
          message: `Release ${vehicle.displayName} failed`,
        });
      });

    return promise;
  };

  const vehicleModeLabel = getModeLabel(vehicleMode);

  const ReleaseComponent = () =>
    canRelease || showDisabledReleaseButton ? (
      <Button
        className={clsx(button, missionOverview ? buttonToActive : buttonToIdle, 'sdds-detail-02')}
        disabled={showDisabledReleaseButton || !dispatcherRight}
        fullWidth
        disableRipple
        onClick={release}
        data-testid="release-vehicle-button"
      >
        {isReleasing && <CircularProgress className={circularProgress} size="3.5rem" />}
        {getButtonTextRelease(releasingProgress)}
      </Button>
    ) : null;

  switch (+missionViewContext) {
    case MissionView.Loading: {
      return null;
    }

    case MissionView.DefaultView: {
      const assignMission = () => {
        setMissionViewContext(MissionView.SelectMission);
      };
      return (
        <DefaultView
          canAssignMission={!!canAssignMission}
          setRenderButton={setRenderButton}
          renderReleaseComponent={ReleaseComponent}
          assignMission={assignMission}
          vehicleMode={vehicleModeLabel}
          dispatcherRight={dispatcherRight}
        />
      );
    }

    case MissionView.ClickAndDriveView: {
      return <ClickAndDriveView mapInteractionModule={mapInteractionModule} />;
    }

    case MissionView.SelectMission: {
      const selectMission = (id: string, hoverMissionId: string | null) => {
        if (id !== hoverMissionId) {
          previewMissionInput.next(null);
        }
        setPreviewMissionId(id);
        setMissionViewContext(MissionView.PreviewMission);
      };
      return (
        <SelectMission
          setActions={setActions}
          setTags={setTags}
          setActionList={setActionList}
          canSelectMission={!!canSelectMission}
          inStandDown={!!inStandDown}
          setRenderButton={setRenderButton}
          renderReleaseComponent={ReleaseComponent}
          actionLists={actionLists}
          clear={clear}
          selectMission={selectMission}
          vehicleMode={vehicleModeLabel}
          setPreviewMissionId={setPreviewMissionId}
          darkMode={darkMode}
          dispatcherRight={dispatcherRight}
        />
      );
    }

    case MissionView.PreviewMission: {
      return (
        <PreviewMissionContainer
          canDispatchtMission={!!canAssignMission}
          setRenderButton={setRenderButton}
          renderReleaseComponent={ReleaseComponent}
          areaId={areaId}
          actionLists={actionLists}
          clear={clear}
          dispatchingProgress={dispatchingProgress}
          externalEquipmentReference={externalEquipmentReference}
          mapVersion={mapVersion}
          previewMissionId={previewMissionId}
          setActions={setActions}
          setTags={setTags}
          setActionList={setActionList}
          startDispatching={startDispatching}
          vehicleMode={vehicleModeLabel}
          vehicleDisplayName={vehicleDisplayName}
          dispatcherRight={dispatcherRight}
        />
      );
    }

    case MissionView.ActiveMission: {
      return (
        <ActiveMissionContainer
          makeSpaceForReleaseButton={canRelease || showDisabledReleaseButton}
          setRenderButton={setRenderButton}
          renderReleaseComponent={ReleaseComponent}
          clearingProgress={clearingProgress}
          dispatchingProgress={dispatchingProgress}
          missionWarning={missionWarning}
          externalEquipmentReference={externalEquipmentReference}
          missionOverview={missionOverview}
          releasingProgress={releasingProgress}
          standingDownProgress={standingDownProgress}
          startClearing={startClearing}
          startStandingDown={startStandingDown}
          user={user}
          vehicle={vehicle}
          vehicleColor={vehicleColor}
        />
      );
    }
    case MissionView.MissionState: {
      return (
        <MissionStateOverview
          missionStateOverview={unfinishedMission}
          dismissCard={() => {
            if (!unfinishedMission) return;
            setDismissedId(unfinishedMission.id);
          }}
          title={getMissionDisplayName(latestMissionId, missions, actionLists)}
          setRenderButton={setRenderButton}
        />
      );
    }
    default:
      return <div>View missing</div>;
  }
};

export default memo(MissionWidget);
