import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { t } from 'ttag';
import { grey300, grey868, red, yellow, green, white } from '../../theme/color';

interface IStyleProps {
  borderTopColor: string;
}

const useStyles = makeStyles(
  {
    card: {
      borderTop: (props: IStyleProps) => `4px solid ${props.borderTopColor}`,
      boxShadow: 'none',
      background: grey868,
      width: '320px',
      borderRadius: '4px',
    },
  },
  { index: 1 },
);

enum VehicleStateLevel {
  Info,
  Warning,
  Error,
}

export enum VehicleStateType {
  OnlineEquipmentStatusMismatch, // Vehicle is reported as on-line but we have no up to data equipment status - to be used in CAV-60926
  MissingEquipmentStatus, // Vehicle is completely missing any equipment status
}

interface IProps {
  type: VehicleStateType;
}

interface IVehicleStateInfo {
  title: string;
  description: string;
  level: VehicleStateLevel;
}

const titleAndDescription: Record<VehicleStateType, IVehicleStateInfo> = {
  [VehicleStateType.OnlineEquipmentStatusMismatch]: {
    title: t`No vehicle data`,
    description: t`Vehicle appears to be online but no vehicle data is being received and updated.`,
    level: VehicleStateLevel.Error,
  },
  [VehicleStateType.MissingEquipmentStatus]: {
    title: t`No vehicle data`,
    description: t`No historical vehicle data has been received from the vehicle (including vehicle position).`,
    level: VehicleStateLevel.Error,
  },
};

const levelBorderTopMap: Record<VehicleStateLevel, string> = {
  [VehicleStateLevel.Info]: green,
  [VehicleStateLevel.Warning]: yellow,
  [VehicleStateLevel.Error]: red,
};

const VehicleStateCard = ({ type }: IProps) => {
  const { title, description, level } = titleAndDescription[type];
  const { card } = useStyles({ borderTopColor: levelBorderTopMap[level] });

  return (
    <div className={clsx(card, 'sdds-card')}>
      <div className="sdds-card-header">
        <h6 className="sdds-card-headline" style={{ color: white }}>
          {title}
        </h6>
      </div>
      <div className="sdds-card-body" style={{ color: grey300 }}>
        {description}
      </div>
    </div>
  );
};
export default VehicleStateCard;
