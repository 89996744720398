import { Card, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { dimWhite, grey868, red, white } from '../../theme/color';
import theme from '../../theme/theme';
import { Error } from '../icons/Error';

export const useStyles = makeStyles(
  {
    box: {
      paddingLeft: '10px',
    },
    card: {
      boxShadow: 'none',
      borderRadius: '4px',
      borderLeft: `4px solid ${red}`,
      width: '325px',
      margin: theme.spacing(0, 0, 6, 5),
    },
    cardOuter: {
      backgroundColor: grey868,
    },
    topRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',
    },
    cardHeader: {
      padding: theme.spacing(4, 3.75, 2, 3.75),
    },
    icon: {
      padding: theme.spacing(3.75, 0, 0, 3.75),
    },
    content: {
      padding: theme.spacing(0, 0, 0, 12.5),
      '&:last-child': {
        paddingBottom: theme.spacing(4),
      },
    },
    typography: {
      color: dimWhite,
      padding: theme.spacing(0, 0, 0),
    },
    typographyTitle: {
      color: white,
    },
  },
  { index: 1 },
);

interface IProps {
  description: string;
}
const PoiInputErrorCard = ({ description }: IProps) => {
  const { card, cardOuter, cardHeader, topRow, icon, content, typography, typographyTitle } = useStyles();

  return (
    <Card className={clsx(card, cardOuter)}>
      <div className={topRow}>
        <div className={icon}>
          <Error />
        </div>
        <CardHeader
          className={cardHeader}
          title="Invalid"
          titleTypographyProps={{ className: clsx(typographyTitle, 'sdds-headline-07') }}
        />
      </div>
      <CardContent className={content}>
        <Typography className={clsx(typography, 'sdds-detail-02')}>{description}</Typography>
      </CardContent>
    </Card>
  );
};
export default PoiInputErrorCard;
