import bearing from '@turf/bearing';
import type { Position } from 'geojson';
import type { DeepReadonly } from 'ts-essentials';

const HEADING_THRESHOLD = 25;
const WRONG_DIRECTION_PENALTY = 10;

export function getDirectionPenalty(heading: number, coordinates: DeepReadonly<Position[]>, index: number) {
  if (heading === null) return 0;

  const angle = getAngleDifference(heading, getConvertedBearing(coordinates, index));
  return angle > HEADING_THRESHOLD ? WRONG_DIRECTION_PENALTY : 0;
}

export const getConvertedBearing = (coordinates: DeepReadonly<Position[]>, index: number): number => {
  const unconverted = bearing(
    coordinates[index && index - 1] as number[],
    coordinates[index < coordinates.length - 1 ? index + 1 : index] as number[],
  );

  // bearing is in -180 to 180 format, this is in 0 to 360 format
  return convertBetweenStandards(unconverted);
};

export function getAngleDifference(a: number, b: number) {
  const first = Math.abs(a - b);
  const second = Math.abs(a - 360 - b);
  const third = Math.abs(a - (b - 360));
  return Math.min(first, second, third);
}

export function convertBetweenStandards(degree: number) {
  if (degree >= 0) {
    return degree;
  }
  return 360 + degree;
}
