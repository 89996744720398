import { ChangeEvent, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Grid } from '@material-ui/core';
import { grey100, grey500, grey900 } from '../../theme/color';

import TelemetryGrid from './telemetry/TelemetryGrid';

import theme from '../../theme/theme';
import TriggerLog from './developerTab/TriggerLog';
import EquipmentEventsLog from './equipmentEventsLog/EquipmentEventsLog';

interface IProps {
  areaId: string | null;
  externalEquipmentReference: string | null;
}

interface IStyleProps {
  isScrollable: boolean;
}

const useStyles = makeStyles(
  {
    root: {
      zIndex: 1300,
      overflow: 'hidden',
    },
    tabs: {
      color: grey500,
      '& button:focus': { color: grey100 },
      margin: theme.spacing(0, 0, 4, 0),
      height: '64px',
      '& .MuiTabs-flexContainer': {
        height: '64px',
      },
    },
    scrollContainer: {
      '&::-webkit-scrollbar': {
        color: 'transparent',
        width: '4px',
      },
      '&:hover': {
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          backgroundColor: grey900,
        },
      },
      height: 'calc(100% - 84px)',
      display: 'flex',
      flexDirection: 'column',
      overflowY: (props: IStyleProps) => (props.isScrollable ? 'auto' : 'hidden'),
      overflowX: 'hidden',
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 1,
    },
  },
  { index: 1 },
);

const InformationCluster = (props: IProps) => {
  const { areaId, externalEquipmentReference } = props;
  const [value, setValue] = useState(0);

  const handleChange = (_event: ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };

  const isTelemetryTab = value === 0;
  const isEquipmentEventsLogTab = value === 1;
  const isDeveloperTab = value === 2;

  const { root, tabs, scrollContainer } = useStyles({ isScrollable: !isEquipmentEventsLogTab });

  return (
    <div className={root}>
      <Tabs value={value} onChange={handleChange} aria-label="vehicle information" className={tabs}>
        <Tab label="Telemetry" disableRipple data-testid="telemetry-tab" />
        <Tab label="Vehicle log" disableRipple data-testid="equipment-events-log-tab" />
        <Tab label="Developer tab" disableRipple data-testid="developer-web-tab" />
      </Tabs>
      <div className={scrollContainer}>
        {isTelemetryTab && externalEquipmentReference && (
          <TelemetryGrid areaId={areaId} externalEquipmentReference={externalEquipmentReference} />
        )}
        {isEquipmentEventsLogTab && externalEquipmentReference && (
          <EquipmentEventsLog externalEquipmentReference={externalEquipmentReference} />
        )}
        {isDeveloperTab && (
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key="trigger-log-event">
              <TriggerLog externalEquipmentReference={externalEquipmentReference} />
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default InformationCluster;
