import { getEquipmentStatuses, getConnectivityStatus } from '@ats/graphql';
import { of, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import getVehicleStateColor from '../../vehicle/state/getVehicleStateColor';
import { selectedVehicleId } from '../../observables';

const selectedVehicleColorObservable = selectedVehicleId.pipe(
  switchMap((externalEquipmentReference) =>
    externalEquipmentReference
      ? combineLatest([
          getEquipmentStatuses({ externalEquipmentReference }),
          getConnectivityStatus({ externalEquipmentReference }),
        ]).pipe(
          map(([equipmentStatuses, connectivity]) => {
            const operationalState = equipmentStatuses[0]?.operationalState || null;
            const mode = equipmentStatuses[0]?.mode || null;
            const status = connectivity[0]?.status || 'offline';
            return getVehicleStateColor(operationalState, mode, status);
          }),
        )
      : of(getVehicleStateColor(null, null, 'offline')),
  ),
);

export default selectedVehicleColorObservable;
