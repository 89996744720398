import { omit } from 'ramda';
import {
  EquipmentStatus_position as EquipmentStatusPosition,
  EquipmentStatus_releaseConditions as EquipmentStatusReleaseConditions,
} from '@ats/graphql';
import IVehicle from './vehicle/IVehicle';
import { IWithEquipmentProps } from '../components/withEquipment';

type ShallowEqualType =
  | EquipmentStatusPosition
  | EquipmentStatusReleaseConditions
  | Omit<IWithEquipmentProps, 'equipmentStatuses'>;

const isEqualShallow = (a: ShallowEqualType, b: ShallowEqualType) => {
  let differenceFound = false;
  Object.entries(a).forEach(([key, value]) => {
    if (value !== Object(b)[key]) {
      differenceFound = true;
    }
  });
  return !differenceFound;
};

export const identicalIVehicle = (vehicleA: Omit<IVehicle, 'timestamp'>, vehicleB: Omit<IVehicle, 'timestamp'>) => {
  let differenceFound = false;
  Object.keys(vehicleA).forEach((key: string) => {
    if (['position', 'releaseConditions', 'timestamp', 'dispatcher'].includes(key)) {
      // These are the 'special' cases, either deep structure or timestamp
      switch (key) {
        case 'position':
          if (
            vehicleA.position !== null &&
            vehicleB.position !== null &&
            !isEqualShallow(vehicleA.position, vehicleB.position)
          ) {
            differenceFound = true;
          }
          break;
        case 'releaseConditions':
          vehicleA.releaseConditions?.forEach((condition, index) => {
            const comparee = vehicleB.releaseConditions?.[index];
            if (comparee && condition && !isEqualShallow(condition, comparee)) {
              differenceFound = true;
            }
          });
          break;
        case 'dispatcher':
          if (vehicleA.dispatcher?.systemName !== vehicleB.dispatcher?.systemName) {
            differenceFound = true;
          }
          break;
        default:
          break;
      }
    } else if (Object(vehicleA)[key] !== Object(vehicleB)[key]) {
      // Shallow comparing of current key
      differenceFound = true;
    }
  });
  return !differenceFound;
};

interface IProps extends IWithEquipmentProps {
  snapState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  showPoiName?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  showVehicleName?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  selectedPoiState?: [string | null, (s: string | null) => void];
  hoverEquipmentState?: [string | null, (s: string | null) => void];
  selectedEquipmentState?: [string | null, (s: string | null) => void];
}

export const isEqualOmitEquipmentTimestamps = <T extends IProps>(prevProps: T, props: T) => {
  const prevLenght = prevProps.equipmentStatuses && prevProps.equipmentStatuses.length;
  const currentLength = props.equipmentStatuses && props.equipmentStatuses.length;

  if (prevLenght !== currentLength) {
    // One is null and the other is an array (common at loading) or they have different amounts of vehicles
    return false;
  }

  let differenceFound = false;
  const prevVehicles = prevProps.equipmentStatuses;
  const vehicles = props.equipmentStatuses;
  if (prevVehicles && vehicles) {
    prevVehicles.forEach((vehicle, idx) => {
      const prevOmitVehicle = omit(['timestamp'], vehicle);
      const currentOmitVehicle = omit(['timestamp'], vehicles[idx]);
      if (!identicalIVehicle(prevOmitVehicle, currentOmitVehicle)) {
        differenceFound = true;
      }
    });
  }

  const propsWithoutEquipmentStatusesA = omit(['equipmentStatuses'], prevProps);
  const propsWithoutEquipmentStatusesB = omit(['equipmentStatuses'], props);
  if (!isEqualShallow(propsWithoutEquipmentStatusesA, propsWithoutEquipmentStatusesB)) {
    differenceFound = true;
  }

  return !differenceFound;
};
