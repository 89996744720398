import { EquipmentStatus } from '@ats/graphql';
import 'ol/ol.css';
import { Map as OlMap } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import Cluster from 'ol/source/Cluster';
import Geometry from 'ol/geom/Geometry';
import OlTileLayer from 'ol/layer/Tile';
import OlSourceXYZ from 'ol/source/XYZ';
import { defaults as controlDefaults, ScaleLine } from 'ol/control';

import { defaults as defaultInteractions, MouseWheelZoom } from 'ol/interaction';

import Events from '../../model/events';
import { topLayerZIndex } from './constants';
import createOLVehicleFeature from './createOLVehicleFeature';

import { displayVehicleLabelSetting as displayVehicleLabelSettingObservable } from '../../model/observables';
import { createTrackingFeature } from './useTrackerLabelsLayer';
import zoomAndCenterToContent from './zoomAndCenterToContent';
import { defaultMapInteractions, defaultMapClick } from './defaultMapInteractions';
import {
  pointerMove as setPosition,
  click as positionClick,
  addSource as addSourcePosition,
} from './modules/PositionModule';
import {
  poiPointerMove as setPoiPosition,
  click as poiPositionClick,
  addPoiSource as addPoiSourcePosition,
} from './modules/PoiPositionModule';
import {
  rotationFeatures as setRotation,
  click as rotationClick,
  addSource as addSourceRotation,
} from './modules/RotationModule';
import {
  rotationFeatures as setPoiRotation,
  click as poiRotationClick,
  addPoiSource as addPoiSourceRotation,
} from './modules/PoiRotationModule';
import { addSource as addSourceConfirmation } from './modules/ConfirmationModule';

interface IConfig {
  container: HTMLDivElement;
  setHoverEquipmentId: (id: string | null) => void;
  setSelectedEquipmentId: (id: string | null) => void;
  setSelectedPoiId: (id: string | null) => void;
  setSelectedZone: (arg0: string, arg1: string) => void;
  siteMapLayer: VectorLayer<VectorSource<Geometry>>;
  poiLayer: VectorLayer<Cluster>;
  queueAndPaddockLayer: VectorLayer<VectorSource<Geometry>>;
  vehicleRouteLayer: VectorLayer<VectorSource<Geometry>>;
  destinationMarkerLayer: VectorLayer<VectorSource<Geometry>>;
  clickAndDriveLayer: VectorLayer<VectorSource<Geometry>>;
  trackerLayer: VectorLayer<VectorSource<Geometry>>;
  satelliteLayerVisible: boolean;
}

interface IResponse {
  map: OlMap;
  vehicleLayer: VectorLayer<VectorSource<Geometry>>;
  poiLayer: VectorLayer<Cluster>;
  mouseWheelZoom: MouseWheelZoom;
  queueAndPaddockLayer: VectorLayer<VectorSource<Geometry>>;
  setSatelliteLayerVisibility: (visible: boolean) => void;
}
export const addBaseLayer = (type: string) => {
  const baseLayerSource = new OlSourceXYZ({
    url: 'https://a.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
  });
  const satelliteLayerSource = new OlSourceXYZ({
    maxZoom: 17,
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  });
  const tileLayer = new OlTileLayer({
    opacity: 0.7,
    preload: 8,
    source: type === 'base' ? baseLayerSource : satelliteLayerSource,
    visible: true,
    zIndex: 0,
  });
  tileLayer.setProperties({ name: 'base-layer' });
  return tileLayer;
};

export function createMap(config: IConfig) {
  const {
    container,
    setHoverEquipmentId,
    setSelectedEquipmentId,
    setSelectedPoiId,
    setSelectedZone,
    siteMapLayer,
    poiLayer,
    queueAndPaddockLayer,
    vehicleRouteLayer,
    destinationMarkerLayer,
    clickAndDriveLayer,
    trackerLayer,
    satelliteLayerVisible,
  } = config;

  const vehicleLayer = new VectorLayer({
    source: new VectorSource(),
    updateWhileAnimating: true,
    updateWhileInteracting: true,
    renderBuffer: 50000000,
  });
  const multiPurposeIconLayer = new VectorLayer({
    source: new VectorSource(),
    updateWhileAnimating: true,
    updateWhileInteracting: true,
  });
  const view = new View({
    zoom: 100,
  });

  const scaleLine = new ScaleLine({
    units: 'metric',
  });
  const mouseWheelZoom = new MouseWheelZoom({ useAnchor: false });

  const map = new OlMap({
    interactions: defaultInteractions().extend([mouseWheelZoom]),
    controls: controlDefaults({
      zoom: false,
    }).extend([scaleLine]),
    target: container,
    layers: [
      siteMapLayer,
      poiLayer,
      queueAndPaddockLayer,
      vehicleRouteLayer,
      destinationMarkerLayer,
      multiPurposeIconLayer,
      clickAndDriveLayer,
      vehicleLayer,
      trackerLayer,
    ],
    view,
  });

  const clickAndDriveSource = clickAndDriveLayer.getSource();
  addSourcePosition(clickAndDriveSource);
  addSourceRotation(clickAndDriveSource);
  addSourceConfirmation(clickAndDriveSource);

  const selectOnMapSource = multiPurposeIconLayer.getSource();
  addPoiSourcePosition(selectOnMapSource);
  addPoiSourceRotation(selectOnMapSource);

  const interactionConfig = {
    map,
    vehicleLayer,
    poiLayer,
    queueAndPaddockLayer,
    setHoverEquipmentId,
    setSelectedEquipmentId,
    setSelectedPoiId,
    setSelectedZone,
  };

  // Default interactions for map (hover on vehicles and POIs)
  map.on('pointermove', defaultMapInteractions(interactionConfig));

  // Click and drive states
  map.on('pointermove', setPosition);
  map.on('pointermove', setRotation);
  map.on('pointermove', setPoiPosition);
  map.on('pointermove', setPoiRotation);

  map.on('click', (event) => {
    const position = map.getPixelFromCoordinate(event.coordinate);
    window.dispatchEvent(new CustomEvent(Events.MAP_CLICK, { detail: { position } }));
  });
  map.on('click', defaultMapClick(interactionConfig));
  map.on('click', positionClick);
  map.on('click', rotationClick);
  map.on('click', poiPositionClick);
  map.on('click', poiRotationClick);

  map.getLayers().forEach((layer) => layer.setZIndex(topLayerZIndex)); // These layers should be considered "on top"

  const baseLayer = addBaseLayer(satelliteLayerVisible ? 'satellite' : 'base');
  map.addLayer(baseLayer);
  baseLayer.setZIndex(0); // This is the "bottom" layer, always

  zoomAndCenterToContent(map);

  window.addEventListener(Events.MAP_AREA_CHANGED, () => map.updateSize());

  const setSatelliteLayerVisibility = (visible: boolean) => {
    map.getLayers().forEach((layer) => {
      if (layer.getProperties().name === 'base-layer') {
        map.removeLayer(layer);
      }
    });
    const newBaseLayer = addBaseLayer(visible ? 'satellite' : 'base');
    map.addLayer(newBaseLayer);
    newBaseLayer.setZIndex(0);
  };
  const zoomLevel = (level: number) => {
    const currentZoomLevel: number | undefined = view.getZoom();
    if (currentZoomLevel === undefined) return 15;
    return currentZoomLevel + level;
  };
  window.addEventListener(Events.ZOOM_IN, () => view.setZoom(zoomLevel(1)));
  window.addEventListener(Events.ZOOM_OUT, () => view.setZoom(zoomLevel(-1)));

  return new Promise((resolve) => {
    resolve({
      map,
      vehicleLayer,
      mouseWheelZoom,
      poiLayer,
      queueAndPaddockLayer,
      setSatelliteLayerVisibility,
    });
  }) as Promise<IResponse>;
}

interface ICreate {
  vehicleLayer: VectorLayer<VectorSource<Geometry>>;
  trackerLayer: VectorLayer<VectorSource<Geometry>>;
  mapPointer: OlMap;
  equipmentStatuses: EquipmentStatus[];
  clickCallback: () => void;
}

export function createOLVehicleFeatures(config: ICreate) {
  const { vehicleLayer, trackerLayer, equipmentStatuses, clickCallback } = config;
  equipmentStatuses.forEach((eq) => {
    const target = vehicleLayer
      ?.getSource()
      ?.getFeatures()
      ?.find((feature) => feature.getProperties().externalEquipmentReference === eq.externalEquipmentReference);
    if (!target) {
      const vehicleFeature = createOLVehicleFeature(eq.externalEquipmentReference, clickCallback);
      vehicleFeature.getProperties().fadeIn();
      vehicleLayer?.getSource()?.addFeature(vehicleFeature);
      const tracker = createTrackingFeature(2, trackerLayer, vehicleFeature, displayVehicleLabelSettingObservable);
      trackerLayer?.getSource()?.addFeature(tracker);
    }
  });
}

interface IUpdate {
  vehicleLayer: VectorLayer<VectorSource<Geometry>>;
  equipmentStatuses: EquipmentStatus[];
  selectedEquipmentId: string | null;
  hoverEquipmentId: string | null;
}

export function updateOLVehicleFeatures(config: IUpdate) {
  const { vehicleLayer, equipmentStatuses, selectedEquipmentId, hoverEquipmentId } = config;
  if (vehicleLayer === null) return;
  vehicleLayer
    ?.getSource()
    ?.getFeatures()
    .forEach((ft) => {
      const eq = equipmentStatuses.find(
        ({ externalEquipmentReference }) =>
          ft.getProperties().externalEquipmentReference === externalEquipmentReference,
      );
      if (eq) {
        const isSelected = eq.externalEquipmentReference === selectedEquipmentId;
        const isHover = eq.externalEquipmentReference === hoverEquipmentId;
        ft.getProperties().customUpdateSelected(eq, isSelected);
        ft.getProperties().customUpdateHover(eq, isHover);
      } else {
        ft.getProperties().remove(() => vehicleLayer?.getSource()?.removeFeature(ft));

        const detail = { id: ft.getProperties().externalEquipmentReference };
        const event = new CustomEvent(Events.VEHICLE_REMOVED, { detail });
        window.dispatchEvent(event);
      }
    });
}

export function removeOLVehicleFeatures(vehicleLayer: VectorLayer<VectorSource<Geometry>>) {
  vehicleLayer
    ?.getSource()
    ?.getFeatures()
    .forEach((ft) => {
      vehicleLayer?.getSource()?.removeFeature(ft);

      const detail = { id: ft.getProperties().externalEquipmentReference };
      const event = new CustomEvent(Events.VEHICLE_REMOVED, { detail });
      window.dispatchEvent(event);
    });
}
