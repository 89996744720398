import { useEffect, useState } from 'react';
import { useObservable } from '@libreact/use-observable';
import { makeStyles } from '@material-ui/core';

import { transform, fromLonLat, toLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import { Coordinate } from 'ol/coordinate';
import MapBrowserEvent from 'ol/MapBrowserEvent';

import { poiPositionIcon, createMarkerStyle } from '../Map.helpers';
import closestPoint from '../closestPoint';

import {
  mapInteractionModule as mapInteractionModuleObservable,
  poiPosition as poiPositionObservable,
} from '../../../model/observables';

import geoJsonObservable from '../../../model/map/observable/geoJson.observable';

const moduleName = 'POIPOSITION';
const target = new Feature({ name: 'destination', id: 7 });

let source: VectorSource<Geometry> | null = null;
let state: string | null = null;
let mouseCoordinate: Coordinate | null = null;
let features: Feature<Geometry>[] | null = null;

function init() {
  if (!source) return;
  source.addFeature(target);

  geoJsonObservable.subscribe((geoJson) => {
    if (!geoJson) return;

    features = new GeoJSON({
      featureProjection: 'EPSG:3857',
    }).readFeatures(geoJson);
  });
}

function cleanup() {
  if (!source || !target) return;
  source.removeFeature(target);
}

mapInteractionModuleObservable.subscribe((value) => {
  if (state !== value && state === moduleName) {
    cleanup();
  }
  state = value;
  if (state === moduleName) {
    init();
  }
});

export function addPoiSource(_source: VectorSource<Geometry> | null) {
  if (!_source) return;
  source = _source;
}

export function poiPointerMove(event: MapBrowserEvent<PointerEvent>) {
  const { coordinate } = event;
  mouseCoordinate = transform(coordinate, 'EPSG:3857', 'EPSG:4326');
  if (state !== moduleName) return true;

  target.setGeometry(new Point(fromLonLat(mouseCoordinate)));
  target.setStyle(createMarkerStyle([0.5, 0.5], poiPositionIcon, 1, 0));
  poiPositionObservable.next(mouseCoordinate);
  return false;
}

export function click() {
  if (state !== moduleName) return true;
  if (!features || !mouseCoordinate) return true;

  const cP = closestPoint(features, mouseCoordinate);

  // Persist the snapped closest point
  poiPositionObservable.next(toLonLat(cP));

  mapInteractionModuleObservable.next('POIROTATION');

  return false;
}

const useStyles = makeStyles(
  {
    clickAwayRoot: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  { index: 1 },
);

export const ActivePoiPositionContainer = () => {
  const [open, setOpen] = useState(false);
  const [mapInteractionModule] = useObservable(mapInteractionModuleObservable);
  const { clickAwayRoot } = useStyles();

  useEffect(() => {
    if (mapInteractionModule === moduleName) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [mapInteractionModule]);

  return open ? <div className={clickAwayRoot} onClick={() => mapInteractionModuleObservable.next('DEFAULT')} /> : null;
};
