import { useRef, useEffect, memo, useCallback } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { v4 as uuid } from 'uuid';
import { CircularProgress, makeStyles, Theme, createStyles } from '@material-ui/core';
import { t } from 'ttag';
import NoDataAvailablePictogram from '../../pictograms/NoDataAvailable';
import LogTableRow from './LogTableRow';
import { EventsLogRowValue } from './getEventsLogRowValues';
import { grey50 } from '../../../theme/color';

const footerHeight = 60;

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      loadingIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      loadingIndicatorCenter: {
        height: (props: IStyleProps) => `calc(100% - ${props.tableHeadHeight}px)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      footer: {
        height: `${footerHeight}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(4),
        width: '100%',
        color: grey50,
      },
    }),
  { index: 1 },
);

interface IStyleProps {
  tableHeadHeight: number;
}

interface IProps {
  rows: EventsLogRowValue[];
  hasNoData: boolean;
  onScrollNearBottom: () => void;
  isEndOfList: boolean;
  isLoading: boolean;
}

interface IColumn {
  id: 'time' | 'type' | 'description';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: IColumn[] = [
  {
    id: 'time',
    label: t`Time`,
  },
  {
    id: 'type',
    label: t`Type`,
  },
  {
    id: 'description',
    label: t`Description`,
  },
];

const LogTable = (props: IProps) => {
  const { rows, hasNoData, isLoading, isEndOfList, onScrollNearBottom } = props;

  const tableRef = useRef<HTMLTableElement | null>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement | null>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const lastElement = useRef<HTMLTableRowElement>(null);
  const isEndOfListRef = useRef(isEndOfList);
  const isLoadingRef = useRef(isLoading);
  const { loadingIndicator, loadingIndicatorCenter, footer } = useStyles({
    tableHeadHeight: tableHeadRef.current?.clientHeight ?? 0,
  });

  const showEndOfList = !hasNoData && !isLoading && isEndOfList;

  useEffect(() => {
    isEndOfListRef.current = isEndOfList;
    isLoadingRef.current = isLoading;
  }, [isEndOfList, isLoading]);

  const scrollObserver = useCallback(
    () =>
      new IntersectionObserver((entries) => {
        if (
          (entries[0].isIntersecting || entries[1]?.isIntersecting) &&
          !isEndOfListRef.current &&
          !isLoadingRef.current
        ) {
          onScrollNearBottom();
        }
      }),
    [onScrollNearBottom],
  );

  useEffect(() => {
    const lastElementObserver = scrollObserver();
    const footerObserver: IntersectionObserver = scrollObserver();
    const currentElement = lastElement.current;
    const currentFooter = footerRef.current;

    const scrollHeight = tableRef.current?.scrollHeight ?? 0;
    const clientHeight = tableRef.current?.clientHeight ?? 0;
    const canBeScrolled = scrollHeight > clientHeight;

    if (currentElement && canBeScrolled) lastElementObserver.observe(currentElement);
    if (currentFooter) footerObserver.observe(currentFooter);

    return () => {
      if (currentElement && lastElementObserver) lastElementObserver.disconnect();
      if (currentFooter && footerObserver) footerObserver.disconnect();
    };
  }, [lastElement, tableRef.current?.scrollHeight, tableRef.current?.clientHeight, scrollObserver]);

  return (
    <TableContainer data-testid="log-table" ref={tableRef}>
      <Table stickyHeader aria-label="equipment events log table">
        <TableHead ref={tableHeadRef}>
          <TableRow>
            {columns.map(({ id, label }) => (
              <TableCell key={`${id}-${uuid()}`} className="sdds-headline-07">
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!hasNoData && (
          <TableBody data-testid="table-body">
            {rows.map(({ timestamp, values, type }, index) =>
              index === rows.length - 1 ? (
                <LogTableRow ref={lastElement} timestamp={timestamp} values={values} type={type} key="last-item" />
              ) : (
                <LogTableRow timestamp={timestamp} values={values} type={type} key={`${timestamp}-${uuid()}`} />
              ),
            )}
          </TableBody>
        )}
      </Table>
      {hasNoData && !isLoading && (
        <NoDataAvailablePictogram
          width="70px"
          containerHeight={`calc(80% - ${footerHeight}px)`}
          text="No data available"
          testId="no-equipment-events-available"
        />
      )}
      <div ref={footerRef} className={footer}>
        {showEndOfList && <span data-testid="log-table-end-of-list">{t`No more data to show.`}</span>}
        {isLoading && (
          <div data-testid="events-log-loading" className={hasNoData ? loadingIndicatorCenter : loadingIndicator}>
            <CircularProgress size={25} />
          </div>
        )}
      </div>
    </TableContainer>
  );
};

export default memo(LogTable);
