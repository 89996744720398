import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import RiskMap from './RiskMap';
import MiniMap from './MiniMap';
import CameraView from './CameraView';
import { SubView } from '../model/ViewTypes';
import theme from '../theme/theme';

const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      zIndex: 1300,
      columnGap: theme.spacing(8),
      padding: theme.spacing(5.5, 0, 8, 0),
    },
    card: {
      display: 'flex',
      aspectRatio: '1/1',
      flex: '1 1 30%',
    },
    cameraCard: {
      display: 'flex',
      aspectRatio: '4/3',
      flex: '1 1 40%',
    },
  },
  { index: 1 },
);

interface IProps {
  subviewState: [SubView, (arg0: SubView) => void];
  areaId: string | null;
  externalEquipmentReference: string | null;
}

const PreviewCluster = (props: IProps) => {
  const { subviewState, areaId, externalEquipmentReference } = props;
  const [, setSubView] = subviewState;
  const { root, card, cameraCard } = useStyles();

  return (
    <div className={root}>
      <Paper className={cameraCard}>
        <CameraView reduced offset={0} />
      </Paper>

      <Paper className={card}>
        <RiskMap
          isPreviewRiskMap
          onExpand={() => setSubView(SubView.RiskMap)}
          areaId={areaId}
          externalEquipmentReference={externalEquipmentReference}
        />
      </Paper>

      <Paper className={card}>
        <MiniMap areaId={areaId} externalEquipmentReference={externalEquipmentReference} />
      </Paper>
    </div>
  );
};

export default PreviewCluster;
